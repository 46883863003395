import React, {useState} from 'react';
import {OptionalArgumentFunction} from '../../types';
import {LoadingButton as MuiLoadingButton} from '@mui/lab';

interface Props<T> {
  row: T;
  title: string;
  icon: any;
  onClick: OptionalArgumentFunction<T, void>;
  disabled?: OptionalArgumentFunction<T, boolean>;
}

const LoadingButton = <T extends object>({row, title, icon, onClick, disabled}: Props<T>): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);

  const handleClick = async () => {
    setLoading(true);
    await onClick(row);
    setLoading(false);
  };

  return (
    <MuiLoadingButton
      className="MuiButton--table"
      sx={{px: 1, py: 1}}
      color="secondary"
      variant="text"
      startIcon={icon}
      onClick={(e) => {
        e.stopPropagation();
        handleClick();
      }}
      disabled={typeof disabled !== 'undefined' && disabled(row)}
      loadingPosition="start"
      loading={loading}
    >
      {title}
    </MuiLoadingButton>
  );
};

export default LoadingButton;
