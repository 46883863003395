import React, {useContext} from 'react';
import {ColumnDefinitionType, TableAction, ElasticDeployment} from '../../../types';
import DataTable from '../../../elements/DataTable/DataTable';
import {Paper} from "@mui/material";
import {
  AddBox,
  DeleteOutlined,
  InfoOutlined,
  OpenInNewOutlined,
  SyncOutlined
} from "@mui/icons-material";
import {UserContext} from "../../../provider/UserProvider";
import {hasRole} from "../../../services/role";
import {useNavigate} from "react-router-dom";
import CustomerLinkButton from "../../CustomerResource/CustomerLinkButton";
import {
  deleteDeployment,
  getDeployments,
  synchronizeDeployment
} from "../../../api/elasticDeployment";

const Overview = () => {
  const navigate = useNavigate();
  const {user} = useContext(UserContext);
  const {data: deployments, isLoading, isFetching} = getDeployments();
  const mutation = deleteDeployment();
  const syncMutation = synchronizeDeployment();

  const columns: Array<ColumnDefinitionType<ElasticDeployment>> = [
    { key: 'name', title: 'Naam', type: 'string', sortable: true, filter: true },
    { key: 'version', title: 'Versie', type: 'string', sortable: true, filter: true },
    { key: 'users.length', title: 'Aantal gebruikers', type: 'integer', sortable: true, filter: true },
    { key: 'customer.name', title: 'Klant', type: 'custom', sortable: true, content: (row) => {
      if (row.customer !== null) {
        return row.customer.name;
      }

      return <CustomerLinkButton resourceId={row.id} resourceType="Elastic deployment"/>
    }},
  ];

  const toolbarActions: Array<TableAction<undefined>> = [
    {
      title: 'Deployment aanmaken',
      icon: <AddBox />,
      variant: 'primary',
      onClick: () => navigate('/elastic/deployment/new'),
      type: 'button' ,
      condition: () => hasRole(user, ['ROLE_ELASTIC_DEPLOYMENT_CREATE']),
    },
  ];

  const actions: Array<TableAction<ElasticDeployment>> = [
    {
      title: 'Enterprise search',
      icon: <OpenInNewOutlined />,
      variant: 'primary',
      onClick: (deployment) => window.open(String(deployment.appSearchUrl), '_blank'),
      type: 'button',
      condition: (deployment) => deployment.appSearchUrl !== null,
    },
    {
      title: 'Kibana',
      icon: <OpenInNewOutlined />,
      variant: 'primary',
      onClick: (deployment) => window.open(String(deployment.kibanaUrl), '_blank'),
      type: 'button',
      condition: (deployment) => deployment.kibanaUrl !== null,
    },
    {
      title: 'Details',
      icon: <InfoOutlined/>,
      variant: 'primary',
      onClick: (deployment) => navigate(`/elastic/deployment/${deployment.id}/detail`),
      type: 'button',
      condition: () => hasRole(user, ['ROLE_ELASTIC_DEPLOYMENT_GET'])
    },
    {
      title: 'Synchroniseren',
      icon: <SyncOutlined/>,
      variant: 'primary',
      onClick: syncMutation.mutateAsync,
      type: 'loading_button',
    },
    {
      title: 'Verwijderen',
      icon: <DeleteOutlined/>,
      variant: 'danger',
      onClick: mutation.mutate,
      type: 'button',
      condition: () => hasRole(user, ['ROLE_ELASTIC_DEPLOYMENT_DELETE']),
      disabled: (deployment) => deployment.name === 'cluster01',
    },
  ];

  return (
    <Paper>
      <DataTable
        columns={columns}
        rows={deployments}
        loading={isLoading || isFetching}
        actions={actions}
        toolbar={{
          title: 'Elastic deployments',
          actions: toolbarActions,
        }}
        defaultRowsPerPage={10}
        pagination
      />
    </Paper>
  );
};

export default Overview;
