import React, {useEffect} from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import {Autocomplete as MuiAutocomplete, AutocompleteProps, TextField} from '@mui/material';
import {getIn} from '../../services/form';
import {getProjects} from "../../api/project";
import {useSearchParams} from "react-router-dom";
import {Customer, Project} from "../../types";

type Props = Omit<AutocompleteProps<any, any, any, any>, 'name'|'renderInput'|'options'> & {
  name: string;
  label: string;
  customer?: Customer;
}

const typeMap = {
  sulu: 'Sulu',
  sylius: 'Sylius',
  akeneo: 'Akeneo',
  symfony: 'Symfony',
  shopware: 'Shopware',
  shopware_frontend: 'Shopware frontend',
  frontend: 'Frontend',
  monorepo: 'Monorepo',
} as any;

const ProjectSelect = ({name, label, customer, ...props}: Props) => {
  const {control, watch, getValues, formState: {errors, isSubmitting}, setValue} = useFormContext();
  const {data: projects, isFetching, isLoading} = getProjects();
  const [searchParams] = useSearchParams();

  const filterProjects = () => {
    if (undefined !== customer && (watch('customer') === null || watch('customer') === undefined)) {
      return projects.filter((project: Project) => project.customer?.id === customer.id);
    }

    if (undefined === customer && watch('customer') !== null && watch('customer') !== undefined) {
      return projects?.filter((project: Project) => project.customer?.id === getValues('customer').id);
    }

    return projects;
  }

  useEffect(() => {
    if (searchParams.get(name) !== null) {
      setValue(name, projects.find((project: Project) => String(project.id) === searchParams.get(name)) || null, {shouldDirty: true, shouldValidate: true, shouldTouch: true});
    }
  }, [projects])

  return (
    <Controller
      name={name}
      control={control}
      render={({field: {onChange, ...field}}) => (
        <MuiAutocomplete
          {...field}
          {...props}
          value={watch(name)}
          options={filterProjects()}
          loading={isFetching || isLoading}
          disabled={isSubmitting || props.disabled}
          onChange={(event, value) => onChange(value)}
          isOptionEqualToValue={(option, value) => {
            if (typeof option === 'object') {
              return option.id === value.id
            }

            return option === value;
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              error={Boolean(getIn(errors, name))}
              helperText={getIn(errors, name)?.message}
            />
          )}
          getOptionLabel={(option: Project) => `${option.domain} - ${typeMap[option.type]}`}
        />
      )}
    />
  );
};

export default ProjectSelect;
