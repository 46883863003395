import {useQuery} from "react-query";
import client from "../services/client";

export const getBranches = (id: number) => {
  return useQuery({
    queryKey: ['branches', id],
    queryFn: () => client.get(`project/${id}/branch`).then(response => response.data),
    initialData: [],
    enabled: id !== 0,
  });
};
