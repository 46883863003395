import {useQuery} from 'react-query';
import client from '../services/client';

export const getRoles = () => {
  return useQuery({
    queryKey: ['roles'],
    queryFn: () => client.get('role').then(response => response.data),
    initialData: [],
  });
};
