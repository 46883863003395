import {Collapse, List, ListItem, ListItemIcon, ListItemText} from '@mui/material';
import React, {useState} from 'react';
import {ChevronRight} from '@mui/icons-material';
import {Link, useLocation} from 'react-router-dom';
import CollapsableSubListItem from './CollapsableSubListItem';

const CollapsableListItem = ({id, icon, items}: any) => {
  const location = useLocation();
  const [open, setOpen] = useState<boolean>(!!items.find((item: any) => location.pathname === item.href));

  const isHighlighted = () => {
    let match = false;
    items.forEach((item: any) => {
      if (item.href === location.pathname) {
        match = true;
      }

      if (typeof item.items !== 'undefined') {
        item.items.forEach((subItem: any) => {
          if (subItem.href === location.pathname) {
            match = true;
          }
        });
      }
    });

    return match;
  };

  return (
    <>
      <ListItem onClick={() => setOpen(!open)} sx={{
        py: 2,
        cursor: 'pointer',
        borderTop: '0.5px solid rgba(255,255,255,.3)',
        '&:hover .MuiListItemIcon-root': {
          color: isHighlighted() ? '#fff' : 'rgba(255,255,255,.8)',
        },
        '&:hover .MuiListItemText-root': {
          color: isHighlighted() ? '#fff' : 'rgba(255,255,255,.8)',
        },
      }}>
        <ListItemIcon sx={{
          color: isHighlighted() ? '#fff' : 'rgba(255,255,255,.6)',
          minWidth: '35px',
        }}>{icon}</ListItemIcon>
        <ListItemText primaryTypographyProps={{fontSize: 14, fontWeight: 'bold'}} sx={{
          color: isHighlighted() ? '#fff' : 'rgba(255,255,255,.6)',
        }} primary={id} />
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding sx={{pb: 2}}>
          {items.map((item: any, i: number) => {
            if (typeof item.condition !== 'undefined' && !item.condition()) {
              return <></>;
            }

            if (typeof item.items !== 'undefined' && item.items.length !== 0) {
              return <CollapsableSubListItem key={item.id} id={item.id} icon={item.icon} items={item.items} />;
            }

            return (
              <ListItem onClick={() => typeof item.onClick !== 'undefined' ? item.onClick() : void 0} key={i} button component={Link} to={item.href} sx={{
                py: 0.5,
                pl: 3,
                '&:hover .MuiListItemIcon-root': {
                  color: location.pathname === item.href ? '#fff' : 'rgba(255,255,255,.8)',
                },
                '&:hover .MuiListItemText-root': {
                  color: location.pathname === item.href ? '#fff' : 'rgba(255,255,255,.8)',
                },
              }}>
                <ListItemIcon sx={{
                  color: location.pathname === item.href ? '#fff' : 'rgba(255,255,255,.6)',
                  minWidth: '35px',
                }}><ChevronRight/></ListItemIcon>
                <ListItemText primaryTypographyProps={{fontSize: 14, fontWeight: 'bold'}} sx={{
                  color: location.pathname === item.href ? '#fff' : 'rgba(255,255,255,.6)',
                }} primary={item.id} />
              </ListItem>
            );
          })}
        </List>
      </Collapse>
    </>
  );
};

export default CollapsableListItem;
