import {Formik, FormikHelpers} from 'formik';
import React from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, Slider
} from '@mui/material';
import * as Yup from 'yup';
import {Database} from '../../types';
import client from '../../services/client';
import {toast} from "react-toastify";
import Typography from "@mui/material/Typography";

const schema = Yup.object().shape({
  minutes: Yup.number().required('Er moet een aantal minuten gekozen worden'),
});

interface Props {
  database: Database|null;
  open: boolean;
  onClose: () => void;
}

const Form = ({ database, open, onClose }: Props) => {
  if (null === database) {
    return <></>;
  }

  const marks = [
    {value: 15, label: '15'},
    {value: 30, label: '30'},
    {value: 45, label: '45'},
    {value: 60, label: '60'},
  ];

  const onSubmit = (values: {minutes: number}, actions: FormikHelpers<{minutes: number}>) => {
    client
      .put(`database/${database.id}/access/extend`, values)
      .then(() => {
        toast.success('Database gewijzigd');
        onClose();
      })
      .finally(() => actions.setSubmitting(false));
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <Formik
        initialValues={{minutes: 15}}
        validationSchema={schema}
        onSubmit={onSubmit}
      >
        {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isValid,
            isSubmitting,
          }) => (
          <form onSubmit={handleSubmit}>
            <DialogTitle>Toegang verlengen</DialogTitle>
            <DialogContent sx={{overflow: 'visible', minWidth: '400px'}}>
              <Typography>Aantal minuten</Typography>
              <Slider
                sx={{mt: 2}}
                name="minutes"
                defaultValue={15}
                valueLabelDisplay="auto"
                step={15}
                marks={marks}
                min={15}
                max={60}
                value={values.minutes}
                onChange={handleChange}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} color="primary">
                Annuleren
              </Button>
              <Button type="submit" color="primary" disabled={!isValid || isSubmitting}>
                Toegang verlengen
                {isSubmitting && <CircularProgress size={20} style={{ marginLeft: '8px' }} />}
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};

export default Form;
