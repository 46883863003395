import React, { useEffect } from 'react';
import {AdminResource, ColumnDefinitionType, TableAction} from '../../types';
import client from '../../services/client';
import { Paper } from '@mui/material';
import DataTable from '../../elements/DataTable/DataTable';
import {VisibilityOutlined} from '@mui/icons-material';

const columns: Array<ColumnDefinitionType<AdminResource>> = [
  { key: 'name', title: 'Naam', type: 'string', sortable: true },
  { key: 'type', title: 'Type', type: 'string', sortable: true },
];

const Overview = () => {
  const [resources, setResources] = React.useState<Array<AdminResource>>([]);
  const [loading, setLoading] = React.useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    client
      .get('resource/admin')
      .then((response) => setResources(response.data))
      .finally(() => setLoading(false));
  }, []);

  const actions: Array<TableAction<AdminResource>> = [
    { title: 'Bekijken', icon: <VisibilityOutlined />, variant: 'primary', onClick: () => void 0, type: 'button' },
  ];

  return (
    <Paper>
      <DataTable
        columns={columns}
        rows={resources}
        actions={actions}
        loading={loading}
        toolbar={{
          title: 'Admin resources',
        }}
      />
    </Paper>
  );
};

export default Overview;
