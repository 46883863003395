import React, { ReactElement, useEffect } from 'react';
import client from '../../../services/client';
import { ColumnDefinitionType, TableAction, Zone } from '../../../types';
import { UserContext } from '../../../provider/UserProvider';
import DataTable from '../../../elements/DataTable/DataTable';
import { AddBox, Delete, Search, Warning } from '@mui/icons-material';
import SearchBar from './SearchBar';
import { Paper } from '@mui/material';
import Form from './Form';
import { toast } from 'react-toastify';
import DeleteConfirmation from '../../../elements/DataTable/DeleteConfirmation';
import { orange } from '@mui/material/colors';
import {useNavigate} from "react-router-dom";

const columns: Array<ColumnDefinitionType<Zone>> = [
  { key: 'name', title: 'Naam', type: 'string', sortable: true },
  {
    key: 'correctNameservers',
    title: '',
    type: 'custom',
    sortable: false,
    content: (zone: Zone) => (
      <>
        {!zone.correctNameservers && (
          <span style={{ display: 'flex', alignItems: 'center' }}>
            <Warning style={{ color: orange[500], marginRight: '4px' }} /> DNS staat niet naar Friday
          </span>
        )}
      </>
    ),
  },
];

const Overview = () => {
  const navigate = useNavigate();
  const {user} = React.useContext(UserContext);
  const [zones, setZones] = React.useState<Array<Zone>>([]);
  const [zoneToEdit, setZoneToEdit] = React.useState<Zone | null>(null);
  const [zoneToDelete, setZoneToDelete] = React.useState<Zone | null>(null);
  const [query, setQuery] = React.useState<string>('');
  const [loading, setLoading] = React.useState<boolean>(false);

  const redirectToZone = (zone: Zone) => {
    navigate(`/dns/zone/${zone.id}`);
  };

  const handleAdd = () => {
    setZoneToEdit({
      externalId: 0,
      id: 0,
      name: '',
      records: [],
      redirects: [],
      customers: [],
      cryptokey: null,
      correctNameservers: true,
    });
  };

  const handleSubmit = (zone: Zone) => {
    client
      .post('dns/zone', zone)
      .then(() => {
        handleSearch(query);
        toast.success('Zone aangemaakt');
      })
      .finally(() => setZoneToEdit(null));
  };

  const handleDelete = () => {
    if (null === zoneToDelete) {
      return;
    }

    client
      .delete(`/dns/zone/${zoneToDelete.id}`)
      .then(() => {
        handleSearch(query);
        toast.success('Zone verwijderd');
      })
      .finally(() => setZoneToDelete(null));
  };

  const actions: Array<TableAction<Zone>> = [
    { title: 'Bekijken', icon: <Search />, variant: 'primary', onClick: redirectToZone, type: 'button' },
    {
      title: 'Verwijderen',
      icon: <Delete />,
      variant: 'danger',
      onClick: setZoneToDelete,
      condition: () => user.roles.includes('ROLE_DNS_ALL'),
      type: 'button',
    },
  ];

  const toolbarActions: Array<TableAction<undefined>> = [
    {
      title: 'Zone aanmaken',
      icon: <AddBox />,
      variant: 'primary',
      onClick: handleAdd,
      condition: () => user.roles.includes('ROLE_DNS_ALL'),
      type: 'button',
    },
  ];

  const handleSearch = (query: string) => {
    if (!loading && query.length > 2) {
      setQuery(query);
      setLoading(true);
      client
        .get(`dns/zone?query=${query}`)
        .then((response) => setZones(response.data))
        .finally(() => setLoading(false));
    }
  };

  const components: Array<ReactElement> = [
    user.roles.includes('ROLE_DNS_ALL') ? <SearchBar handleSearch={handleSearch} /> : <span />,
  ];

  useEffect(() => {
    if (0 === user.id || user.roles.includes('ROLE_DNS_ALL')) {
      return;
    }

    setLoading(true);
    client
      .get(`dns/zone`)
      .then((response) => setZones(response.data))
      .finally(() => setLoading(false));
  }, [query, user]);

  return (
    <Paper>
      <DataTable
        columns={columns}
        rows={zones}
        actions={actions}
        loading={loading}
        defaultInfoText="Begin met zoeken om zones in beeld te krijgen"
        onRowClick={redirectToZone}
        toolbar={{
          title: 'DNS zones',
          components: components,
          actions: toolbarActions,
        }}
      />
      {null !== zoneToEdit && (
        <Form
          zone={zoneToEdit}
          open={null !== zoneToEdit}
          onConfirm={handleSubmit}
          onClose={() => setZoneToEdit(null)}
        />
      )}
      <DeleteConfirmation
        title={`Zone ${zoneToDelete?.name || ''} verwijderen?`}
        text="Wanneer de zone wordt verwijderd worden ook alle bijbehorende DNS records en redirects verwijderd. Deze actie kan niet ongedaan gemaakt worden."
        open={null !== zoneToDelete}
        onConfirm={handleDelete}
        onClose={() => setZoneToDelete(null)}
      />
    </Paper>
  );
};

export default Overview;
