import {Alert, Button, Grid, Paper, Stack, Table, TableCell, TableRow, Typography} from '@mui/material';
import React, {useContext} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import CustomerLinkButton from "../../CustomerResource/CustomerLinkButton";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import {ArrowBack, LockResetOutlined, OpenInNewOutlined, SyncOutlined} from "@mui/icons-material";
import {getDeployment, resetDeploymentPassword, synchronizeDeployment} from "../../../api/elasticDeployment";
import Password from "./Password";
import { UserContext } from '../../../provider/UserProvider';
import {hasRole} from "../../../services/role";

const Detail = () => {
  const {user} = useContext(UserContext);
  const navigate = useNavigate();
  const {id} = useParams();
  const {data: deployment} = getDeployment(+(id || 0));
  const mutation = resetDeploymentPassword();
  const syncMutation = synchronizeDeployment();

  const redirectBack = () => {
    navigate(-1);
  };

  const styles = {height: '60px', px: 0, width: '50%'}

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} sm={12} md={12} lg={6}>
        <Paper sx={{p: 6, maxWidth: '600px', margin: '0 auto'}}>
          <Stack direction="row" justifyContent="start" alignItems="center" sx={{mb: 4}}>
            <Tooltip title="Terug">
              <IconButton onClick={redirectBack} sx={{mr: 1}}>
                <ArrowBack />
              </IconButton>
            </Tooltip>
            <Typography variant="h6" fontWeight="bold">Elastic deployment {deployment.name}</Typography>
          </Stack>
          <Typography variant="subtitle1" fontWeight="bold">Algemene informatie</Typography>
          <Table>
            <TableRow>
              <TableCell sx={styles}>Naam</TableCell>
              <TableCell sx={styles}>{deployment.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={styles}>Kibana</TableCell>
              <TableCell sx={styles}>
                {deployment.kibanaUrl === null ? (
                  'N.v.t.'
                ) : (
                  <Button
                    sx={{p: 1}}
                    startIcon={<OpenInNewOutlined />}
                    color="info"
                    onClick={() => window.open(deployment.kibanaUrl, '_blank')}
                  >
                    Openen
                  </Button>
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={styles}>Enterprise search</TableCell>
              <TableCell sx={styles}>
                {deployment?.appSearchUrl === null ? (
                  'N.v.t.'
                ) : (
                  <Button
                    sx={{p: 1}}
                    startIcon={<OpenInNewOutlined />}
                    color="info"
                    onClick={() => window.open(deployment.appSearchUrl, '_blank')}
                  >
                    Openen
                  </Button>
                )}
              </TableCell>
            </TableRow>
            {hasRole(user, ['ROLE_ELASTIC_DEPLOYMENT_UPDATE']) && (
              <TableRow>
                <TableCell sx={styles}>Wachtwoord reset</TableCell>
                <TableCell sx={styles}>
                  <Button
                    sx={{p: 1}}
                    startIcon={<LockResetOutlined />}
                    color="info"
                    onClick={() => mutation.mutate(deployment)}
                  >
                    Reset
                  </Button>
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell sx={styles}>Synchroniseren</TableCell>
              <TableCell sx={styles}>
                <Button
                  sx={{p: 1}}
                  startIcon={<SyncOutlined />}
                  color="info"
                  onClick={() => syncMutation.mutate(deployment)}
                >
                  Sync
                </Button>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={styles}>Aantal gebruikers</TableCell>
              <TableCell sx={styles}>{deployment.users.length}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{...styles, borderBottom: 'none'}}>Klant</TableCell>
              <TableCell sx={{...styles, borderBottom: 'none'}}>
                {deployment.customer === null ? (
                  <CustomerLinkButton resourceId={deployment.id} resourceType="Elastic deployment"/>
                ) : deployment.customer.name}
              </TableCell>
            </TableRow>
          </Table>

          <Typography variant="subtitle1" fontWeight="bold" sx={{mt: 2}}>Inloggen</Typography>
          <Alert severity="warning" sx={{boxShadow: 'none', my: 2, whiteSpace: 'pre-line'}}>
            Gebruik deze gegevens niet binnen een project. Gebruik hiervoor de project specifieke gebruikers. Sla deze gegevens ook niet op in LastPass. Het wachtwoord van het admin account wordt wekelijks automatisch gewijzigd.
          </Alert>
          <Table>
            <TableRow>
              <TableCell sx={styles}>Host</TableCell>
              <TableCell sx={styles}>{deployment.elasticsearchUrl}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={styles}>Gebruikersnaam</TableCell>
              <TableCell sx={styles}>{deployment.username}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{...styles, borderBottom: 'none'}}>Wachtwoord</TableCell>
              <TableCell sx={{...styles, borderBottom: 'none'}}>
                <Password deployment={deployment} />
              </TableCell>
            </TableRow>
          </Table>
        </Paper>
      </Grid>
    </Grid>
  )
};

export default Detail;
