import { ColumnDefinitionType, Record, TableAction, Zone } from '../../../types';
import React, { ReactElement } from 'react';
import client from '../../../services/client';
import { toast } from 'react-toastify';
import { AddBox, Delete, Edit} from '@mui/icons-material';
import DataTable from '../../../elements/DataTable/DataTable';
import Form from './Form';
import DeleteConfirmation from '../../../elements/DataTable/DeleteConfirmation';
import SearchBar from '../Zone/SearchBar';

interface Props {
  zone: Zone;
  loading: boolean;
  refresh: () => void;
}

const Overview = ({ zone, refresh, loading }: Props) => {
  const [recordToEdit, setRecordToEdit] = React.useState<Record | null>(null);
  const [recordToDelete, setRecordToDelete] = React.useState<Record | null>(null);
  const [query, setQuery] = React.useState<string>('');

  const columns: Array<ColumnDefinitionType<Record>> = [
    { key: 'name', title: 'Naam', type: 'string', sortable: true },
    { key: 'type', title: 'Type', type: 'string', sortable: true },
    {
      key: 'content',
      title: 'Content',
      type: 'custom',
      sortable: true,
      wordBreak: 'break-all',
      content: (record) => {
        if (record.content !== '87.233.198.9' && record.content !== '2001:9a8:a6:0:87:233:198:9') {
          return record.content;
        }

        const redirect = zone.redirects.find((redirect) => redirect.name === record.name);
        if (typeof redirect === 'undefined') {
          return record.content;
        }

        return (
          <>
            {record.content}
            <span style={{ fontStyle: 'italic', fontSize: '0.8rem', marginLeft: '12px' }}>
              Redirect naar {redirect.destination}
            </span>
          </>
        );
      },
    },
    {
      key: 'priority',
      title: 'Prioriteit',
      type: 'custom',
      sortable: true,
      content: (record) => {
        if (record.type !== 'MX' && record.type !== 'SRV') {
          return '';
        }

        return record.priority;
      },
    },
    { key: 'ttl', title: 'TTL', type: 'integer', sortable: true },
  ];

  const handleAdd = () => {
    setRecordToEdit({ id: 0, name: '', type: '', ttl: 3600, content: '', priority: 0 } as Record);
  };

  const handleSubmit = (record: Record) => {
    if (record.id === 0) {
      client
        .post(`dns/zone/${zone.id}/record`, record)
        .then(() => {
          toast.success('Record aangemaakt');
          refresh();
        })
        .finally(() => setRecordToEdit(null));
    } else {
      client
        .put(`dns/zone/${zone.id}/record/${record.id}`, record)
        .then(() => {
          toast.success('Record gewijzigd');
          refresh();
        })
        .finally(() => setRecordToEdit(null));
    }
  };

  const handleDelete = () => {
    if (null === recordToDelete) {
      return;
    }

    client
      .delete(`dns/zone/${zone.id}/record/${recordToDelete.id}`)
      .then(() => {
        toast.success('Record verwijderd');
        refresh();
      })
      .finally(() => setRecordToDelete(null));
  };

  const toolbarActions: Array<TableAction<undefined>> = [
    { title: 'Record aanmaken', icon: <AddBox />, variant: 'primary', type: 'button', onClick: handleAdd },
  ];

  const actions: Array<TableAction<Record>> = [
    {
      title: 'Bewerken',
      icon: <Edit />,
      variant: 'primary',
      type: 'button',
      onClick: setRecordToEdit,
      disabled: (record) =>
        record.content === '87.233.198.9' ||
        record.content === '2001:9a8:a6:0:87:233:198:9' ||
        record.type === 'SOA',
    },
    {
      title: 'Verwijderen',
      icon: <Delete />,
      variant: 'danger',
      type: 'button',
      onClick: setRecordToDelete,
      disabled: (record) =>
        record.content === '87.233.198.9' ||
        record.content === '2001:9a8:a6:0:87:233:198:9' ||
        record.type === 'SOA',
    },
  ];

  const components: Array<ReactElement> = [<SearchBar handleSearch={setQuery} />];

  return (
    <>
      <DataTable
        columns={columns}
        rows={
          '' === query
            ? zone.records
            : zone.records.filter(
              (record) =>
                record.name.includes(query) ||
                record.content.includes(query) ||
                record.type.includes(query)
            )
        }
        actions={actions}
        loading={loading}
        toolbar={{
          title: 'Records',
          actions: toolbarActions,
          components: components,
        }}
      />
      {null !== recordToEdit && (
        <Form
          zone={zone}
          record={recordToEdit}
          open={null !== recordToEdit}
          onClose={() => setRecordToEdit(null)}
          onConfirm={handleSubmit}
        />
      )}
      <DeleteConfirmation
        title="Record verwijderen?"
        text="Deze actie kan niet ongedaan gemaakt worden."
        open={null !== recordToDelete}
        onConfirm={handleDelete}
        onClose={() => setRecordToDelete(null)}
      />
    </>
  );
};

export default Overview;
