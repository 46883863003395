import {useMutation, useQuery} from 'react-query';
import client from '../services/client';
import {SslCertificate} from '../types';
import {toast} from 'react-toastify';
import fileDownload from 'js-file-download';
import queryClient from '../services/queryClient';

export const getCertificates = () => {
  return useQuery({
    queryKey: ['certificates'],
    queryFn: () => client.get('ssl/certificate').then(response => response.data),
    initialData: [],
  });
};

export const getCertificate = (id: number, detailed: boolean = true) => {
  return useQuery({
    queryKey: ['certificate', id],
    queryFn: () => client.get(`ssl/certificate/${id}`, {
      params: {
        detailed: detailed,
      },
    }).then(response => response.data),
    initialData: {
      id: 0,
      commonName: '',
      domains: [],
      records: [],
      expirationDate: null,
      orderDate: null,
      requestedBy: null,
    },
    enabled: id !== 0,
  });
};

export const createCertificate = () => {
  return useMutation({
    mutationFn: async (values: SslCertificate) => {
      return client.post('ssl/certificate', {
        ...values,
        customer: values.customer?.id,
        subscription: values.subscription?.id,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['certificates']});
      toast.success('SSL certificaat aangemaakt');
    },
  });
};

export const updateCertificate = () => {
  return useMutation({
    mutationFn: async (values: SslCertificate) => {
      return client.put(`ssl/certificate/${values.id}`, {
        status: values.status,
        domains: values.domains,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['certificates']});
      toast.success('SSL certificaat gewijzigd');
    },
  });
};

export const downloadCertificate = () => {
  return useMutation({
    mutationFn: async ({id, name}: {id: number, name: string}) => {
      return client.get(`ssl/certificate/${id}/download`, { responseType: 'blob' })
        .then(response => fileDownload(response.data, `${name.replace('.', '_')}.zip`));
    },
  });
};
