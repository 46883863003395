import {useMutation, useQuery} from 'react-query';
import client from '../services/client';
import confirm from '../elements/Confirmation/Confirmation';
import {MessageQueueServer} from "../types";
import queryClient from '../services/queryClient';
import { toast } from 'react-toastify';

export const getMessageQueueServers = () => {
  return useQuery({
    queryKey: ['messageQueueServers'],
    queryFn: () => client.get('mq/server').then(response => response.data),
    initialData: [],
  });
};

export const getMessageQueueServer = (id: number) => {
  return useQuery({
    queryKey: ['messageQueueServer', id],
    queryFn: () => client.get(`mq/server/${id}`).then(response => response.data),
  });
};

export const createMessageQueueServer = () => {
  return useMutation({
    mutationFn: async (server: MessageQueueServer) => {
      return client.post('mq/server',server);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['messageQueueServers']});
      toast.success('Server aangemaakt');
    },
  });
};

export const updateMessageQueueServer = () => {
  return useMutation({
    mutationFn: async (server: MessageQueueServer) => {
      return client.put(`mq/server/${server.id}`,server);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['messageQueueServers']});
      toast.success('Server gewijzigd');
    },
  });
};

export const deleteMessageQueueServer = () => {
  return useMutation({
    mutationFn: async (server: MessageQueueServer) => {
      if (await confirm({
        title: 'Server verwijderen',
        text: 'Deze actie kan niet ongedaan gemaakt worden',
      })) {
        return client.delete(`mq/server/${server.id}`);
      }

      return Promise.reject(new Error('Cancelled'));
    },
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['messageQueueServers']});
      toast.success('Server verwijderd');
    },
  });
};

export const synchronizeMessageQueueServer = () => {
  return useMutation({
    mutationFn: async (server: MessageQueueServer) => {
      return client.post(`mq/server/${server.id}/synchronize`,server);
    },
    onSuccess: () => {
      toast.success('Server gesynchroniseerd');
    },
  });
};
