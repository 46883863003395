import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface props {
  kubeData: any
}

export const StackedBar = ({kubeData}: props) => {
  const labels: string[] = [];
  kubeData.forEach((value: any) => {
    labels.push(new Date(value.createdAt * 1000).toLocaleDateString())
  });

  const options = {
    plugins: {
      title: {
        display: false,
        text: 'Bar Chart - Stacked',
      },
      legend: {
        labels: {
          font: {
            size: 14,
          }
        }
      },
      tooltip: {
        titleFont: {
          size: 16,
        },
        bodyFont: {
          size: 16,
        },
        footerFont: {
          size: 16,
        },
        callbacks: {
          label: function(context:any) {
            let label = context.dataset.label || '';

            if (label) {
              label += ': ';
            }

            if (context.parsed.y !== null) {
              label += new Intl.NumberFormat('nl-NL', {style: 'currency', currency: 'EUR'}).format(context.parsed.y);
            }

            return label;
          },
          footer: function(context:any) {
            return 'Totaal: ' + new Intl.NumberFormat('nl-NL', {style: 'currency', currency: 'EUR'}).format(context.reduce((a:any, b:any) => a + b.parsed.y, 0));

          }
        }
      }
    },
    interaction: {
      intersect: false,
      mode: 'index' as 'index',
    },
    responsive: true,
    locale: 'nl-NL',
    scales: {
      x: {
        stacked: true,
        title: {
          display: true,
          text: 'Datum \u2800 \u2192',
          padding: 20,
          font: {
            size: 18,
          }
        }
      },
      y: {
        ticks: {
          callback: (value:any) => {
            return new Intl.NumberFormat('nl-NL', {style: 'currency', currency: 'EUR'}).format(value);
          }
        },
        stacked: true,
        title : {
          display: true,
          text: 'Kosten in \u20AC \u2800 \u2192',
          padding: 20,
          font: {
            size: 18,
          }
        },
      }
    },
  };

  const getCosts = (costType: string) => {
    const arr: any[] = [];
    kubeData.forEach(function(value: any) {
      if(costType in value) {
        arr.push(value[costType].toFixed(2))
      }
    })
    return arr;
  }

  const data = {
    labels,
    datasets: [
      {
        label: 'Shared',
        data: getCosts('sharedCost'),
        backgroundColor: 'rgb(242, 146, 141)',
      },
      {
        label: 'CPU',
        data: getCosts('cpuCost'),
        backgroundColor: 'rgb(68, 94, 147)',
      },
      {
        label: 'RAM',
        data: getCosts('ramCost'),
        backgroundColor: 'rgb(83, 153, 135)',
      },

    ],
  };

  return(
      <Bar options={options} data={data} className="stackedBar"/>
  )
}
