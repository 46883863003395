import Button from "@mui/material/Button";
import {LinkOutlined} from "@mui/icons-material";
import React, {useContext, useState} from 'react';
import {Customer, Namespace} from "../../types";
import NamespaceProjectLinkForm from "./NamespaceProjectLinkForm";
import {UserContext} from "../../provider/UserProvider";
import { hasRole } from "../../services/role";

interface Props {
  namespace: Namespace;
  customer?: Customer;
}

const ProjectLinkButton = (props: Props) => {
  const {user} = useContext(UserContext);
  const [showForm, setShowForm] = useState<boolean>(false);

  return (
    <>
      <Button
        sx={{p: 1}}
        startIcon={<LinkOutlined/>}
        color="primary"
        onClick={() => setShowForm(true)}
        disabled={!hasRole(user, ['ROLE_KUBERNETES_NAMESPACE_UPDATE'])}
      >
        Koppelen
      </Button>
      <NamespaceProjectLinkForm open={showForm} onClose={() => setShowForm(false)} {...props} />
    </>
  )
}

export default ProjectLinkButton;
