import {Button, Grid, Paper, Typography} from '@mui/material';
import React, {useEffect} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {createUser, getUser, updateUser} from "../../api/user";
import {joiResolver} from "@hookform/resolvers/joi";
import Joi from "joi";
import {FormProvider, useForm} from "react-hook-form";
import TextField from "../../elements/Form/TextField";
import {getRoles} from "../../api/role";
import Autocomplete from "../../elements/Form/Autocomplete";
import { LoadingButton } from '@mui/lab';
import {User} from "../../types";
import {getCustomers} from "../../api/customer";
import validationSchema from "../../services/validationSchema";

const FormPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const {data: user} = id === undefined ? {data: undefined} : getUser(+(id));
  const {data: roles} = getRoles();
  const {data: customers} = getCustomers();
  const mutation = id === undefined ? createUser() : updateUser();

  const schema = validationSchema.object({
    name: Joi.string().required(),
    email: Joi.string().required(),
    password: Joi.string()
      .when('$new', {
        is: true,
        then: Joi.required(),
        otherwise: Joi.optional(),
      }),
    passwordConfirmation: Joi.string()
      .equal(Joi.ref('password'))
      .when('$new', {
        is: true,
        then: Joi.required(),
        otherwise: Joi.optional(),
      }),
    roles: Joi.array(),
    customer: Joi.object().required(),
  }).unknown();

  const form = useForm({
    defaultValues: user || {name: '', email: '', password: '', passwordConfirmation: '', roles: [], customer: null},
    resolver: joiResolver(schema),
    context: {new: id === undefined},
    mode: 'onBlur',
  });

  const onSubmit = (values: User) => {
    return mutation.mutateAsync(values).then(() => {
      navigate('/user');
    }).catch((error) => {
      Object.keys(error.response.data.errors || {}).forEach(key => {
        form.setError(key, {type: 'custom', message: error.response.data.errors[key]})
      })
    });
  }

  useEffect(() => {
    if (id !== undefined) {
      form.reset(user);
    }
  }, [user])

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} sm={12} md={12} lg={6}>
        <Paper sx={{p: 6, maxWidth: '600px', margin: '0 auto'}}>
          <Typography variant="h6" fontWeight="bold">Gebruiker {id === undefined ? 'aanmaken' : 'wijzigen'}</Typography>
          <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <TextField name="name" label="Naam" />
              <TextField name="email" label="E-mailadres" />
              {id === undefined && (
                <>
                  <TextField name="password" label="Wachtwoord" type="password" />
                  <TextField name="passwordConfirmation" label="Bevestig wachtwoord" type="password" />
                </>
              )}
              <Autocomplete
                options={roles}
                name="roles"
                label="Rollen"
                multiple
                getOptionLabel={(option) => option.name}
              />
              <Autocomplete
                options={customers}
                name="customer"
                label="Klant"
                getOptionLabel={(option) => option.name}
              />
              <LoadingButton sx={{mt: 2}} type="submit" color="primary" variant="contained" loading={form.formState.isSubmitting}>
                {id === undefined ? 'Aanmaken' : 'Wijzigen'}
              </LoadingButton>
              <Button onClick={() => navigate('/user')} sx={{mt: 2, ml: 1}} color="secondary" disabled={form.formState.isSubmitting}>Annuleren</Button>
            </form>
          </FormProvider>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default FormPage;
