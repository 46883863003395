import {useMutation, useQuery} from 'react-query';
import client from '../services/client';
import confirm from '../elements/Confirmation/Confirmation';
import {User} from "../types";
import queryClient from '../services/queryClient';
import { toast } from 'react-toastify';

export const getUsers = () => {
  return useQuery({
    queryKey: ['users'],
    queryFn: () => client.get('user').then(response => response.data),
    initialData: [],
  });
};

export const getUser = (id: number) => {
  return useQuery({
    queryKey: ['user', id],
    queryFn: () => client.get(`user/${id}`).then(response => response.data),
    initialData: {
      id: 0,
      name: '',
      email: '',
      phone: '',
      roles: [],
      customerRoles: [],
    }
  });
};

export const createUser = () => {
  return useMutation({
    mutationFn: async (user: User) => {
      return client.post('user',{
        ...user,
        customer: user.customer?.id,
        roles: user.roles.map((role: any) => role.id),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['users']});
      toast.success('Gebruiker aangemaakt');
    },
  });
};

export const updateUser = () => {
  return useMutation({
    mutationFn: async (user: User) => {
      return client.put(`user/${user.id}`,{
        ...user,
        customer: user.customer?.id,
        roles: user.roles.map((role: any) => role.id),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['users']});
      toast.success('Gebruiker gewijzigd');
    },
  });
};

export const deleteUser = () => {
  return useMutation({
    mutationFn: async (user: User) => {
      if (await confirm({
        title: 'Gebruiker verwijderen',
        text: 'Deze actie kan niet ongedaan gemaakt worden',
      })) {
        return client.delete(`user/${user.id}`);
      }

      return Promise.reject(new Error('Cancelled'));
    },
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['users']});
      toast.success('Gebruiker verwijderd');
    },
  });
};
