import React from "react";
import {DateRangePicker} from "rsuite";
import {
  startOfDay,
  endOfDay,
  addDays,
  subDays,
  subMonths,
  startOfMonth,
  startOfYear,
  subYears,
} from 'date-fns';
import {DateRange, RangeType} from "rsuite/DateRangePicker";
import {after} from "rsuite/esm/DateRangePicker/disabledDateUtils";

interface props {
  setStartDate: any
  setEndDate: any
}

export function DatePicker({setStartDate, setEndDate}: props){

  const ranges: RangeType[] = [
    {
      label: 'Gisteren',
      value: [startOfDay(addDays(new Date(), -1)), endOfDay(new Date())],
      placement: 'left'
    },
    {
      label: 'Laatste 7 dagen',
      value: [startOfDay(subDays(new Date(), 7)), endOfDay(new Date())],
      placement: 'left'
    },
    {
      label: 'Laatste 30 dagen',
      value: [startOfDay(subDays(new Date(), 30)), endOfDay(new Date())],
      placement: 'left'
    },
    {
      label: 'Laatste 60 dagen',
      value: [startOfDay(subDays(new Date(), 60)), endOfDay(new Date())],
      placement: 'left'
    },
    {
      label: 'Laatste 90 dagen',
      value: [startOfDay(subDays(new Date(), 90)), endOfDay(new Date())],
      placement: 'left'
    },
    {
      label: 'Vorige maand',
      value: [startOfMonth(subMonths(new Date(), 1)), startOfMonth(new Date())],
      placement: 'left'
    },
    {
      label: 'Huidige maand',
      value: [startOfMonth(new Date()), endOfDay(new Date())],
      placement: 'left'
    },
    {
      label: 'Vorig jaar',
      value: [startOfYear(subYears(new Date(), 1)), startOfYear(new Date())],
      placement: 'left'
    },
    {
      label: 'Huidig jaar',
      value: [startOfYear(new Date()), endOfDay(new Date())],
      placement: 'left'
    },

  ];

  function handleChange(value: DateRange | null) {
    if (value !== null) {
      setStartDate(value[0])
      setEndDate(value[1])
    }
  }

  return(
    <DateRangePicker
      cleanable={false}
      ranges={ranges}
      showOneCalendar={true}
      disabledDate={after(new Date())}
      editable={false}
      defaultValue={[startOfDay(subDays(new Date(), 7)), endOfDay(new Date())]}
      onChange={ (e) => {handleChange(e);}}
      format={"dd-MM-yyyy"}
      placeholder="Selecteer datumbereik"
      className="dateRangePicker"
      size="lg"
    />
  )
}
