import React, {useContext} from 'react';
import {ColumnDefinitionType, Namespace, TableAction} from '../../../types';
import {Paper} from '@mui/material';
import DataTable from '../../../elements/DataTable/DataTable';
import {deleteNamespace, getNamespaces, synchronizeNamespace} from "../../../api/namespace";
import {AddBox, DeleteOutlined, EditOutlined, SyncOutlined} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import CustomerLinkButton from "../../CustomerResource/CustomerLinkButton";
import {hasRole} from "../../../services/role";
import {UserContext} from "../../../provider/UserProvider";
import NamespaceProjectLinkButton from "../../ProjectResource/NamespaceProjectLinkButton";

const typeMap = {
  sulu: 'Sulu',
  sylius: 'Sylius',
  akeneo: 'Akeneo',
  symfony: 'Symfony',
  shopware: 'Shopware',
  shopware_frontend: 'Shopware frontend',
  frontend: 'Frontend',
  monorepo: 'Monorepo',
} as any;

const columns: Array<ColumnDefinitionType<Namespace>> = [
  { key: 'name', title: 'Naam', type: 'string', sortable: true, filter: true },
  { key: 'cluster.name', title: 'Cluster', type: 'string', sortable: true, filter: true },
  { key: 'customer.name', title: 'Klant', type: 'custom', sortable: true, filter: true, content: (row) => {
    if (row.customer !== null) {
      return row.customer.name;
    }

    return <CustomerLinkButton resourceId={row.id} resourceType="Kubernetes namespace"/>
    }},
  { key: 'project.domain', title: 'Project', type: 'custom', sortable: true, filter: true, content: (row) => {
    if (row.project !== null) {
      return `${row.project.domain} - ${typeMap[row.project.type]}`
    }

    return <NamespaceProjectLinkButton namespace={row} customer={row.customer || undefined}/>
  }},
  { key: 'tags', title: 'Tags', type: 'tags', sortable: true },
];

const Overview = () => {
  const {user} = useContext(UserContext);
  const navigate = useNavigate();
  const {data: namespaces, isFetching, isLoading} = getNamespaces();
  const mutation = deleteNamespace();
  const synchronizeMutation = synchronizeNamespace();

  const toolbarActions: Array<TableAction<undefined>> = [
    {
      title: 'Namespace aanmaken',
      icon: <AddBox />,
      variant: 'primary',
      onClick: () => navigate('/kubernetes/namespace/new'),
      type: 'button',
      condition: () => hasRole(user, ['ROLE_KUBERNETES_NAMESPACE_CREATE'])
    },
  ];

  const actions: Array<TableAction<Namespace>> = [
    {
      title: 'Synchroniseren',
      icon: <SyncOutlined />,
      variant: 'primary',
      onClick: synchronizeMutation.mutateAsync,
      type: 'loading_button',
    },
    {
      title: 'Bewerken',
      icon: <EditOutlined />,
      variant: 'primary',
      onClick: (namespace) => navigate(`/kubernetes/namespace/${namespace.id}`),
      type: 'button',
      condition: () => hasRole(user, ['ROLE_KUBERNETES_NAMESPACE_UPDATE']),
    },
    {
      title: 'Verwijderen',
      icon: <DeleteOutlined />,
      variant: 'danger',
      onClick: mutation.mutate,
      type: 'button',
      condition: () => hasRole(user, ['ROLE_KUBERNETES_NAMESPACE_DELETE']),
      disabled: (namespace) => namespace.tags.includes('system'),
    },
  ];

  return (
    <Paper>
      <DataTable
        columns={columns}
        rows={namespaces.filter((namespace: Namespace) => namespace.name !== '__idle__')}
        actions={actions}
        loading={isLoading || isFetching}
        toolbar={{
          title: 'Kubernetes namespaces',
          actions: toolbarActions,
        }}
        pagination
        defaultRowsPerPage={10}
      />
    </Paper>
  );
};

export default Overview;
