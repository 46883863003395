import {Breadcrumbs, Link} from '@mui/material';
import {BreadcrumbItem} from '../../types';
import React from 'react';
import {
  Link as RouterLink,
} from 'react-router-dom';
import Typography from '@mui/material/Typography';

interface Props {
  items: Array<BreadcrumbItem>;
}

const Breadcrumb = ({items} : Props) => {
  return (
    <Breadcrumbs
      separator="/"
      sx={{mb: 1.5}}>
      {items.map((item : BreadcrumbItem, i) => {
        if (item.url !== null) {
          return (
            <Link key={i} sx={{textDecoration: 'none'}} color="text.primary" to={item.url} component={RouterLink}>
              {item.title}
            </Link>
          );
        }
        return (
          <Typography key={i} color="inherit">{item.title}</Typography>
        );
      })}
    </Breadcrumbs>
  );
};

export default Breadcrumb;
