import {Box, Button, Checkbox, FormControlLabel, Grid, Paper, Typography} from '@mui/material';
import React from 'react';
import {useNavigate} from "react-router-dom";
import {joiResolver} from "@hookform/resolvers/joi";
import Joi from "joi";
import {Controller, FormProvider, useForm} from "react-hook-form";
import TextField from '../../../elements/Form/TextField';
import Autocomplete from '../../../elements/Form/Autocomplete';
import { LoadingButton } from '@mui/lab';
import validationSchema from "../../../services/validationSchema";
import {ElasticDeployment} from "../../../types";
import {createDeployment, getDeploymentVersions} from "../../../api/elasticDeployment";
import CustomerSelect from "../../../elements/Form/CustomerSelect";

const Form = () => {
  const navigate = useNavigate();
  const {data: versions} = getDeploymentVersions();
  const mutation = createDeployment();

  const schema = validationSchema.object({
    name: Joi.string().required(),
    customer: Joi.object().required(),
  }).unknown();

  const form = useForm<ElasticDeployment>({
    defaultValues: {name: '', version: '', enterpriseSearch: false, customer: null},
    resolver: joiResolver(schema),
    mode: 'onBlur',
  });

  const onSubmit = (values: ElasticDeployment) => {
    return mutation.mutateAsync(values).catch((error) => {
      Object.keys(error.response.data.errors || {}).forEach(key => {
        form.setError(key as keyof ElasticDeployment, {type: 'custom', message: error.response.data.errors[key]})
      })
    });
  }

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} sm={12} md={12} lg={6}>
        <Paper sx={{p: 6, maxWidth: '600px', margin: '0 auto'}}>
          {form.formState.isSubmitSuccessful ? (
            <>
              <Typography variant="h6" fontWeight="bold">Deployment aangemaakt</Typography>
              <Typography sx={{my: 1}}>De deployment wordt aangemaakt, dit kan enkele minuten duren. Synchroniseer de deployment later om toegang te krijgen.</Typography>
              <Button onClick={() => navigate(-1)} sx={{mt: 2}} color="primary" variant="contained">Bevestigen</Button>
            </>
          ) : (
            <>
              <Typography variant="h6" fontWeight="bold">Deployment aanmaken</Typography>
              <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)}>
                  <TextField name="name" label="Naam" />
                  <Autocomplete
                    options={versions}
                    name="version"
                    label="Versie"
                    getOptionLabel={(option: string) => option}
                  />
                  <CustomerSelect name="customer" label="Klant"/>
                  <Box>
                    <Controller
                      name="enterpriseSearch"
                      control={form.control}
                      render={({field}) => (
                        <FormControlLabel
                          control={<Checkbox {...field} name="enterpriseSearch" />}
                          label="Enterprise search"
                        />
                      )}
                    />
                  </Box>
                  <LoadingButton sx={{mt: 2}} type="submit" color="primary" variant="contained" loading={form.formState.isSubmitting}>
                    Aanmaken
                  </LoadingButton>
                  <Button onClick={() => navigate(-1)} sx={{mt: 2, ml: 1}} color="secondary" disabled={form.formState.isSubmitting}>Annuleren</Button>
                </form>
              </FormProvider>
            </>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
}

export default Form;
