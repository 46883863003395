import React, {ReactElement, useEffect, useState} from 'react';
import {DatePicker} from '../DatePicker';
import DataTableToolbar from "../../../elements/DataTable/DataTableToolbar";
import {addDays, endOfDay, startOfDay, subDays} from "date-fns";
import '../kubecost.css';
import {Paper} from "@mui/material";
import {BarChart} from './BarChart';
import client from '../../../services/client';
import DataTable from "../../../elements/DataTable/DataTable";
import {ColumnDefinitionType, KubecostCustomer} from "../../../types";
import {DataToolTip} from "./DataTooltip";
import {CheckPickerTree} from "./CheckPickerTree";

const columns: Array<ColumnDefinitionType<KubecostCustomer>> = [
  {key: 'customerName', title: 'Klant', type: 'string', sortable: true},
  {key: 'namespaces', title: '', type: 'custom', sortable: false},
  {key: 'cpuCost', title: 'CPU', type: 'price', sortable: true},
  {key: 'ramCost', title: 'RAM', type: 'price', sortable: true},
  {key: 'sharedCost', title: 'Shared', type: 'price', sortable: true},
  {key: 'totalCost', title: 'Totaal', type: 'price', sortable: true}
];

const Overview = () => {
  const [startDate, setStartDate] = useState(startOfDay(subDays(new Date(), 7)));
  const [endDate, setEndDate] = useState(endOfDay(addDays(new Date(), -1)));
  const [customerChartData, setChartData] = useState([]);
  const [customerTableData, setTableData] = useState([]);
  const [selectedCustomers, setSelectedCustomers] = useState('');

  useEffect(() => {
    client.get('kubecost/customer/compare', {
      params: {
        startDate: startDate,
        endDate: endDate,
        customers: selectedCustomers,
      }}).then((response: any) => setChartData(response.data));
    client.get('kubecost/customer/usage', {
      params: {
        startDate: startDate,
        endDate: endDate,
      }}).then((response: any) => setTableData(response.data));
    },[startDate, endDate, setChartData, setTableData, selectedCustomers]);

  const getGroupPickerData = (inputData: any) => {
    return inputData.reduce((acc: Array<{ label: any; value: any; children: Array<{ label: any; value: any; }>; }>, {
      customerName,
      customerCategory
    }: any) => {
      if (customerName === "\"Unassigned customer\"") {
        return acc;
      }
      const foundGroup = acc.find((group) => group.value === customerCategory);
      if (foundGroup) {
        foundGroup.children.push({label: customerName, value: customerName});
      } else {
        acc.push({
          label: customerCategory,
          value: customerCategory,
          children: [{ label: customerName, value: customerName }],
        });
      }
      return acc;
    }, []);
  }

  const getTableRows = () => {
    return customerTableData.map((value: any) => {
      return {
        ...value,
        totalCost: value.cpuCost + value.ramCost + value.sharedCost,
        namespaces: <DataToolTip data={value.namespaces} type="namespace"/>
      }
    })
  }

  const calculateSummedTotal = (costType: string) => {
    let cost: number = 0;
    getTableRows().forEach((value: any) => {
      cost += value[costType];
    })

    return cost;
  }

  const summedCosts = [
    null,
    calculateSummedTotal('cpuCost'),
    calculateSummedTotal('ramCost'),
    calculateSummedTotal('sharedCost'),
    calculateSummedTotal('totalCost'),
  ];

  const components: Array<ReactElement> = [
    <DatePicker setStartDate={setStartDate} setEndDate={setEndDate}/>,
    <CheckPickerTree inputData={customerTableData} setSelectedCustomers={setSelectedCustomers} placeholder="Filter grafiek op klanten" manipulateData={getGroupPickerData}/>
  ];

  return(
    <Paper className="customerDashboard">
      <DataTableToolbar title="Customer Dashboard" components={components} />
      <BarChart inputData={customerChartData} autoSkip={!selectedCustomers} labelType="customerName" />
      <DataTable columns={columns} rows={getTableRows()} summedValues={summedCosts}/>
    </Paper>
  )
}

export default Overview;
