import React, {useContext} from 'react';
import {
  ColumnDefinitionType, ObjectStoreAccount,
  TableAction
} from '../../../types';
import DataTable from '../../../elements/DataTable/DataTable';
import {Paper} from "@mui/material";
import {AddBox, DeleteOutlined, SyncOutlined} from "@mui/icons-material";
import {UserContext} from "../../../provider/UserProvider";
import {hasRole} from "../../../services/role";
import {useNavigate} from "react-router-dom";
import CustomerLinkButton from '../../CustomerResource/CustomerLinkButton';
import {deleteAccount, getAccounts, synchronizeAccount} from '../../../api/objectStoreAccount';

const Overview = () => {
  const navigate = useNavigate();
  const {user} = useContext(UserContext);
  const {data: users, isLoading, isFetching} = getAccounts();
  const mutation = deleteAccount();
  const syncMutation = synchronizeAccount();

  const columns: Array<ColumnDefinitionType<ObjectStoreAccount>> = [
    { key: 'name', title: 'Naam', type: 'string', sortable: true, filter: true },
    { key: 'customer.name', title: 'Klant', type: 'custom', sortable: true, content: (row) => {
      if (row.customer !== null) {
        return row.customer.name;
      }

      return <CustomerLinkButton resourceId={row.id} resourceType="Object store account"/>
    }},
  ];

  const toolbarActions: Array<TableAction<undefined>> = [
    {
      title: 'Account aanvragen',
      icon: <AddBox />,
      variant: 'primary',
      onClick: () => navigate('/os/account/request'),
      type: 'button' ,
      condition: () => hasRole(user, ['ROLE_OBJECT_STORAGE_ACCOUNT_GET']),
    },
    {
      title: 'Account aanmaken',
      icon: <AddBox />,
      variant: 'primary',
      onClick: () => navigate('/os/account/new'),
      type: 'button' ,
      condition: () => hasRole(user, ['ROLE_OBJECT_STORAGE_ACCOUNT_CREATE']),
    },
  ];

  const actions: Array<TableAction<ObjectStoreAccount>> = [
    { title: 'Synchroniseren', icon: <SyncOutlined />, variant: 'primary', onClick: syncMutation.mutateAsync, type: 'loading_button', condition: () => hasRole(user, ['ROLE_OBJECT_STORAGE_ACCOUNT_GET']) },
    { title: 'Verwijderen', icon: <DeleteOutlined />, variant: 'danger', onClick: mutation.mutate, type: 'button', condition: () => hasRole(user, ['ROLE_OBJECT_STORAGE_ACCOUNT_DELETE']) },
  ];

  return (
    <Paper>
      <DataTable
        columns={columns}
        rows={users}
        loading={isLoading || isFetching}
        actions={actions}
        toolbar={{
          title: 'Objectstore accounts',
          actions: toolbarActions,
        }}
        defaultRowsPerPage={10}
        pagination
      />
    </Paper>
  );
};

export default Overview;
