import React, {useContext} from 'react';
import {ColumnDefinitionType, TableAction, ElasticUser} from '../../../types';
import DataTable from '../../../elements/DataTable/DataTable';
import {Paper} from "@mui/material";
import {AddBox, DeleteOutlined, InfoOutlined} from "@mui/icons-material";
import {UserContext} from "../../../provider/UserProvider";
import {hasRole} from "../../../services/role";
import {useNavigate} from "react-router-dom";
import {deleteUser, getUsers} from "../../../api/elasticUser";
import ProjectLinkButton from "../../ProjectResource/ProjectLinkButton";

const Overview = () => {
  const navigate = useNavigate();
  const {user} = useContext(UserContext);
  const {data: users, isLoading, isFetching} = getUsers();
  const mutation = deleteUser();

  const columns: Array<ColumnDefinitionType<ElasticUser>> = [
    { key: 'name', title: 'Naam', type: 'string', sortable: true, filter: true },
    { key: 'deployment.name', title: 'Deployment', type: 'string', sortable: true, filter: true },
    { key: 'project.domain', title: 'Project', type: 'custom', sortable: true, content: (row) => {
        if (row.project !== null) {
          return row.project.domain;
        }

        return <ProjectLinkButton resourceId={row.id} resourceType="Elastic user"/>
      }},
  ];

  const toolbarActions: Array<TableAction<undefined>> = [
    {
      title: 'Gebruiker aanmaken',
      icon: <AddBox />,
      variant: 'primary',
      onClick: () => navigate('/elastic/user/new'),
      type: 'button' ,
      condition: () => hasRole(user, ['ROLE_ELASTIC_USER_CREATE']),
    },
  ];

  const actions: Array<TableAction<ElasticUser>> = [
    { title: 'Details', icon: <InfoOutlined />, variant: 'primary', onClick: (user: ElasticUser) => navigate(`/elastic/user/${user.id}/detail `), type: 'button' },
    { title: 'Verwijderen', icon: <DeleteOutlined />, variant: 'danger', onClick: mutation.mutate, type: 'button', condition: () => hasRole(user, ['ROLE_ELASTIC_USER_DELETE']) },
  ];

  return (
    <Paper>
      <DataTable
        columns={columns}
        rows={users}
        loading={isLoading || isFetching}
        actions={actions}
        toolbar={{
          title: 'Elastic gebruikers',
          actions: toolbarActions,
        }}
        defaultRowsPerPage={10}
        pagination
      />
    </Paper>
  );
};

export default Overview;
