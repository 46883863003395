import React, {useEffect} from 'react';
import {Paper, TextField} from '@mui/material';
import {AuditLog, ColumnDefinitionType, TableAction} from '../../types';
import client from '../../services/client';
import {AxiosResponse} from 'axios';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import {VisibilityOutlined} from '@mui/icons-material';
import {useNavigate} from 'react-router-dom';
import DataTable from "../../elements/DataTable/DataTable";
import {getDescription} from "../../services/audit";

const Overview = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [logs, setLogs] = React.useState<Array<any>>([]);
  const [query, setQuery] = React.useState<string>('');

  useEffect(() => {
    setLoading(true);

    client
      .get('audit', {
        params: {
          query: query,
        },
      })
      .then((response: AxiosResponse) => setLogs(response.data))
      .finally(() => setLoading(false));
  }, [query]);

  const columns: Array<ColumnDefinitionType<AuditLog>> = [
    {key: 'datetime', title: t('audit:date'), type: 'custom', sortable: true, content: (log: AuditLog) => moment(log.datetime).format('DD-MM-YYYY HH:mm:ss')},
    {
      key: 'scope',
      title: t('audit:scope'),
      type: 'custom',
      sortable: false,
      content: (log: AuditLog) => {
        return <span dangerouslySetInnerHTML={{
          __html: getDescription(log, t),
        }}/>;
      },
    },
  ];

  const components = [
    (
      <TextField
        key="audit-filter"
        placeholder="Zoeken . . ."
        variant="outlined"
        size="small"
        margin="none"
        onChange={(e) => setQuery(e.target.value)}
        sx={{ml: 2, width: '400px', input: {py: 2}}}
        fullWidth
      />
    ),
  ];

  const actions: Array<TableAction<AuditLog>> = [
    {title: t('audit:view'), icon: <VisibilityOutlined />, variant: 'warning', onClick: (log) => navigate(`/audit/${log.id}`), type: 'button'},
  ];

  return (
    <Paper>
      <DataTable
        toolbar={{
          title: t('audit:activities'),
          components: components,
        }}
        columns={columns}
        rows={logs}
        actions={actions}
        loading={loading}
        defaultOrder="desc"
      />
    </Paper>
  );
};

export default Overview;
