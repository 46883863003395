import {useMutation, useQuery} from "react-query";
import client from "../services/client";
import {ElasticDeployment} from "../types";
import confirm from "../elements/Confirmation/Confirmation";
import queryClient from "../services/queryClient";
import {toast} from "react-toastify";

export const getDeployments = () => {
  return useQuery({
    queryKey: ['elasticDeployments'],
    queryFn: () => client.get('elastic/deployment').then(response => response.data),
    initialData: [],
  });
};

export const getDeploymentVersions = () => {
  return useQuery({
    queryKey: ['elasticDeploymentVersions'],
    queryFn: () => client.get('elastic/deployment/version').then(response => response.data),
    initialData: [],
  });
};

export const getDeployment = (id: number) => {
  return useQuery({
    queryKey: ['elasticDeployment', id],
    queryFn: () => client.get(`elastic/deployment/${id}`).then(response => response.data),
    enabled: id !== 0,
    initialData: {
      id: 0,
      name: '',
      username: '',
      password: '',
      elasticsearchUrl: null,
      kibanaUrl: null,
      customer: null,
      users: [],
    },
  });
};

export const getDeploymentPassword = (id: number, enabled: boolean) => {
  return useQuery({
    queryKey: ['elasticDeployment', id, 'password'],
    queryFn: () => client.get(`elastic/deployment/${id}/password`).then(response => response.data),
    enabled: enabled,
    initialData: ''
  });
};

export const createDeployment = () => {
  return useMutation({
    mutationFn: async (deployment: ElasticDeployment) => {
      return client.post('elastic/deployment', {
        ...deployment,
        customer: deployment.customer?.id,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['elasticDeployments']});
      toast.success('Deployment aangemaakt');
    },
  });
};

export const deleteDeployment = () => {
  return useMutation({
    mutationFn: async (deployment: ElasticDeployment) => {
      if (await confirm({
        title: 'Deployment verwijderen',
        text: 'Deze actie kan niet ongedaan gemaakt worden',
      })) {
        return client.delete(`elastic/deployment/${deployment.id}`);
      }

      return Promise.reject(new Error('Cancelled'));
    },
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['elasticDeployments']});
      toast.success('Deployment verwijderd');
    },
  });
};

export const synchronizeDeployment = () => {
  return useMutation({
    mutationFn: async (deployment: ElasticDeployment) => {
      return client.post(`elastic/deployment/${deployment.id}/synchronize`);
    },
    onSuccess: () => {
      toast.success('Deployment gesynchroniseerd');
    },
  });
};

export const resetDeploymentPassword = () => {
  return useMutation({
    mutationFn: async (deployment: ElasticDeployment) => {
      return client.put(`elastic/deployment/${deployment.id}/reset-password`);
    },
    onSuccess: () => {
      toast.success('Wachtwoord is gewijzigd');
    },
  });
};
