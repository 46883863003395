import Button from "@mui/material/Button";
import {LinkOutlined} from "@mui/icons-material";
import React, {useState} from 'react';
import CustomerLinkForm from "./CustomerLinkForm";
import {OverridableStringUnion} from '@mui/types';
import {ButtonPropsVariantOverrides} from '@mui/material/Button/Button';
import {SxProps} from '@mui/system';
import {Theme} from '@mui/material/styles';

interface Props {
  resourceId: number;
  resourceType: string;
  variant?: OverridableStringUnion<'text' | 'outlined' | 'contained', ButtonPropsVariantOverrides>;
  sx?: SxProps<Theme>;
}

const CustomerLinkButton = ({variant = 'text', sx = {p: 1}, ...props}: Props) => {
  const [showForm, setShowForm] = useState<boolean>(false);

  return (
    <>
      <Button
        sx={sx}
        startIcon={<LinkOutlined/>}
        color="primary"
        onClick={() => setShowForm(true)}
        variant={variant}
      >
        Koppelen
      </Button>
      <CustomerLinkForm open={showForm} onClose={() => setShowForm(false)} {...props} />
    </>
  )
}

export default CustomerLinkButton;
