import React from 'react';
import {ColumnDefinitionType, TableAction} from '../../../types';
import {Paper} from '@mui/material';
import DataTable from '../../../elements/DataTable/DataTable';
import {SyncOutlined, VisibilityOutlined} from '@mui/icons-material';
import {Cluster} from "../../../types";
import {useNavigate} from "react-router-dom";
import {getClusters, synchronizeCluster} from "../../../api/kubernetesCluster";

const columns: Array<ColumnDefinitionType<Cluster>> = [
  { key: 'name', title: 'Naam', type: 'string', sortable: true },
  { key: 'externalId', title: 'Extern ID', type: 'string', sortable: true },
  { key: 'namespaces.length', title: 'Aantal namespaces', type: 'integer', sortable: true },
  { key: 'cost', title: 'Kosten', type: 'price', sortable: true},
];

const Overview = () => {
  const navigate = useNavigate();
  const {data: clusters, isFetching, isLoading} = getClusters();
  const mutation = synchronizeCluster();

  const actions: Array<TableAction<Cluster>> = [
    { title: 'Synchroniseren', icon: <SyncOutlined />, variant: 'primary', onClick: mutation.mutateAsync, type: 'loading_button' },
    { title: 'Bekijken', icon: <VisibilityOutlined />, variant: 'primary', onClick: (cluster: Cluster) => navigate(`/kubernetes/cluster/${cluster.id}}`), type: 'button' },
  ];

  return (
    <Paper>
      <DataTable
        columns={columns}
        rows={clusters}
        actions={actions}
        loading={isLoading || isFetching}
        toolbar={{
          title: 'Kubernetes clusters',
        }}
      />
    </Paper>
  );
};

export default Overview;
