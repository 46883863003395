import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import {Box} from '@mui/material';
import {confirmable, createConfirmation} from 'react-confirm';

type Props = {
  title: string;
  text: string;
  show: boolean;
  confirmation: any;
  proceed: (proceed: boolean) => void;
  dismiss: () => void;
};

const Confirmation: React.FC<Props> = ({title, text, show, proceed, dismiss}: Props) => {
  return (
    <Dialog open={show} onClose={dismiss}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => proceed(true)} color="primary">Bevestigen</Button>
        <Button onClick={() => proceed(false)} color="primary">Annuleren</Button>
        <Box style={{flex: '1 0 0'}} />
      </DialogActions>
    </Dialog>
  );
};

// @ts-ignore
const confirmation = confirmable(Confirmation);
const confirm = createConfirmation(confirmation);

export default confirm;
