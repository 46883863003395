import {createTheme} from '@mui/material/styles';

let theme = createTheme({
  palette: {
    text: {
      primary: '#000',
    },
    primary: {
      light: '#434DF7',
      main: '#434DF7',
      dark: '#434DF7',
    },
    secondary: {
      light: '#000',
      main: '#000',
      dark: '#000',
    },
    success: {
      main: '#58d729',
    },
  },
  typography: {
    fontFamily: 'GroteskTextCasual',
    h1: {
      fontFamily: 'GroteskWide',
    },
    h2: {
      fontFamily: 'GroteskWide',
    },
    h3: {
      fontFamily: 'GroteskWide',
    },
    h4: {
      fontFamily: 'GroteskWide',
    },
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
      fontFamily: 'GroteskWide',
    },
    h6: {
      fontFamily: 'GroteskWide',
    },
    body1: {
      fontSize: 14,
    },
  },
  shape: {
    borderRadius: 4,
  },
  mixins: {
    toolbar: {
      minHeight: 64,
    },
  },
});

theme = {
  ...theme,
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
          textTransform: 'none',
          borderRadius: theme.shape.borderRadius,
          fontFamily: 'GroteskWide',
          '&.MuiButton--table': {
            fontFamily: 'GroteskTextCasual',
          },
        },
        contained: {
          boxShadow: 'none',
          '&:active': {
            boxShadow: 'none',
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          marginLeft: theme.spacing(1),
        },
      },
    },
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          textTransform: 'none',
          margin: '0 16px',
          minWidth: 0,
          padding: 0,
          [theme.breakpoints.up('md')]: {
            padding: 0,
            minWidth: 0,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: theme.spacing(1),
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          borderRadius: 4,
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: 32,
          height: 32,
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        rounded: {
          borderRadius: '4px',
        },
        root: {
          boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, .1)',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
        margin: 'normal',
        fullWidth: true,
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          border: 'none',
          boxShadow: 'none',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '8px 24px',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          transition: 'color .15s ease-in-out',
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          transition: 'color .15s ease-in-out',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: theme.shape.borderRadius,
        },
        filled: {
          border: '1px solid #172630',
          borderColor: theme.palette.primary.main,
          backgroundColor: 'rgb(41, 80, 195, 0.1)',
          color: theme.palette.primary.main,
          '&.MuiChip--success': {
            borderColor: theme.palette.success.main,
            color: theme.palette.success.main,
            backgroundColor: `${theme.palette.success.main}05`
          },
          '&.MuiChip--warning': {
            borderColor: theme.palette.warning.main,
            color: theme.palette.warning.main,
            backgroundColor: `${theme.palette.warning.main}05`
          },
          '&.MuiChip--danger': {
            borderColor: theme.palette.error.main,
            color: theme.palette.error.main,
            backgroundColor: `${theme.palette.error.main}05`
          },
        },
      },
    },
  },
};

export default theme;
