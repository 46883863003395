import React, { useEffect } from 'react';
import {ColumnDefinitionType, Customer, CustomerZoneLink, TableAction} from '../../types';
import client from '../../services/client';
import { Paper } from '@mui/material';
import DataTable from '../../elements/DataTable/DataTable';
import {AddBox, CardMembership, Dns, Link, ListAlt} from '@mui/icons-material';
import { toast } from 'react-toastify';
import ZoneForm from './ZoneForm';
import {useNavigate} from "react-router-dom";

const columns: Array<ColumnDefinitionType<Customer>> = [
  { key: 'name', title: 'Naam', type: 'string', sortable: true },
  { key: 'amountOfUsers', title: 'Gebruikers', type: 'integer', sortable: true },
  { key: 'amountOfZones', title: 'DNS zones', type: 'integer', sortable: true },
  { key: 'amountOfProjects', title: 'Projecten', type: 'integer', sortable: true },
  { key: 'amountOfResources', title: 'Resources', type: 'integer', sortable: true },
];

const Overview = () => {
  const navigate = useNavigate();
  const [customers, setCustomers] = React.useState<Array<Customer>>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [showForm, setShowForm] = React.useState<boolean>(false);
  const [showProjectForm, setShowProjectForm] = React.useState<boolean>(false);

  useEffect(() => {
    if (showForm || showProjectForm) {
      return;
    }

    setLoading(true);
    client
      .get('customer?active=true')
      .then((response) => setCustomers(response.data))
      .finally(() => setLoading(false));
  }, [showForm, showProjectForm]);

  const redirectToResources = (customer: Customer) => {
    navigate(`/customer/${customer.id}`);
  };

  const redirectToZones = (customer: Customer) => {
    navigate(`/customer/${customer.id}/zone`);
  };

  const redirectToDetails = (customer: Customer) => {
    navigate(`/customer/${customer.id}/data`);
  };

  const linkZone = ({ id, zoneId }: CustomerZoneLink) => {
    client
      .post(`customer/${id}/zone/${zoneId}`)
      .then(() => toast.success('Zone gekoppeld'))
      .finally(() => setShowForm(false));
  };

  const actions: Array<TableAction<Customer>> = [
    { title: 'Resources', icon: <ListAlt />, variant: 'primary', onClick: redirectToResources, type: 'button' },
    { title: 'Zones', icon: <Dns />, variant: 'primary', onClick: redirectToZones, type: 'button' },
    { title: 'Details', icon: <CardMembership />, variant: 'primary', onClick: redirectToDetails, type: 'button' },
  ];

  const toolbarActions: Array<TableAction<undefined>> = [
    { title: 'Zones koppelen', icon: <Link />, variant: 'primary', onClick: () => setShowForm(true), type: 'button' },
    { title: 'Project koppelen', icon: <AddBox />, variant: 'primary', onClick: () => setShowProjectForm(true), type: 'button' },
  ];

  return (
    <Paper>
      <DataTable
        columns={columns}
        rows={customers.filter(c => c.amountOfProjects > 0 || c.amountOfZones > 0 || c.amountOfUsers > 0 || c.amountOfResources > 0)}
        actions={actions}
        loading={loading}
        toolbar={{
          title: 'Klanten',
          actions: toolbarActions,
        }}
      />
      <ZoneForm open={showForm} onClose={() => setShowForm(false)} onConfirm={linkZone} />
    </Paper>
  );
};

export default Overview;
