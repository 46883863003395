import React from 'react';
import {
  Avatar, Box, Button,
  Chip,
  Divider,
  Grid,
  Paper,
  Stack, Table, TableBody, TableCell, TableHead, TableRow, TextField,
  Typography
} from '@mui/material';
import Breadcrumb from '../App/Breadcrumb';
import {useParams} from 'react-router-dom';
import {downloadCertificate, getCertificate} from '../../api/sslCertificate';
import moment from 'moment';
import {AccessTimeOutlined, Check, Download} from '@mui/icons-material';

const Detail = () => {
  const {id} = useParams();
  const {data: certificate} = getCertificate(+(id || 0));
  const mutation = downloadCertificate();

  const items = [
    { title: 'SSL Certificaten', url: '/ssl/certificate' },
    { title: certificate.commonName, url: null },
  ];

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <Stack
          direction={{xs: 'column', sm: 'row'}}
          alignItems={{xs: 'left', sm: 'center'}}
          sx={{mb: 4, whiteSpace: 'nowrap'}}
          gap={2}
          flexWrap="wrap"
        >
          <Stack direction="column">
            <Typography variant="h5" sx={{fontWeight: 'bold'}}>{certificate.commonName}</Typography>
            <Breadcrumb items={items} />
          </Stack>
          <Box sx={{flex: '1 0 auto', display: 'flex'}}/>
          {certificate.status !== 'requested' && (
            <Button
              startIcon={<Download/>}
              variant="outlined"
              onClick={() => mutation.mutate({id: certificate.id, name: certificate.commonName})}
            >
              Downloaden
            </Button>
          )}
        </Stack>
        <Paper sx={{p: 6}}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Stack direction="row" gap={1.5} alignItems="center">
                <Avatar sx={{width: 48, height: 48}}>
                  <AccessTimeOutlined />
                </Avatar>
                <Typography>
                  <strong>Verloopdatum</strong><br />
                  {certificate.status === 'requested' ? 'N.v.t.' : moment(certificate.expirationDate).format('DD-MM-YYYY')}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={4}>
              <Stack direction="row" gap={1.5} alignItems="center">
                <Avatar sx={{width: 48, height: 48}}>
                  <AccessTimeOutlined />
                </Avatar>
                <Typography>
                  <strong>Aanschafdatum</strong><br />
                  {moment(certificate.orderDate).format('DD-MM-YYYY')}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={4}>
              <Stack direction="row" gap={1.5} alignItems="center">
                <Avatar sx={{width: 48, height: 48}}>
                </Avatar>
                <Typography>
                  <strong>Aanvrager</strong><br />
                  {certificate.requestedBy?.name || 'Onbekend'}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
          <Table sx={{mt: 4}}>
            <TableHead>
              <TableCell sx={{fontWeight: 'bold', pl: 0}}>Domein</TableCell>
              <TableCell sx={{fontWeight: 'bold'}}>Status</TableCell>
            </TableHead>
            <TableBody>
              {Object.keys(certificate.domains).map((domain: string) => (
                <TableRow key={domain}>
                  <TableCell sx={{pl: 0}}>{domain}</TableCell>
                  <TableCell>
                    {certificate.domains[domain] ? (
                      <Chip label="Actief" className="MuiChip--success" />
                    ) : (
                      <Chip label="Inactief" className="MuiChip--danger" />
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {certificate.status === 'requested' && (
            <Box sx={{mt: 4}}>
              <Typography variant="subtitle1">Records</Typography>
              {certificate.records.map((record: any) => (
                <Box key={record.name}>
                  <TextField label="Naam" disabled={true} value={record.name} variant="outlined" size="small"/>
                  <TextField label="Waarde" disabled={true} value={record.value.toLowerCase()} variant="outlined" size="small"/>
                  <TextField
                    label="Huidige waarde"
                    disabled={true}
                    value={record.current.toLowerCase() ?? ' '}
                    variant="outlined"
                    size="small"
                    InputLabelProps={{shrink: true}}
                    InputProps={{
                      startAdornment: record.current.toLowerCase() === record.value.toLowerCase() ? <Check fontSize="small" color="success" /> : ''
                    }}
                  />
                  <Divider sx={{my: 2}} />
                </Box>
              ))}
            </Box>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
}

export default Detail;
