import React from 'react';
import { Log, StringMap } from '../../types';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from '@mui/material';
import { green, red } from '@mui/material/colors';

interface Props {
    log: Log;
    open: boolean;
    onClose: () => void;
}

const DetailDialog = ({ log, open, onClose }: Props) => {
    const keys = Object.keys(log.oldValues || log.newValues || []);
    const keyMap: StringMap = {
        name: 'Naam',
        type: 'Type',
        content: 'Content',
        priority: 'Prioriteit',
        ttl: 'TTL',
        destination: 'Bestemming',
    };

    const oldValue = (property: string) => (log.oldValues === null ? '' : log.oldValues[property] || '');
    const newValue = (property: string) => (log.newValues === null ? '' : log.newValues[property] || '');
    const type = () => {
        if ('' === oldValue('type')) {
            return newValue('type');
        }

        if ('' === newValue('type')) {
            return oldValue('type');
        }

        return '';
    };

    return (
        <Dialog onClose={onClose} open={open} fullWidth>
            <DialogTitle>
                {log.scope} - {log.title} door {log.user.name}
            </DialogTitle>
            <DialogContent sx={{overflow: 'visible', minWidth: '400px'}}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            <TableCell>Oud</TableCell>
                            <TableCell>Nieuw</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {keys
                            .filter((key: string) => {
                                if (key !== 'priority') {
                                    return true;
                                }

                                return type() === 'SRV' || type() === 'MX';
                            })
                            .map((key: string) => (
                                <TableRow key={key}>
                                    <TableCell>{keyMap[key]}</TableCell>
                                    <TableCell sx={{
                                      ...(oldValue(key) !== newValue(key) && {backgroundColor: red[100], color: red[500]})
                                    }}>
                                        {null === log.oldValues ? '' : log.oldValues[key]}
                                    </TableCell>
                                    <TableCell sx={{
                                      ...(oldValue(key) !== newValue(key) && {backgroundColor: green[100], color: green[500]})
                                    }}>
                                        {null === log.newValues ? '' : log.newValues[key]}
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </DialogContent>
        </Dialog>
    );
};

export default DetailDialog;
