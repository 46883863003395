import React, {ReactElement, useEffect, useState} from 'react';
import {Namespace} from "../../../types";
import {Box, Card, CardContent, Grid, Link, Paper, Typography} from "@mui/material";
import DataTableToolbar from "../../../elements/DataTable/DataTableToolbar";
import client from "../../../services/client";
import {useNavigate, useParams} from "react-router-dom";
import Kubernetes from "../../../elements/Icon/Kubernetes";
import Dollar from "../../../elements/Icon/Dollar";
import {blue, red} from "@mui/material/colors";
import Breadcrumb from "../../App/Breadcrumb";
import Customer from "../../../elements/Icon/Customer";
import {StackedBar} from "../../Kubecost/StackedBar";
import {DatePicker} from "../../Kubecost/DatePicker";
import {addDays, endOfDay, startOfDay, subDays} from "date-fns";

const Detail = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(startOfDay(subDays(new Date(), 7)));
  const [endDate, setEndDate] = useState(endOfDay(addDays(new Date(), -1)));
  const [namespace, setNamespace] = React.useState<Namespace|null>(null);

  useEffect(() => {
    client
      .get(`kubernetes/namespace/${params.id}/statistics`, {
        params: {
          startDate: startDate,
          endDate: endDate,
        },
      })
      .then((response: any) => setNamespace(response.data))
  }, [startDate, endDate]);

  if (namespace === null) {
    return <></>;
  }

  const components: Array<ReactElement> = [
    <DatePicker setStartDate={setStartDate} setEndDate={setEndDate}/>,
  ];

  const items = [
    { title: 'Kubernetes', url: null },
    { title: 'Namespaces', url: null },
    { title: namespace.name, url: null },
  ]

  return (
    <>
      <Box sx={{mb: 5}}>
        <Typography variant="h5" sx={{fontWeight: 'bold'}}>{namespace.name} namespace</Typography>
        <Breadcrumb items={items} />
      </Box>
      <Grid container spacing={4} sx={{mb: 4}}>
        <Grid item xs={4}>
          <Card>
            <CardContent sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', p: 3}}>
              <Box>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                  Kubernetes cluster
                </Typography>
                <Link
                  variant="h5"
                  component="div"
                  underline="hover"
                  color="inherit"
                  onClick={() => navigate(`/kubernetes/cluster/${namespace?.cluster?.id}`)}
                  sx={{cursor: 'pointer'}}
                >
                  {namespace.cluster?.name}
                </Link>
              </Box>
              <Box>
                <Kubernetes/>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card>
            <CardContent sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', p: 3}}>
              <Box>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                  Kosten
                </Typography>
                <Typography variant="h5" component="div">
                  € {namespace.cost.toFixed(2).replace('.', ',')}
                </Typography>
              </Box>
              <Box>
                <Dollar color={red[200]}/>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card>
            <CardContent sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', p: 3}}>
              <Box>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                  Klant
                </Typography>
                <Link
                  variant="h5"
                  component="div"
                  underline="hover"
                  color="inherit"
                  onClick={() => navigate(`/customer/${namespace?.customer?.id}`)}
                  sx={{cursor: 'pointer'}}
                >
                  {namespace.customer?.name}
                </Link>
              </Box>
              <Box>
                <Customer color={blue[200]}/>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Paper>
        <DataTableToolbar title="Kosten" components={components} />
        <StackedBar kubeData={namespace.statistics}/>
      </Paper>
    </>
  );
}

export default Detail;
