import React, {ReactElement, useState} from 'react';
import {ColumnDefinitionType, WebservicesData} from '../../types';
import {Paper, Box, Typography, Stack, MenuItem, ToggleButtonGroup, ToggleButton, Button} from '@mui/material';
import DataTable from '../../elements/DataTable/DataTable';
import {useNavigate, useParams} from "react-router-dom";
import Breadcrumb from "../App/Breadcrumb";
import {getWebservicesUser} from '../../api/webservicesUser';
import TextField from '@mui/material/TextField';
import CustomerLinkButton from '../CustomerResource/CustomerLinkButton';
import {EuroSymbol} from '@mui/icons-material';

const months = [
  { title: 'Januari', value: 1 },
  { title: 'Februari', value: 2 },
  { title: 'Maart', value: 3 },
  { title: 'April', value: 4 },
  { title: 'Mei', value: 5 },
  { title: 'Juni', value: 6 },
  { title: 'Juli', value: 7 },
  { title: 'Augustus', value: 8 },
  { title: 'September', value: 9 },
  { title: 'Oktober', value: 10 },
  { title: 'November', value: 11 },
  { title: 'December', value: 12 },
];

const quarters = [
  { title: 'Q1', value: 1 },
  { title: 'Q2', value: 2 },
  { title: 'Q3', value: 3 },
  { title: 'Q4', value: 4 },
];

const years = [
  { title: '2022', value: 2022 },
  { title: '2023', value: 2023 },
  { title: '2024', value: 2024 },
]

const Detail = () => {
  const navigate = useNavigate();
  const [month, setMonth] = useState<number>(new Date().getMonth() + 1);
  const [quarter, setQuarter] = useState<number>(Math.ceil((new Date().getMonth() + 1) / 3));
  const [year, setYear] = useState<number>(new Date().getFullYear());
  const [period, setPeriod] = useState<'monthly' | 'quarterly'>('monthly');
  const {id} = useParams();
  const {data: user} = getWebservicesUser(+(id || 0), period);

  const columns: Array<ColumnDefinitionType<WebservicesData>> = [
    { key: 'name', title: 'Naam', type: 'string', sortable: true },
    { key: 'calls', title: 'Aantal calls', type: 'string', sortable: true },
    { key: 'price', title: 'Prijs per call', type: 'price', sortable: true },
    { key: 'total', title: 'Totaal', type: 'price', sortable: true },
  ];

  const items = [
    { title: 'Webservices', url: '/webservices/user' },
    { title: user.name, url: null },
  ];

  const components: Array<ReactElement> = [(
    <TextField
      select
      label="Jaar"
      margin="none"
      fullWidth
      variant="outlined"
      sx={{maxWidth: '150px', ml: 2}}
      onChange={(event) => setYear(+event.target.value)}
      value={year}
    >
      {years.map((option) => (
        <MenuItem key={option.title} value={option.value}>{option.title}</MenuItem>
      ))}
    </TextField>
  )];

  if (period === 'monthly') {
    components.push((
      <TextField
        select
        label="Maand"
        margin="none"
        fullWidth
        variant="outlined"
        sx={{maxWidth: '150px', ml: 2}}
        onChange={(event) => setMonth(+event.target.value)}
        value={month}
      >
        {months.map((option) => (
          <MenuItem key={option.title} value={option.value}>{option.title}</MenuItem>
        ))}
      </TextField>
    ));
  } else {
    components.push((
      <TextField
        select
        label="Kwartaal"
        margin="none"
        fullWidth
        variant="outlined"
        sx={{maxWidth: '150px', ml: 2}}
        onChange={(event) => setQuarter(+event.target.value)}
        value={month}
      >
        {quarters.map((option) => (
          <MenuItem key={option.title} value={option.value}>{option.title}</MenuItem>
        ))}
      </TextField>
    ));
  }

  const getRows = () => {
    const selectedPeriod = 'monthly' === period ? month : quarter;
    if (Object.keys(user.data).length === 0 || user.data[year] === undefined || user.data[year][selectedPeriod] === undefined) {
      return [];
    }

    return user.data[year][selectedPeriod];
  }

  const toggle = (value: null|'monthly'|'quarterly') => {
    if (value === null) {
      return;
    }

    setPeriod(value);
  }

  return (
    <>
      <Stack
        direction={{xs: 'column', sm: 'row'}}
        alignItems={{xs: 'left', sm: 'center'}}
        sx={{mb: 4, whiteSpace: 'nowrap'}}
        gap={2}
        flexWrap="wrap"
      >
        <Stack direction="column">
          <Typography variant="h5" sx={{fontWeight: 'bold'}}>{user.name} {user.customer === null ? '' : `- ${user.customer.name}`}</Typography>
          <Breadcrumb items={items} />
        </Stack>
        <Box sx={{flex: '1 0 auto', display: 'flex'}}/>
        <ToggleButtonGroup color="primary" exclusive value={period} onChange={(e, value) => toggle(value)}>
          <ToggleButton value="monthly" sx={{textTransform: 'none', p: 1.5}}>Per maand</ToggleButton>
          <ToggleButton value="quarterly" sx={{textTransform: 'none', p: 1.5}}>Per kwartaal</ToggleButton>
        </ToggleButtonGroup>
        {user.customer === null && (
          <CustomerLinkButton resourceId={user.id} resourceType="Webservices user" variant="outlined" sx={{}} />
        )}
        {user.customer !== null && (
          <Button
            startIcon={<EuroSymbol/>}
            onClick={() => navigate(`/webservices/user/${user.id}/bill`)}
            variant="outlined"
          >
            Factureren
          </Button>
        )}
      </Stack>
      <Paper>
        <DataTable
          columns={columns}
          rows={getRows()}
          actions={[]}
          toolbar={{
            title: 'Verbruik',
            components: components,
          }}
        />
      </Paper>
    </>
  );
};

export default Detail;
