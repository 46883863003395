import {useMutation, useQuery} from 'react-query';
import client from '../services/client';
import {ObjectStoreBucket} from '../types';
import queryClient from '../services/queryClient';
import {toast} from 'react-toastify';
import confirm from '../elements/Confirmation/Confirmation';

export const getBuckets = () => {
  return useQuery({
    queryKey: ['objectStoreBuckets', 'resources'],
    queryFn: () => client.get('os/bucket').then(response => response.data),
    initialData: [],
  });
};

export const getBucket = (id: number) => {
  return useQuery({
    queryKey: ['objectStoreBucket', id],
    queryFn: () => client.get(`os/bucket/${id}`).then(response => response.data),
    enabled: id !== 0,
    initialData: {
      id: 0,
      name: '',
      account: null,
      project: null,
    },
  });
};

export const createBucket = () => {
  return useMutation({
    mutationFn: async (bucket: ObjectStoreBucket) => {
      return client.post('os/bucket', {
        ...bucket,
        account: bucket.account?.id,
        project: bucket.project?.id,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['objectStoreBuckets']});
      toast.success('Bucket aangemaakt');
    },
  });
};

export const updateBucket = () => {
  return useMutation({
    mutationFn: async (bucket: ObjectStoreBucket) => {
      return client.put(`os/bucket/${bucket.id}`, {
        ...bucket,
        project: bucket.project?.id,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['objectStoreBuckets']});
      toast.success('Bucket gewijzigd');
    },
  });
};

export const deleteBucket = () => {
  return useMutation({
    mutationFn: async (bucket: ObjectStoreBucket) => {
      if (await confirm({
        title: 'Bucket verwijderen',
        text: 'Deze actie kan niet ongedaan gemaakt worden',
      })) {
        return client.delete(`os/bucket/${bucket.id}`);
      }

      return Promise.reject(new Error('Cancelled'));
    },
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['objectStoreBuckets']});
      toast.success('Bucket verwijderd');
    },
  });
};
