import React, {useContext} from 'react';
import {ColumnDefinitionType, TableAction, WebservicesUser} from '../../types';
import DataTable from '../../elements/DataTable/DataTable';
import {Paper} from "@mui/material";
import {AddBox, EuroSymbol, ZoomInOutlined} from "@mui/icons-material";
import {UserContext} from "../../provider/UserProvider";
import {hasRole} from "../../services/role";
import {useNavigate} from "react-router-dom";
import CustomerLinkButton from "../CustomerResource/CustomerLinkButton";
import {getWebservicesUsers} from '../../api/webservicesUser';

const Overview = () => {
  const navigate = useNavigate();
  const {user} = useContext(UserContext);
  const {data: users, isLoading, isFetching} = getWebservicesUsers();

  const columns: Array<ColumnDefinitionType<WebservicesUser>> = [
    { key: 'name', title: 'Naam', type: 'string', sortable: true, filter: true },
    { key: 'customer.name', title: 'Klant', type: 'custom', sortable: true, content: (row) => {
      if (row.customer !== null) {
        return row.customer.name;
      }

      return <CustomerLinkButton resourceId={row.id} resourceType="Webservices user"/>
    }},
  ];

  const toolbarActions: Array<TableAction<undefined>> = [
    {
      title: 'Gebruiker aanmaken',
      icon: <AddBox />,
      variant: 'primary',
      onClick: () => navigate('/webservices/user/new'),
      type: 'button' ,
      condition: () => hasRole(user, ['ROLE_WEBSERVICES_USER_CREATE']),
    },
  ];

  const actions: Array<TableAction<WebservicesUser>> = [
    { title: 'Factureren', icon: <EuroSymbol />, variant: 'primary', onClick: (user) => navigate(`/webservices/user/${user.id}/bill`), type: 'button', condition: () => hasRole(user, ['ROLE_ADMIN']) },
    { title: 'Bekijken', icon: <ZoomInOutlined />, variant: 'primary', onClick: (user) => navigate(`/webservices/user/${user.id}`), type: 'button', condition: () => hasRole(user, ['ROLE_WEBSERVICES_USER_GET']) },
  ];

  return (
    <Paper>
      <DataTable
        columns={columns}
        rows={users}
        loading={isLoading || isFetching}
        actions={actions}
        toolbar={{
          title: 'Webservices gebruikers',
          actions: toolbarActions,
        }}
        defaultRowsPerPage={10}
        pagination
      />
    </Paper>
  );
};

export default Overview;
