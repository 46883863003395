import {useMutation, useQuery} from 'react-query';
import client from '../services/client';
import confirm from '../elements/Confirmation/Confirmation';
import {Namespace} from "../types";
import queryClient from '../services/queryClient';
import { toast } from 'react-toastify';

export const getNamespaces = (project: number|null = null) => {
  return useQuery({
    queryKey: ['namespaces'],
    queryFn: () => client.get('kubernetes/namespace', {
      params: {
        project: project,
      },
    }).then(response => response.data),
    initialData: [],
  });
};

export const getNamespace = (id: number) => {
  return useQuery({
    queryKey: ['namespace', id],
    queryFn: () => client.get(`kubernetes/namespace/${id}`).then(response => response.data),
    enabled: id !== 0,
    initialData: {
      id: 0,
      name: '',
      cluster: null,
      customer: null,
      project: null,
      tags: [],
    }
  });
};

export const createNamespace = () => {
  return useMutation({
    mutationFn: async (namespace: Namespace) => {
      return client.post('kubernetes/namespace',{
        ...namespace,
        cluster: namespace.cluster?.id,
        customer: namespace.customer?.id,
        project: namespace.project?.id,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['namespaces']});
      toast.success('Namespace aangemaakt');
    },
  });
};

export const updateNamespace = () => {
  return useMutation({
    mutationFn: async (namespace: Namespace) => {
      return client.put(`kubernetes/namespace/${namespace.id}`,{
        ...namespace,
        cluster: namespace.cluster?.id,
        customer: namespace.customer?.id,
        project: namespace.project?.id,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['namespaces']});
      toast.success('Namespace gewijzigd');
    },
  });
};

export const deleteNamespace = () => {
  return useMutation({
    mutationFn: async (namespace: Namespace) => {
      if (await confirm({
        title: 'Namespace verwijderen',
        text: 'Deze actie kan niet ongedaan gemaakt worden',
      })) {
        return client.delete(`kubernetes/namespace/${namespace.id}`);
      }

      return Promise.reject(new Error('Cancelled'));
    },
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['namespaces']});
      toast.success('Namespace verwijderd');
    },
  });
};

export const synchronizeNamespace = () => {
  return useMutation({
    mutationFn: async (namespace: Namespace) => {
      return client.put(`kubernetes/namespace/${namespace.id}/synchronize`);
    },
    onSuccess: () => {
      toast.success('Namespace gesynchroniseerd');
    },
  });
};
