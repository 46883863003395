import React from 'react';
import { User } from '../types';

export const UserContext = React.createContext({
  user: {
    id: 0,
    name: '',
    email: '',
    password: '',
    roles: [],
    internal: false,
    database: {
      age: 0,
      expires: 0,
      expired: false,
    },
    secure: false,
    customer: {
      id: 0,
      name: '',
      architectureUrl: null,
      amountOfUsers: 0,
      amountOfZones: 0,
      amountOfProjects: 0,
      amountOfResources: 0,
      users: [],
      zones: [],
      projects: [],
      services: [],
      resources: [],
      invoicedSums: {},
      subscriptionId: null,
      debtorNumber: null,
    },
  } as User,
  setUser: (user: User) => {},
});
