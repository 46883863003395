import { Formik } from 'formik';
import React from 'react';
import { Redirect, Zone } from '../../../types';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import * as Yup from 'yup';

interface Props {
  zone: Zone;
  redirect: Redirect;
  open: boolean;
  onClose: () => void;
  onConfirm: (redirect: Redirect) => void;
}

const Form = ({ zone, redirect, open, onClose, onConfirm }: Props) => {
  const onSubmit = (r: Redirect) => {
    r.name = r.name === '' ? zone.name : `${r.name}.${zone.name}`;

    onConfirm(r);
  };

  const getFullName = (domain: string, name?: string) => {
    if (typeof name === 'undefined' || name === '') {
      return domain;
    }

    return `${name}.${domain}`;
  };

  const hasRecord = (zone: Zone, type: string, value: string) => {
    if (getFullName(zone.name, value) === redirect.name) {
      return false;
    }

    return zone.records.filter((r) => r.name === getFullName(zone.name, value) && type === r.type).length > 0;
  };

  const schema = Yup.object().shape({
    name: Yup.string()
      .test(
        'domain-not-repeated',
        `${zone.name} wordt al automatisch aan het eind van de naam toegevoegd`,
        (value: any) => !value?.includes(zone.name)
      )
      .test(
        'no-conflicting-a-record',
        (context: any) => `${getFullName(zone.name, context.value)} bevat al een A record`,
        (value: any) => !hasRecord(zone, 'A', value)
      )
      .test(
        'no-conflicting-aaaa-record',
        (context: any) => `${getFullName(zone.name, context.value)} bevat al een AAAA record`,
        (value: any) => !hasRecord(zone, 'AAAA', value)
      )
      .test(
        'no-conflicting-cname-record',
        (context: any) => `${getFullName(zone.name, context.value)} bevat al een CNAME record`,
        (value: any) => !hasRecord(zone, 'CNAME', value)
      ),
    destination: Yup.string().required('Bestemming mag niet leeg zijn'),
  });

  return (
    <Dialog open={open} onClose={onClose}>
      <Formik
        initialValues={{
          ...redirect,
          name: redirect.name.replace(`.${zone.name}`, '').replace(zone.name, ''),
        }}
        validationSchema={schema}
        onSubmit={onSubmit}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isValid, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <DialogTitle>Nieuwe redirect aanmaken</DialogTitle>
            <DialogContent sx={{overflow: 'visible', minWidth: '400px'}}>
              <TextField
                name="name"
                label="Naam"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(errors.name && touched.name)}
                helperText={errors.name && touched.name && errors.name}
                margin="normal"
                disabled={isSubmitting}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Typography>.{zone.name}</Typography>
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
              <TextField
                name="destination"
                label="Bestemming"
                value={values.destination}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(errors.destination && touched.destination)}
                helperText={errors.destination && touched.destination && errors.destination}
                margin="normal"
                disabled={isSubmitting}
                fullWidth
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} color="primary" disabled={isSubmitting}>
                Annuleren
              </Button>
              <Button type="submit" color="primary" disabled={!isValid || isSubmitting}>
                {0 === values.id ? 'Aanmaken' : 'Wijzigen'}
                {isSubmitting && <CircularProgress size={20} style={{ marginLeft: '8px' }} />}
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};

export default Form;
