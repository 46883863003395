import {TableAction} from '../../types';
import {Button, IconButton, TableCell, Tooltip} from '@mui/material';
import React, {Fragment} from 'react';
import LoadingButton from '../Button/LoadingButton';

type Props<T> = {
  row: T;
  actions: Array<TableAction<T>>;
};

const DataTableActions = <T extends object>({row, actions}: Props<T>): JSX.Element => {
  return (
    <>
      {actions.length > 0 && (
        <TableCell align="right" sx={{whiteSpace: 'nowrap'}}>
          {actions.map(({condition, disabled, variant, icon, onClick, title, type = 'icon'}, index) => {
            if (typeof condition !== 'undefined' && !condition(row)) {
              return null;
            }

            if (type === 'loading_button') {
              return (
                <Fragment key={title}>
                  <LoadingButton
                    row={row}
                    title={title}
                    icon={icon}
                    onClick={onClick}
                    disabled={disabled}
                  />
                </Fragment>
              );
            }

            if (type === 'button') {
              return (
                <Fragment key={title}>
                  <Button
                    className="MuiButton--table"
                    sx={{px: 1, py: 1}}
                    color={variant === 'danger' ? 'error' : 'secondary'}
                    variant="text"
                    startIcon={icon}
                    onClick={(e) => {
                      e.stopPropagation();
                      onClick(row);
                    }}
                    disabled={typeof disabled !== 'undefined' && disabled(row)}
                  >
                    {title}
                  </Button>
                </Fragment>
              );
            }

            return (
              <Tooltip title={title} key={title}>
                <span>
                  <IconButton
                    centerRipple={false}
                    color="primary"
                    onClick={(e: any) => {
                      e.stopPropagation();
                      onClick(row);
                    }}
                    disabled={typeof disabled !== 'undefined' && disabled(row)}
                  >
                    {icon}
                  </IconButton>
                </span>
              </Tooltip>
            );
          })}
        </TableCell>
      )}
    </>
  );
};

export default DataTableActions;
