import React, {useContext} from 'react';
import {ColumnDefinitionType, SslCertificate, TableAction} from '../../types';
import DataTable from '../../elements/DataTable/DataTable';
import {Paper, Stack} from "@mui/material";
import {Check, Refresh, ZoomInOutlined} from "@mui/icons-material";
import {UserContext} from "../../provider/UserProvider";
import {hasRole} from "../../services/role";
import {useNavigate} from "react-router-dom";
import {getCertificates, updateCertificate} from '../../api/sslCertificate';

const Overview = () => {
  const navigate = useNavigate();
  const {user} = useContext(UserContext);
  const {data: certificates, isLoading, isFetching} = getCertificates();
  const mutation = updateCertificate();

  const expiryColumns: Array<ColumnDefinitionType<SslCertificate>> = [
    { key: 'commonName', title: 'Common name', type: 'string', sortable: true, filter: true },
    { key: 'expirationDate', title: 'Verloopdatum', type: 'date', sortable: true, filter: true },
    { key: 'expirationDate', title: 'Dagen tot verloop', type: 'custom', sortable: true, content: (certificate) => {
      return -1 * Math.round((+new Date() - +new Date(certificate.expirationDate)) / (1000 * 60 * 60 * 24));
    }},
    { key: 'domains', title: 'Aantal domeinen', type: 'count', sortable: true, filter: true },
    { key: 'requestedBy.name', title: 'Aangevraagd door', type: 'custom', sortable: true, content: (certificate) => {
      if (certificate.requestedBy !== null) {
        return certificate.requestedBy.name;
      }

      return '';
    }},
  ];

  const requestColumns: Array<ColumnDefinitionType<SslCertificate>> = [
    { key: 'commonName', title: 'Common name', type: 'string', sortable: true, filter: true },
    { key: 'orderDate', title: 'Aanvraagdatum', type: 'date', sortable: true },
    { key: 'orderDate', title: 'Dagen sinds aanvraag', type: 'custom', sortable: true, content: (certificate) => {
      return Math.round((+new Date() - +new Date(certificate.orderDate)) / (1000 * 60 * 60 * 24));
    }},
    { key: 'domains', title: 'Aantal domeinen', type: 'count', sortable: true, filter: true },
    { key: 'requestedBy.name', title: 'Aangevraagd door', type: 'custom', sortable: true, content: (certificate) => {
      if (certificate.requestedBy !== null) {
        return certificate.requestedBy.name;
      }

      return '';
    }},
  ];

  const expiryActions: Array<TableAction<SslCertificate>> = [
    {
      title: 'Voltooien',
      icon: <Check/>,
      variant: 'primary',
      onClick: (certificate) => mutation.mutate({...certificate, status: 'completed'}),
      type: 'button',
      condition: () => hasRole(user, ['ROLE_SSL_CERTIFICATE_UPDATE'])
    },
    { title: 'Vernieuwen', icon: <Refresh />, variant: 'primary', onClick: (certificate) => navigate(`/ssl/certificate/new?from=${certificate.id}`), type: 'button', condition: () => hasRole(user, ['ROLE_SSL_CERTIFICATE_GET']) },
    { title: 'Bekijken', icon: <ZoomInOutlined />, variant: 'primary', onClick: (certificate) => navigate(`/ssl/certificate/${certificate.id}`), type: 'button', condition: () => hasRole(user, ['ROLE_SSL_CERTIFICATE_GET']) },
  ];

  const requestActions: Array<TableAction<SslCertificate>> = [
    { title: 'Bekijken', icon: <ZoomInOutlined />, variant: 'primary', onClick: (certificate) => navigate(`/ssl/certificate/${certificate.id}`), type: 'button', condition: () => hasRole(user, ['ROLE_SSL_CERTIFICATE_GET']) },
  ];

  return (
    <Stack gap={4}>
      <Paper>
        <DataTable
          columns={requestColumns}
          rows={certificates.filter((certificate: SslCertificate) => certificate.expirationDate === null)}
          loading={isLoading || isFetching}
          actions={requestActions}
          toolbar={{
            title: 'Openstaande aanvragen',
          }}
          defaultRowsPerPage={10}
          pagination
          defaultOrder="desc"
          defaultOrderColumn="orderDate"
        />
      </Paper>
      <Paper>
        <DataTable
          columns={expiryColumns}
          rows={certificates.filter((certificate: SslCertificate) => certificate.expirationDate !== null && new Date(certificate.expirationDate) > new Date() && (certificate.status === 'active' || '*' === certificate.commonName.charAt(0)))}
          loading={isLoading || isFetching}
          actions={expiryActions}
          toolbar={{
            title: 'Certificaten die binnenkort verlopen',
          }}
          defaultRowsPerPage={10}
          pagination
          defaultOrder="asc"
          defaultOrderColumn="expirationDate"
        />
      </Paper>
    </Stack>

  );
};

export default Overview;
