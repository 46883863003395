export const getRecentYears = (count: number = 10): number[] => {
  const years = [];
  const currentYear = new Date().getFullYear()

  for (let i = 0; i < count; i++) {
    years.push(currentYear - i);
  }

  return years;
}

export const getRecentMonths = (count: number = 12, includeCurrent: boolean = true): number[] => {
  const months = [];
  const currentMonth = new Date().getMonth();

  for (let i = includeCurrent ? 0 : 1; i < (count + (includeCurrent ? 0 : 1)); i++) {
    months.push(currentMonth - i);
  }

  return months;
}

export const formatDateToMonthString = (date: Date): string => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');

  return `${year}-${month}`;
}

export const parseMonthNameToMonthInt = (monthName: string): number => {
  for (let i = 0; i < 12; i++) {
    let date = new Date(0, i);
    if (date.toLocaleString('nl-NL', { month: 'long' }) === monthName) {
      return date.getMonth();
    }
  }

  throw new Error('invalid month name for locale nl-NL');
}
