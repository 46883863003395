export const generatePassword = (
  length: number,
  lowercase: boolean = true,
  uppercase: boolean = true,
  numbers: boolean = true,
  symbols: boolean = true
) => {
  let password = '';
  const chars = (lowercase ? 'abcdefghijklmnopqrstuvwxyz' : '') +
    (uppercase ? 'ABCDEFGHIJKLMNOPQRSTUVWXYZ' : '') +
    (numbers ? '0123456789' : '') +
    (symbols ? '!@#$%^&*()_+~`|}{[]:;?><,./-=' : '');

  for (let i = 0; i < length; i++) {
    password += chars[Math.floor(Math.random() * chars.length)];
  }

  return password;
}
