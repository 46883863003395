import React, {ReactElement, useEffect, useState} from 'react';
import {
  Box,
  Checkbox,
  Chip, Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow
} from '@mui/material';
import {ColumnDefinitionType, DottedPath, TableAction} from '../../types';
import DataTableActions from '../DataTable/DataTableActions';
import DataTableHead from '../DataTable/DataTableHead';
import Fade from '@mui/material/Fade';
import LinearProgress from '@mui/material/LinearProgress';
import InfoRow from './InfoRow';
import moment from 'moment';
import DataTableToolbar from './DataTableToolbar';
import _ from 'lodash';
import {Warning} from "@mui/icons-material";

type TableProps<T> = {
  rows: Array<T>;
  columns: Array<ColumnDefinitionType<T>>;
  actions?: Array<TableAction<T>>;
  loading?: boolean;
  defaultOrder?: 'asc' | 'desc';
  defaultOrderColumn?: DottedPath<T>;
  defaultInfoText?: string;
  onRowClick?: (row: T) => void;
  checkboxSelection?: {
    identifier: keyof T;
    selected: Array<any>;
    setSelected: any;
  };
  toolbar?: {
    title: string;
    components?: Array<ReactElement>;
    actions?: Array<TableAction<undefined>>;
    backButton?: boolean;
    columns?: Array<ColumnDefinitionType<T>>;
    columnEditor?: boolean
  }
  summedValues?: Array<any>;
  pagination?: boolean;
  defaultRowsPerPage?: number;
};

const DataTable = <T extends object>({
  rows,
  columns,
  actions = [],
  loading = false,
  defaultOrder = 'asc',
  defaultOrderColumn,
  defaultInfoText = 'Geen data gevonden',
  onRowClick,
  checkboxSelection,
  toolbar,
  summedValues,
  pagination = false,
  defaultRowsPerPage = 25,
}: TableProps<T>): JSX.Element => {
  const [columnState, setColumnState] = useState<Array<ColumnDefinitionType<T>>>(columns);
  const [query, setQuery] = useState<string>('');
  const [infoText, setInfoText] = useState<string>(defaultInfoText);
  const [order, setOrder] = useState<'asc' | 'desc'>(defaultOrder);
  const [orderBy, setOrderBy] = useState<DottedPath<T>>(
    typeof defaultOrderColumn === 'undefined' ? columnState[0].key : defaultOrderColumn
  );
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(defaultRowsPerPage);

  const handleRowClick = (row: T) => {
    if (typeof onRowClick !== 'undefined') {
      onRowClick(row);
    } else {
      void 0;
    }
  };

  const summedTotalsRow = () => {
    if (typeof summedValues !== 'undefined' && summedValues.length !== 0) {
      return <TableRow className="summedTotalsRow">
        <TableCell>Totaal</TableCell>
        {summedValues.map((value: any, index:number) => (
          <TableCell key={index}>
            {value === null ? '' : '€ ' + value.toFixed(2).replace('.', ',')}
          </TableCell>
        ))}
      </TableRow>
    }
  }

  const cellContent = (column: ColumnDefinitionType<T>, row: T) => {
    const value = String(column.key)
      .split('.')
      .reduce((o: any, i: string) => o[i] || (column.type === 'integer' ? 0 : ''), row);

    if (column.type === 'custom' && column.content) {
      return column.content(row);
    }

    if (column.type === 'date') {
      if (typeof value === 'string') {
        return moment(value).format('DD-MM-y');
      }

      if (value) {
        return moment(value * 1000).format('DD-MM-y');
      }
    }

    if (column.type === 'time') {
      if (value) {
        return moment(value).format('HH:mm');
      }
    }

    if (column.type === 'dateTime') {
      if (value) {
        return moment(value * 1000).format('DD-MM-y HH:mm');
      }
    }

    if (column.type === 'price') {
      return '€ ' + (+value).toFixed(2).replace('.', ',');
    }

    if (column.type === 'boolean') {
      return value ? 'Ja' : 'Nee';
    }

    if (column.type === 'tags') {
      const tags = value.map((tag: string) => {
        if (tag === 'deprecated') {
          return (
            <Chip
              size="small"
              label={_.capitalize(tag)}
              className="MuiChip--warning"
              icon={<Warning color="warning" />}
            />
          )
        }

        return (
          <Chip size="small" label={_.capitalize(tag)}/>
        )
      })

      return <Stack spacing={1} direction="row">{tags}</Stack>
    }

    if (column.type === 'count') {
      return value.length;
    }

    return value;
  };

  const desc = (a: T, b: T, orderBy: DottedPath<T>) => {
    const first = String(orderBy)
      .split('.')
      .reduce((o: any, i: string) => o[i] || '', a);
    const second = String(orderBy)
      .split('.')
      .reduce((o: any, i: string) => o[i] || '', b);

    if (second < first) {
      return -1;
    }
    if (second > first) {
      return 1;
    }

    return 0;
  };

  const stableSort = (array: Array<T>, cmp: any) => {
    const stabilizedThis = array.map((el: any, index: number) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const getSorting = (order: 'asc' | 'desc', orderBy: DottedPath<T>) => {
    return order === 'desc' ? (a: any, b: any) => desc(a, b, orderBy) : (a: any, b: any) => -desc(a, b, orderBy);
  };

  const handleRequestSort = (key: DottedPath<T>) => {
    const isDesc = orderBy === key && order === 'desc';

    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(key);
  };

  const handleSelectedChange = (identifier: any) => {
    if (typeof checkboxSelection === 'undefined') {
      return;
    }

    const selectedIndex = checkboxSelection.selected.indexOf(identifier);
    let newSelected: any = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(checkboxSelection.selected, identifier);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(checkboxSelection.selected.slice(1));
    } else if (selectedIndex === checkboxSelection.selected.length - 1) {
      newSelected = newSelected.concat(checkboxSelection.selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        checkboxSelection.selected.slice(0, selectedIndex),
        checkboxSelection.selected.slice(selectedIndex + 1)
      );
    }

    checkboxSelection.setSelected(newSelected);
  };

  const applySearchQuery = (row: T) => {
    if (query === '') {
      return true;
    }

    return columns.some(column => {
      if (column.filter !== true || column.hidden) {
        return false;
      }

      const value = String(column.key)
        .split('.')
        .reduce((o: any, i: string) => o[i] || (column.type === 'integer' ? 0 : ''), row);

      return String(value).toLowerCase().includes(query.toLowerCase());
    });
  };

  const getRows = (ignorePagination: boolean = false) => {
    if (pagination && !ignorePagination) {
      return stableSort(rows, getSorting(order, orderBy))
        .filter(applySearchQuery)
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    }

    return stableSort(rows, getSorting(order, orderBy)).filter(applySearchQuery);
  };

  useEffect(() => {
    if (loading === true) {
      setInfoText('Geen data gevonden');
    }
  }, [loading]);

  useEffect(() => {
    setPage(0);
  }, [query]);

  useEffect(() => {
    if (typeof toolbar === 'undefined') {
      return;
    }

    const status = localStorage.getItem(toolbar.title);
    if (status === null) {
      setColumnState(columns);

      return;
    }

    const json = JSON.parse(status);
    setColumnState(columns.map((column: any) => {
      if (column.key in json) {
        column.hidden = json[column.key];
      }

      return column;
    }));
  }, [columns]);

  useEffect(() => {
    if (!toolbar) {
      return;
    }

    const cachedOrder = localStorage.getItem(`${toolbar.title}-order`);
    const cachedOrderBy = localStorage.getItem(`${toolbar.title}-orderBy`);
    if ((cachedOrder !== 'asc' && cachedOrder !== 'desc') || cachedOrderBy === null) {
      return;
    }

    setOrder(cachedOrder);
    setOrderBy(cachedOrderBy as DottedPath<T>);
  }, []);

  useEffect(() => {
    if (!toolbar) {
      return;
    }

    const cachedRowsPerPage = localStorage.getItem(`${toolbar.title}-rowsPerPage`);
    if (cachedRowsPerPage === null) {
      return;
    }

    setRowsPerPage(parseInt(cachedRowsPerPage));
  }, []);

  useEffect(() => {
    if (!toolbar) {
      return;
    }

    localStorage.setItem(`${toolbar.title}-order`, order);
    localStorage.setItem(`${toolbar.title}-orderBy`, String(orderBy));
  }, [order, orderBy]);

  useEffect(() => {
    if (!toolbar) {
      return;
    }

    localStorage.setItem(`${toolbar.title}-rowsPerPage`, String(rowsPerPage));
  }, [rowsPerPage]);

  return (
    <>
      {typeof toolbar !== 'undefined' && (
        <DataTableToolbar
          title={toolbar.title}
          actions={toolbar.actions}
          components={toolbar.components}
          columns={columnState}
          setColumns={setColumnState}
          query={query}
          setQuery={setQuery}
          columnEditor={toolbar.columnEditor}
        />
      )}
      <Fade in={loading} unmountOnExit>
        <LinearProgress />
      </Fade>
      <TableContainer>
        <Table size={actions.length > 0 ? 'small' : 'medium'}>
          <DataTableHead
            columns={columnState}
            rows={rows}
            actions={actions.length > 0}
            order={order}
            orderBy={orderBy}
            onSort={handleRequestSort}
            checkboxSelection={checkboxSelection}
          />
          <TableBody>
            {summedTotalsRow()}
            {rows.length === 0 && loading === false && (
              <InfoRow text={infoText} colSpan={columnState.length + (actions.length > 0 ? 1 : 0)} />
            )}
            {getRows().map((row, rowIndex) => (
              <TableRow
                hover={typeof onRowClick !== 'undefined'}
                tabIndex={-1}
                key={rowIndex}
                onClick={() => handleRowClick(row)}
                style={{cursor: typeof onRowClick === 'undefined' ? 'auto' : 'pointer'}}
              >
                {typeof checkboxSelection !== 'undefined' && (
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={checkboxSelection.selected.indexOf(row[checkboxSelection.identifier]) !== -1}
                      color="primary"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSelectedChange(row[checkboxSelection.identifier]);
                      }}
                    />
                  </TableCell>
                )}
                {columnState
                  .filter(column => (typeof column.condition === 'undefined' || column.condition()) && (!column.hidden))
                  .map((column, columnIndex) => (
                    <TableCell key={columnIndex} style={{wordBreak: column.wordBreak || 'normal'}}>
                      {cellContent(column, row)}
                    </TableCell>
                  ))}
                <DataTableActions row={row} actions={actions} />
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {pagination && (
        <>
          <TablePagination
            sx={{borderBottom: 'none', float: 'right', py: 0}}
            rowsPerPageOptions={[10, 15, 25, 50]}
            count={getRows(true).length}
            onPageChange={(e, page) => setPage(page)}
            page={page}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={(e) => {
              setRowsPerPage(parseInt(e.target.value, 10));
              setPage(0);
            }}
            labelRowsPerPage="Rijen per pagina"
            labelDisplayedRows={({from, to, count}) => `${from}-${to} van ${count}`}
          />
          <Box sx={{clear: 'both'}}/>
        </>
      )}
    </>
  );
};

export default DataTable;
