import React, { ReactElement, useEffect } from 'react';
import {ColumnDefinitionType, Log} from '../../types';
import client from '../../services/client';
import DataTable from '../../elements/DataTable/DataTable';
import DetailDialog from './DetailDialog';
import SearchBar from '../Dns/Zone/SearchBar';
import {UserContext} from "../../provider/UserProvider";

interface Props {
  domain?: string;
  search?: boolean;
}

const Overview = ({ domain = '', search = true }: Props) => {
  const {user} = React.useContext(UserContext);
  const [logs, setLogs] = React.useState<Array<Log>>([]);
  const [logToView, setLogToView] = React.useState<null | Log>(null);
  const [loading, setLoading] = React.useState<boolean>(false);

  const handleSearch = (query: string) => {
    if (!loading && query.length > 2) {
      setLoading(true);
      client
        .get(`dns/log?domain=${query}`)
        .then((response) => setLogs(response.data))
        .finally(() => setLoading(false));
    }
  };

  const components: Array<ReactElement> = [search ? <SearchBar handleSearch={handleSearch} /> : <span />];

  const columns: Array<ColumnDefinitionType<Log>> = [
    { key: 'title', title: 'Handeling', type: 'string', sortable: true },
    { key: 'scope', title: 'Scope', type: 'string', sortable: true },
    { key: 'user.name', title: 'Gebruiker', type: 'string', sortable: true },
    { key: 'date', title: 'Datum', type: 'date', sortable: true },
  ];

  if (user.roles.includes('ROLE_ADMIN')) {
    columns.splice(3, 0, { key: 'user.customer.name', title: 'Organisatie', type: 'string', sortable: true });
  }

  useEffect(() => {
    if ('' === domain && !search) {
      return;
    }

    setLoading(true);
    client
      .get(`dns/log?domain=${domain}`)
      .then((response) => setLogs(response.data))
      .finally(() => setLoading(false));
  }, [domain]);

  return (
    <>
      <DataTable
        columns={columns}
        rows={logs}
        loading={loading}
        onRowClick={setLogToView}
        defaultOrderColumn="date"
        defaultOrder="desc"
        toolbar={{
          title: 'Logs',
          components: components,
        }}
      />
      {null !== logToView && (
        <DetailDialog open={null !== logToView} log={logToView} onClose={() => setLogToView(null)} />
      )}
    </>
  );
};

export default Overview;
