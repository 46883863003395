import React from 'react';
import {Bar} from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface props {
  inputData: any
  autoSkip: boolean
  labelType: any
}

export const BarChart = ({inputData, autoSkip, labelType}: props) => {

  const labels: string[] = [];
  inputData.forEach((value: any) => {
    labels.push(value[labelType])
  });

  const options = {
    aspectRatio: 1.85,
    indexAxis: 'y' as const,
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        display: false
      },
      tooltip : {
        titleFont: {
          size: 16,
        },
        bodyFont: {
          size: 16,
        },
        callbacks: {
          label: function(context:any) {
            let label = context.dataset.label || '';

            if (label) {
              label += ': ';
            }

            if (context.parsed.x !== null) {
              label += new Intl.NumberFormat('nl-NL', {style: 'currency', currency: 'EUR'}).format(context.parsed.x);
            }
            return label;
          },
        }
      }
    },
    scales: {
      y: {
        ticks: {
          font: {
            size: 16,
          },
          autoSkip: autoSkip
        }
      },
      x: {
        title: {
          display: true,
          text: 'Kosten in \u20AC \u2800 \u2192',
          padding: 20,
          font: {
            size: 14,
          }
        },
        ticks: {
          callback: (value:any) => {
            return new Intl.NumberFormat('nl-NL', {style: 'currency', currency: 'EUR'}).format(value);
          }
        }
      },
    }
  };

  const totalCosts: number[] = [];
  inputData.forEach((value: any) => {
    totalCosts.push(value.totalCost.toFixed(2))
  });

const data = {
  labels,
  datasets: [{
    data: totalCosts,
    borderColor: 'rgb(242, 146, 141)',
    backgroundColor: 'rgb(242, 146, 141)',
  }]
}

  return (
      <Bar options={options} data={data} className="barChart"/>
  )
}
