import React from 'react';
import {ColumnDefinitionType, Invoice, TableAction} from '../../types';
import DataTable from '../../elements/DataTable/DataTable';
import {Alert, Chip, Paper} from "@mui/material";
import {ZoomInOutlined} from "@mui/icons-material";
import {getInvoices} from '../../api/invoice';

const CustomerOverview = () => {
  const {data: invoices, isLoading, isFetching} = getInvoices(null, true);

  const columns: Array<ColumnDefinitionType<Invoice>> = [
    { key: 'description', title: 'Beschrijving', type: 'string', sortable: true, filter: true },
    { key: 'totalAmount', title: 'Bedrag', type: 'price', sortable: true },
    { key: 'invoiceDate', title: 'Datum', type: 'date', sortable: true },
    { key: 'openAmount', title: 'Status', type: 'custom', sortable: true, content: (row) => {
      if (row.openAmount === '0.00') {
        return <Chip label="Betaald" className="MuiChip--success" />;
      }

      if (new Date(row.dueDate) < new Date()) {
        return <Chip label="Verlopen" className="MuiChip--danger" />;
      }

      return <Chip label="Open" className="MuiChip--warning" />;
    }},
  ];

  const actions: Array<TableAction<Invoice>> = [
    { title: 'Bekijken', icon: <ZoomInOutlined />, variant: 'primary', onClick: (invoice) => window.open(invoice.publicUrl, '_blank'), type: 'button' },
  ];

  return (
    <>
      {(invoices.filter((i: Invoice) => i.openAmount !== '0.00').length === 0 && invoices.length > 0) && (
        <Alert severity="success" sx={{mb: 3}}>Alle facturen zijn betaald.</Alert>
      )}
      <Paper>
        <DataTable
          columns={columns}
          rows={invoices}
          loading={isLoading || isFetching}
          actions={actions}
          toolbar={{
            title: 'Facturen',
          }}
          defaultRowsPerPage={10}
          pagination
          defaultOrderColumn="invoiceNumber"
          defaultOrder="desc"
        />
      </Paper>
    </>
  );
};

export default CustomerOverview;
