import {Button, Grid, Paper, Typography} from '@mui/material';
import React from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {joiResolver} from "@hookform/resolvers/joi";
import Joi from "joi";
import {FormProvider, useForm} from "react-hook-form";
import TextField from "../../elements/Form/TextField";
import Autocomplete from "../../elements/Form/Autocomplete";
import { LoadingButton } from '@mui/lab';
import {Project} from "../../types";
import {getCustomers} from "../../api/customer";
import validationSchema from "../../services/validationSchema";
import {createProject} from "../../api/project";
import Select from "../../elements/Form/Select";

const FormPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const {data: customers} = getCustomers();
  const mutation = createProject();

  const types = [
    {value: 'sulu', label: 'Sulu'},
    {value: 'sylius', label: 'Sylius'},
    {value: 'akeneo', label: 'Akeneo'},
    {value: 'symfony', label: 'Symfony'},
    {value: 'shopware', label: 'Shopware'},
    {value: 'shopware_frontend', label: 'Shopware frontend'},
    {value: 'frontend', label: 'Frontend'},
    {value: 'monorepo', label: 'Monorepo'},
  ];

  const schema = validationSchema.object({
    domain: Joi.string().required().regex(/^[a-z0-9-.]+$/).messages({
      'string.pattern.base': 'De waarde moet een geldige domeinnaam zijn zonder http:// of https:// ervoor.',
    }),
    slug: Joi.string().required().custom((value, helpers) => {
      const domain = helpers.state.ancestors[0].domain.replaceAll('.', '-');
      const dynamicRegexPattern = new RegExp(`^(?!-)(?:[a-z0-9-]*-)?${domain}(?:-[a-z0-9-]*[^-])?$`);

      if (!dynamicRegexPattern.test(value)) {
        return helpers.error('regex.project.slug', { domain: domain });
      }

      return value;
    }).messages({
      'regex.project.slug': 'De waarde voldoet niet aan het vereiste formaat. Het moet \'{#domain}\' bevatten en kan andere kleine letters en streepjes bevatten.',
    }),
    customer: Joi.object().required(),
  }).unknown();

  const form = useForm<Project>({
    defaultValues: {domain: '', slug: '', customer: null},
    resolver: joiResolver(schema),
    mode: 'onBlur',
  });

  const onSubmit = (values: Project) => {
    return mutation.mutateAsync(values).then(() => {
      navigate('/project');
    }).catch((error) => {
      Object.keys(error.response.data.errors || {}).forEach(key => {
        form.setError(key as keyof Project, {type: 'custom', message: error.response.data.errors[key]})
      })
    });
  }

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} sm={12} md={12} lg={6}>
        <Paper sx={{p: 6, maxWidth: '600px', margin: '0 auto'}}>
          <Typography variant="h6" fontWeight="bold">Project aanmaken</Typography>
          <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <TextField name="domain" label="Domein" />
              <TextField name="slug" label="Repository naam" />
              <Select name="type" label="Type" options={types} />
              <Autocomplete
                options={customers}
                name="customer"
                label="Klant"
                getOptionLabel={(option) => option.name}
              />
              <LoadingButton sx={{mt: 2}} type="submit" color="primary" variant="contained" loading={form.formState.isSubmitting}>
                {id === undefined ? 'Aanmaken' : 'Wijzigen'}
              </LoadingButton>
              <Button onClick={() => navigate(-1)} sx={{mt: 2, ml: 1}} color="secondary" disabled={form.formState.isSubmitting}>Annuleren</Button>
            </form>
          </FormProvider>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default FormPage;
