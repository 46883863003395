import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import auditNl from '../translations/nl/audit.json';
import kubernetesNamespaceNl from '../translations/nl/kubernetes_namespace.json';
import roleNl from '../translations/nl/role.json';
import databaseAccessNl from '../translations/nl/database_access.json';

const resources = {
  nl: {
    audit: auditNl,
    kubernetes_namespace: kubernetesNamespaceNl,
    role: roleNl,
    database_access: databaseAccessNl,
  },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: localStorage.getItem('locale') || 'nl',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
