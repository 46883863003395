import React, {useContext, useEffect} from 'react';
import {ColumnDefinitionType, DatabaseServer, TableAction} from '../../types';
import client from '../../services/client';
import DataTable from '../../elements/DataTable/DataTable';
import {Paper} from "@mui/material";
import {AddBox, DeleteOutlined, SyncOutlined, EuroSymbol} from "@mui/icons-material";
import {toast} from "react-toastify";
import DeleteConfirmation from "../../elements/DataTable/DeleteConfirmation";
import Form from "./Form";
import SetCostForm from "./SetCostForm";
import {UserContext} from "../../provider/UserProvider";
import {hasRole} from "../../services/role";

const Overview = () => {
  const {user} = useContext(UserContext);
  const [databases, setDatabases] = React.useState<Array<DatabaseServer>>([]);
  const [databaseServerToEdit, setDatabaseServerToEdit] = React.useState<DatabaseServer | null>(null);
  const [databaseServerToDelete, setDatabaseServerToDelete] = React.useState<DatabaseServer | null>(null);
  const [databaseServerToSetCostFor, setDatabaseServerToSetCostFor] = React.useState<DatabaseServer | null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const emptyDatabaseServer = {id: 0, name: ''} as DatabaseServer;

  const columns: Array<ColumnDefinitionType<DatabaseServer>> = [
    { key: 'name', title: 'Naam', type: 'string', sortable: true },
    { key: 'databases.length', title: 'Aantal databases', type: 'integer', sortable: true },
  ];

  const toolbarActions: Array<TableAction<undefined>> = [
    {
      title: 'Database server aanmaken',
      icon: <AddBox />,
      variant: 'primary',
      onClick: () => setDatabaseServerToEdit(emptyDatabaseServer),
      type: 'button' ,
      condition: () => hasRole(user, ['ROLE_DATABASE_SERVER_CREATE']),
    },
  ];

  const handleDelete = () => {
    if (null === databaseServerToDelete) {
      return;
    }

    client
      .delete(`database/server/${databaseServerToDelete.id}`)
      .then(() => toast.success('Database verwijderd'))
      .finally(() => setDatabaseServerToDelete(null));
  }

  const handleSynchronize = (server: DatabaseServer) => {
    client
        .post(`database/server/${server.id}/synchronize`)
        .then(() => toast.success('Database server gesynchroniseerd'))
  }

  useEffect(() => {
    if (null !== databaseServerToEdit || null !== databaseServerToDelete) {
      return;
    }

    setLoading(true);
    client
      .get('database/server')
      .then((response) => setDatabases(response.data))
      .finally(() => setLoading(false));
  }, [databaseServerToEdit, databaseServerToDelete]);

  const actions: Array<TableAction<DatabaseServer>> = [
    { title: 'Kosten doorgeven', icon: <EuroSymbol />, variant: 'primary', onClick: setDatabaseServerToSetCostFor, type: 'button', condition: () => hasRole(user, ['ROLE_DATABASE_SERVER_UPDATE']) },
    { title: 'Synchroniseren', icon: <SyncOutlined />, variant: 'primary', onClick: handleSynchronize, type: 'button', condition: () => hasRole(user, ['ROLE_DATABASE_SERVER_CREATE']) },
    { title: 'Verwijderen', icon: <DeleteOutlined />, variant: 'primary', onClick: setDatabaseServerToDelete, type: 'button', condition: () => hasRole(user, ['ROLE_DATABASE_SERVER_DELETE']) },
  ];

  return (
    <Paper>
      <DataTable
        columns={columns}
        rows={databases}
        loading={loading}
        actions={actions}
        toolbar={{
          title: 'Database servers',
          actions: toolbarActions,
        }}
      />
      <DeleteConfirmation
        title="Database server verwijderen?"
        text="Deze actie kan niet ongedaan gemaakt worden."
        open={databaseServerToDelete !== null}
        onClose={() => setDatabaseServerToDelete(null)}
        onConfirm={handleDelete}
      />
      <Form
        databaseServer={databaseServerToEdit}
        open={databaseServerToEdit !== null}
        onClose={() => setDatabaseServerToEdit(null)}
      />
      <SetCostForm
        databaseServer={databaseServerToSetCostFor}
        open={databaseServerToSetCostFor !== null}
        onClose={() => setDatabaseServerToSetCostFor(null)}
      />
    </Paper>
  );
};

export default Overview;
