import {Button, Grid, Paper, Typography} from '@mui/material';
import React, {useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {joiResolver} from '@hookform/resolvers/joi';
import Joi from 'joi';
import {FormProvider, SubmitHandler, useForm} from 'react-hook-form';
import TextField from '../../elements/Form/TextField';
import {LoadingButton} from '@mui/lab';
import {MessageQueueServer} from '../../types';
import validationSchema from '../../services/validationSchema';
import {createMessageQueueServer, getMessageQueueServer, updateMessageQueueServer} from "../../api/messageQueueServer";
import Select from "../../elements/Form/Select";
import Autocomplete from "../../elements/Form/Autocomplete";

const types = [
  {value: 'rabbitmq', label: 'RabbitMQ'},
];

const tags: {[key: string]: string} = {
  'acceptance': 'Acceptance',
  'production': 'Production',
  'deprecated': 'Deprecated',
};

const Form = () => {
  const navigate = useNavigate();
  const {id} = useParams();
  const {data} = id === undefined ? {data: undefined} : getMessageQueueServer(+(id));
  const mutation = id === undefined ? createMessageQueueServer() : updateMessageQueueServer();

  const schema = validationSchema.object({
    name: Joi.string().required(),
    type: Joi.string().required()
      .when('$new', {
        is: true,
        then: Joi.required(),
        otherwise: Joi.optional(),
      }),
    host: Joi.string().required()
      .when('$new', {
        is: true,
        then: Joi.required(),
        otherwise: Joi.optional(),
      }),
    admin: Joi.string().required(),
    username: Joi.string().required()
      .when('$new', {
        is: true,
        then: Joi.required(),
        otherwise: Joi.optional(),
      }),
    password: Joi.string()
      .when('$new', {
        is: true,
        then: Joi.required(),
        otherwise: Joi.optional(),
      }),
    tags: Joi.array(),
  }).unknown();

  const form = useForm<MessageQueueServer>({
    defaultValues: data || {id: 0, name: '', type: 'rabbitmq', host: '', username: '', password: '', admin: '', tags: []},
    resolver: joiResolver(schema),
    context: {new: id === undefined},
    mode: 'onBlur',
  });

  const onSubmit: SubmitHandler<MessageQueueServer> = (data) => {
    return mutation.mutateAsync(data).then(() => {
      navigate('/mq/server');
    }).catch((error) => {
      Object.keys(error.response.data.errors || {}).forEach((key) => {
        form.setError(key as keyof MessageQueueServer, {type: 'custom', message: error.response.data.errors[key]});
      });
    });
  };

  useEffect(() => {
    if (id !== undefined) {
      form.reset(data as MessageQueueServer);
    }
  }, [data]);

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} sm={12} md={12} lg={6}>
        <Paper sx={{p: 6, maxWidth: '600px', margin: '0 auto'}}>
          <Typography variant="h6" fontWeight="bold" sx={{mb: 4}}>Server {id === undefined ? 'aanmaken' : 'wijzigen'}</Typography>
          <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <TextField name="name" label="Naam" />
              <Select name="type" label="Type" options={types} disabled={id !== undefined} />
              <TextField name="host" label="Host" disabled={id !== undefined} />
              <TextField name="admin" label="Admin URL" />
              {id === undefined && (
                <>
                  <TextField name="username" label="Gebruikersnaam" />
                  <TextField name="password" label="Wachtwoord" type="password" />
                </>
              )}
              <Autocomplete
                multiple
                name="tags"
                label="Tags"
                options={Object.keys(tags)}
                getOptionLabel={(tag) => tags[tag]}
              />
              <LoadingButton sx={{mt: 2}} type="submit" color="primary" variant="contained" loading={form.formState.isSubmitting}>
                {id === undefined ? 'Aanmaken' : 'Wijzigen'}
              </LoadingButton>
              <Button onClick={() => navigate('/mq/server')} sx={{mt: 2, ml: 1}} color="secondary" disabled={form.formState.isSubmitting}>Annuleren</Button>
            </form>
          </FormProvider>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Form;
