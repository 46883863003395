import {useMutation, useQuery} from "react-query";
import client from "../services/client";
import queryClient from "../services/queryClient";
import {toast} from "react-toastify";

export const getCustomerResources = () => {
  return useQuery({
    queryKey: ['resources'],
    queryFn: () => client.get('resource/customer').then(response => response.data),
    initialData: [],
  });
};

export const linkResourceToCustomer = () => {
  return useMutation({
    mutationFn: async (data: {customer: number, resource: number, resourceType: string}) => {
      return client.put('resource/customer',data);
    },
    onSuccess: () => {
      queryClient.resetQueries();
      toast.success('Klant gekoppeld aan resource');
    },
  });
};

export const linkResourceToProject = () => {
  return useMutation({
    mutationFn: async (data: {project: number, resource: number, resourceType: string}) => {
      return client.put('resource/project',data);
    },
    onSuccess: () => {
      queryClient.resetQueries();
      toast.success('Project gekoppeld aan resource');
    },
  });
};

export const unlinkResourceFromProject = () => {
  return useMutation({
    mutationFn: async (data: {project: null, resource: number, resourceType: string}) => {
      return client.put('resource/project',data);
    },
    onSuccess: () => {
      queryClient.resetQueries();
      toast.success('Resource ontkoppeld van project');
    },
  });
};
