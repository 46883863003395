import {useQuery} from 'react-query';
import client from '../services/client';

export const getCloudimageTokens = () => {
  return useQuery({
    queryKey: ['cloudimageTokens', 'resources'],
    queryFn: () => client.get('cloudimage/token').then(response => response.data),
    initialData: [],
  });
};
