import React, {useEffect} from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import {Autocomplete as MuiAutocomplete, AutocompleteProps, TextField} from '@mui/material';
import {getIn} from '../../services/form';
import {Subscription} from "../../types";
import {getCustomerSubscriptions} from '../../api/customer';

type Props = Omit<AutocompleteProps<any, any, any, any>, 'name'|'renderInput'|'options'> & {
  name: string;
  label: string;
}

const SubscriptionSelect = ({name, label, ...props}: Props) => {
  const {control, watch, formState: {errors, isSubmitting}, setValue} = useFormContext();
  const {data: subscriptions, isFetching, isLoading} = getCustomerSubscriptions(watch('customer')?.id || 0);

  useEffect(() => {
    setValue(name, null);
    if (subscriptions.length > 0) {
      setValue(name, subscriptions.find((subscription: Subscription) => subscription.id === watch('customer')?.subscriptionId) || null, {shouldDirty: true, shouldValidate: true, shouldTouch: true});
    }
  }, [subscriptions, watch('user')])

  return (
    <Controller
      name={name}
      control={control}
      render={({field: {onChange, ...field}}) => (
        <MuiAutocomplete
          {...field}
          {...props}
          value={watch(name)}
          options={subscriptions}
          loading={isFetching || isLoading}
          disabled={isSubmitting || props.disabled}
          onChange={(event, value) => onChange(value)}
          isOptionEqualToValue={(option, value) => {
            if (typeof option === 'object') {
              return option.id === value.id
            }

            return option === value;
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              error={Boolean(getIn(errors, name))}
              helperText={getIn(errors, name)?.message}
            />
          )}
          getOptionLabel={(option: Subscription) => option.name}
        />
      )}
    />
  );
};

export default SubscriptionSelect;
