import React from "react";
import {Box, TextField} from "@mui/material";
import {EuroSymbol} from "@mui/icons-material";


interface Props {
  handleChange: (e: React.ChangeEvent<any>) => void;
  handleBlur: (e: React.ChangeEvent<any>) => void;
  monthError: boolean;
  costError: boolean;
  costHelperText: any;
  monthHelperText: any;
  monthValue: string|undefined;
  costValue: number|undefined;
}

const MonthCostRow = ({handleChange, handleBlur, monthError, costError, costHelperText, monthHelperText, monthValue, costValue} : Props) => {
  return <div style={{ display: 'flex' }}>
    <TextField
      name="month"
      label="Maand (Y-m)"
      value={monthValue}
      onChange={handleChange}
      onBlur={handleBlur}
      error={monthError}
      helperText={monthHelperText}
      margin="normal"
      sx={{ marginRight: 4 }}
    />
    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
      <EuroSymbol sx={{ color: 'action.active', mr: 1, mb: 1.5, fontSize: 20}} />
      <TextField
        name="cost"
        label="Kosten"
        value={costValue}
        onChange={handleChange}
        onBlur={handleBlur}
        error={costError}
        helperText={costHelperText}
        margin="normal"
        variant="standard"
      />
    </Box>
  </div>;
}

export default MonthCostRow;
