import React from 'react';

interface Props {
  color?: string;
}

const Dollar = ({color = '#000'}: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 102.095 102.096">
      <path id="dollar" fill={color} d="M51.047,45.971A51.048,51.048,0,1,0,102.1,97.019,51.048,51.048,0,0,0,51.047,45.971M56,120.327v5.882H46.483v-5.861c-10.211-1.295-15.4-7.046-16.46-14.327H41.2c1.084,3.783,4.017,6.395,10.851,6.395,5.417,0,7.55-1.965,7.55-4.588,0-2.888-1.416-4.886-11.327-7.028-13.35-2.875-17.052-7.819-17.052-14.46,0-6.311,4.235-12.549,14.528-13.993V67.38h9.512v4.946c11.193,1.431,14.53,7.883,14.932,12.538H58.833c-.72-1.87-1.958-4.739-8.972-4.739-5.512,0-7.224,2.33-7.224,4.678,0,2.609,1.91,4.134,10.122,5.9,15.726,3.375,18.785,8.448,18.785,15.379,0,6.712-4.445,12.851-15.542,14.243" transform="translate(0 -45.971)"/>
    </svg>
  )
};

export default Dollar;
