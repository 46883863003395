import React, {useContext} from 'react';
import {ColumnDefinitionType, MessageQueueServer, TableAction} from '../../types';
import DataTable from '../../elements/DataTable/DataTable';
import {Paper} from "@mui/material";
import {
  AddBox,
  DeleteOutlined, EditOutlined, OpenInNew, SyncOutlined
} from "@mui/icons-material";
import {UserContext} from "../../provider/UserProvider";
import {hasRole} from "../../services/role";
import { useNavigate } from 'react-router-dom';
import {
  deleteMessageQueueServer,
  getMessageQueueServers,
  synchronizeMessageQueueServer
} from "../../api/messageQueueServer";

const Overview = () => {
  const navigate = useNavigate();
  const {user} = useContext(UserContext);
  const {isLoading, data, isFetching} = getMessageQueueServers();
  const deleteMutation = deleteMessageQueueServer();
  const synchronizeMutation = synchronizeMessageQueueServer();

  const columns: Array<ColumnDefinitionType<MessageQueueServer>> = [
    { key: 'name', title: 'Naam', type: 'string', sortable: true },
    { key: 'users.length', title: 'Aantal gebruikers', type: 'integer', sortable: true },
    { key: 'tags', title: 'Tags', type: 'tags', sortable: true },
  ];

  const toolbarActions: Array<TableAction<undefined>> = [
    {
      title: 'Server aanmaken',
      icon: <AddBox />,
      variant: 'primary',
      onClick: () => navigate('/mq/server/new'),
      type: 'button' ,
      condition: () => hasRole(user, ['ROLE_MESSAGE_QUEUE_SERVER_CREATE']),
    },
  ];

  const actions: Array<TableAction<MessageQueueServer>> = [
    { title: 'Admin', icon: <OpenInNew />, variant: 'primary', onClick: (server) => window.open(server.admin, '_blank'), type: 'button', condition: () => hasRole(user, ['ROLE_MESSAGE_QUEUE_SERVER_CREATE']) },
    { title: 'Synchroniseren', icon: <SyncOutlined />, variant: 'primary', onClick: synchronizeMutation.mutateAsync, type: 'loading_button', condition: () => hasRole(user, ['ROLE_MESSAGE_QUEUE_SERVER_GET']) },
    { title: 'Bewerken', icon: <EditOutlined />, variant: 'primary', onClick: (server) => navigate(`/mq/server/${server.id}`), type: 'button', condition: () => hasRole(user, ['ROLE_MESSAGE_QUEUE_SERVER_DELETE']) },
    { title: 'Verwijderen', icon: <DeleteOutlined />, variant: 'primary', onClick: deleteMutation.mutate, type: 'button', condition: () => hasRole(user, ['ROLE_MESSAGE_QUEUE_SERVER_DELETE']) },
  ];

  return (
    <Paper>
      <DataTable
        columns={columns}
        rows={data}
        loading={isLoading || isFetching}
        actions={actions}
        toolbar={{
          title: 'Message queue servers',
          actions: toolbarActions,
        }}
      />
    </Paper>
  );
};

export default Overview;
