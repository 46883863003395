import {Grid, Paper, Stack, Table, TableCell, TableRow, Typography} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import {ArrowBack} from '@mui/icons-material';
import React from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {getCustomerData} from '../../api/customer';
import DataTable from '../../elements/DataTable/DataTable';
import {ColumnDefinitionType} from '../../types';

const Detail = () => {
  const navigate = useNavigate();
  const {id} = useParams();
  const {data: customer, isLoading, isFetching} = getCustomerData(+(id || 0));

  const styles = {height: '60px', px: 0, width: '50%'}

  return (
    <Grid container justifyContent="center" gap={4}>
      <Grid item xs={12} sm={12} md={12} lg={5}>
        <Paper sx={{p: 6}}>
          <Stack direction="row" justifyContent="start" alignItems="center" sx={{mb: 4}}>
            <Tooltip title="Terug">
              <IconButton onClick={() => navigate(-1)} sx={{mr: 1}}>
                <ArrowBack />
              </IconButton>
            </Tooltip>
            <Typography variant="h6" fontWeight="bold">{customer.name}</Typography>
          </Stack>
          <Typography variant="subtitle1" fontWeight="bold">Contactgegevens</Typography>
          <Table>
            <TableRow>
              <TableCell sx={styles}>E-mail</TableCell>
              <TableCell sx={styles}>{customer.email}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{...styles, borderBottom: 'none'}}>Telefoonnummer</TableCell>
              <TableCell sx={{...styles, borderBottom: 'none'}}>{customer.phone}</TableCell>
            </TableRow>
          </Table>
          <Typography variant="subtitle1" fontWeight="bold" sx={{pt: 2}}>Adresgegevens</Typography>
          <Table>
            <TableRow>
              <TableCell sx={styles}>Adres</TableCell>
              <TableCell sx={styles}>{customer.address}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={styles}>Postcode</TableCell>
              <TableCell sx={styles}>{customer.postalCode}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={styles}>Stad</TableCell>
              <TableCell sx={styles}>{customer.city}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{...styles, borderBottom: 'none'}}>Land</TableCell>
              <TableCell sx={{...styles, borderBottom: 'none'}}>{customer.country}</TableCell>
            </TableRow>
          </Table>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={6}>
        <Paper>
          <DataTable
            columns={[
              { key: 'name', title: 'Naam', type: 'string', sortable: true },
              { key: 'email', title: 'E-mail', type: 'string', sortable: true },
              { key: 'phone', title: 'Telefoonnummer', type: 'string', sortable: true },
              { key: 'function', title: 'Functie', type: 'string', sortable: true },
            ] as Array<ColumnDefinitionType<{name: string, email: string, phone: string, function: string}>>}
            rows={customer.contacts}
            loading={isLoading || isFetching}
            toolbar={{
              title: `${customer.name} contactpersonen`,
            }}
          />
        </Paper>
        <Paper sx={{mt: 4}}>
          <DataTable
            columns={[
              { key: 'name', title: 'Naam', type: 'string', sortable: true },
              { key: 'email', title: 'E-mail', type: 'string', sortable: true },
              { key: 'telephone', title: 'Telefoon', type: 'string', sortable: true },
              { key: 'function', title: 'Functie', type: 'string', sortable: true },
            ] as Array<ColumnDefinitionType<{name: string, email: string, function: string}>>}
            rows={customer.employees}
            loading={isLoading || isFetching}
            toolbar={{
              title: 'Friday contactpersonen',
            }}
          />
        </Paper>
      </Grid>
    </Grid>
  )
};

export default Detail;
