import React, { useEffect } from 'react';
import { TableAction, TableIcon, Zone } from '../../../types';
import client from '../../../services/client';
import { Paper, Tab, Tabs } from '@mui/material';
import { GetApp, Link, Lock, Warning } from '@mui/icons-material';
import DataTableToolbar from '../../../elements/DataTable/DataTableToolbar';
import { toast } from 'react-toastify';
import RedirectOverview from '../Redirect/Overview';
import RecordOverview from '../Record/Overview';
import LogOverview from '../../Log/Overview';
import fileDownload from 'js-file-download';
import DnssecDetail from '../Dnssec/Detail';
import { green, orange } from '@mui/material/colors';
import { UserContext } from '../../../provider/UserProvider';
import {useParams} from "react-router-dom";

const initialZone: Zone = {
  id: 0,
  name: '',
  externalId: 0,
  records: [],
  redirects: [],
  customers: [],
  cryptokey: null,
  correctNameservers: true,
};

const Detail = () => {
  const { id } = useParams();
  const {user} = React.useContext(UserContext);
  const [zone, setZone] = React.useState<Zone>(initialZone);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [value, setValue] = React.useState<number>(0);

  const a11yProps = (index: number) => {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const handleExport = () => {
    client
      .get(`dns/zone/${zone.id}/export?name=${zone.name}`, { responseType: 'blob' })
      .then((response) => fileDownload(response.data, `${zone.name}.csv`));
  };

  const toggleDnssec = () => {
    return client.put(`dns/zone/${zone.id}/dnssec`).then(() => {
      toast.success(`DNSSEC ${null === zone.cryptokey ? 'ingeschakeld' : 'ingeschakeld'}`);
      refresh();
    });
  };

  const refresh = () => {
    setLoading(true);
    client
      .get(`dns/zone/${id}`)
      .then((response) => setZone(response.data))
      .finally(() => setLoading(false));
  };

  useEffect(refresh, []);

  const toolbarActions: Array<TableAction<undefined>> = [
    {
      title: 'DNSSEC inschakelen',
      icon: <Lock />,
      variant: 'primary',
      onClick: toggleDnssec,
      condition: () => null === zone.cryptokey && user.roles.includes('ROLE_ADMIN'),
      type: 'button'
    },
    {
      title: 'Exporteren',
      icon: <GetApp />,
      variant: 'primary',
      onClick: handleExport,
      type: 'button',
    },
  ];

  const icons: Array<TableIcon> = [
    {
      title: 'DNSSEC is ingeschakeld',
      element: <Lock style={{ color: green[500] }} />,
      condition: () => null !== zone.cryptokey,
    },
    {
      title: 'Gekoppeld aan ' + zone.customers.map((customer) => customer.name).join(', '),
      element: <Link style={{ color: green[500] }} />,
      condition: () => user.roles.includes('ROLE_ADMIN') && zone.customers.length > 0,
    },
    {
      title: 'DNS staat niet naar Friday',
      element: <Warning style={{ color: orange[500] }} />,
      condition: () => !zone.correctNameservers,
    },
  ];

  return (
    <>
      <Paper>
        <DataTableToolbar title={zone.name} actions={toolbarActions} icons={icons} backButton />
        <Tabs value={value} onChange={handleChange} indicatorColor="primary">
          <Tab label="Records" {...a11yProps(0)} />
          <Tab label="Redirects" {...a11yProps(1)} />
          <Tab label="Logs" {...a11yProps(2)} />
          {user.roles.includes('ROLE_ADMIN') && null !== zone.cryptokey && (
            <Tab label="DNSSEC" {...a11yProps(3)} />
          )}
        </Tabs>
      </Paper>

      <Paper style={{ marginTop: '24px' }}>
        <div hidden={value !== 0} id={`full-width-tabpanel-0`}>
          <RecordOverview zone={zone} loading={loading} refresh={refresh} />
        </div>
        <div hidden={value !== 1} id={`full-width-tabpanel-1`}>
          <RedirectOverview zone={zone} loading={loading} refresh={refresh} />
        </div>
        <div hidden={value !== 2} id={`full-width-tabpanel-2`}>
          <LogOverview domain={zone.name} search={false} />
        </div>
        {user.roles.includes('ROLE_ADMIN') && (
          <>
            {null !== zone.cryptokey && (
              <div hidden={value !== 3} id={`full-width-tabpanel-3`}>
                <DnssecDetail cryptokey={zone.cryptokey} />
              </div>
            )}
          </>
        )}
      </Paper>
    </>
  );
};

export default Detail;
