import React, { useEffect } from 'react';
import {ColumnDefinitionType, DatabaseAccess, TableAction} from '../../types';
import client from '../../services/client';
import DataTable from '../../elements/DataTable/DataTable';
import {Box, Paper} from "@mui/material";
import {
  UpdateOutlined,
  VpnKeyOffOutlined,
} from "@mui/icons-material";
import {toast} from "react-toastify";
import Countdown, {zeroPad} from "react-countdown";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import ExtendAccessForm from "../Database/ExtendAccessForm";

const Overview = () => {
  const [rows, setRows] = React.useState<Array<DatabaseAccess>>([]);
  const [accessToExtend, setAccessToExtend] = React.useState<DatabaseAccess | null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);

  const columns: Array<ColumnDefinitionType<DatabaseAccess>> = [
    { key: 'database.name', title: 'Database', type: 'string', sortable: true },
    { key: 'user.name', title: 'Gebruiker', type: 'string', sortable: true },
    { key: 'timeRemaining', title: 'Toegang', type: 'custom', sortable: true, content: (database) => {
        if (database.timeRemaining <= 0) {
          return 'Geen toegang';
        }

        return <Countdown date={Date.now() + database.timeRemaining * 1000} renderer={({hours, minutes, seconds, completed}) => {
          if (completed) {
            return 'Geen toegang';
          }

          return (
            <Box sx={{display: 'flex', alignItems: 'center'}}>
              <span>{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</span>
              {(hours < 1 && minutes < 15) && (
                <Tooltip title="Toegang verlengen">
                  <IconButton sx={{ml: 1}} onClick={() => setAccessToExtend(database)} color="secondary">
                    <UpdateOutlined/>
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          )
        }} />
      }},
  ];

  const loadDatabases = () => {
    setLoading(true);
    client
      .get('database/access')
      .then((response) => setRows(response.data))
      .finally(() => setLoading(false));
  }

  const revokeAccess = (access: DatabaseAccess) => {
    client
      .delete(`database/${access.database.id}/access`, {
        data: {},
      })
      .then(() => toast.success('Toegang stopgezet'))
      .finally(() => loadDatabases());
  }

  useEffect(() => {
    if (null !== accessToExtend) {
      return;
    }

    loadDatabases();
  }, [accessToExtend]);

  const actions: Array<TableAction<DatabaseAccess>> = [
    { title: 'Toegang stoppen', icon: <VpnKeyOffOutlined />, variant: 'primary', onClick: revokeAccess, type: 'button', condition: (database) => database.timeRemaining > 0 }
  ];

  return (
    <Paper>
      <DataTable
        columns={columns}
        rows={rows}
        loading={loading}
        actions={actions}
        toolbar={{
          title: 'Database toegang',
        }}
      />
      <ExtendAccessForm
        database={accessToExtend?.database ?? null}
        open={accessToExtend !== null}
        onClose={() => setAccessToExtend(null)}
      />
    </Paper>
  );
};

export default Overview;
