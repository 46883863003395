import React, {ReactElement, useEffect} from 'react';
import {ColumnDefinitionType, Customer, CustomerResource, TableAction} from '../../types';
import client from '../../services/client';
import {Paper, Box, Typography, Grid, Card, CardContent, Autocomplete, TextField} from '@mui/material';
import DataTable from '../../elements/DataTable/DataTable';
import {VisibilityOutlined} from '@mui/icons-material';
import {useNavigate, useParams} from "react-router-dom";
import Breadcrumb from "../App/Breadcrumb";
import Kubernetes from "../../elements/Icon/Kubernetes";
import Dollar from "../../elements/Icon/Dollar";
import {green, red} from "@mui/material/colors";
import {getRecentYears, getRecentMonths, formatDateToMonthString} from "../../services/date";

const ResourceOverview = () => {
  const {id} = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [customer, setCustomer] = React.useState<Customer | null>(null);

  const [year, setYear] = React.useState<number>(new Date().getFullYear());
  const [month, setMonth] = React.useState<number>(getRecentMonths(1, false)[0]);

  if (month > getRecentMonths(1)[0]) {
    setYear(year - 1);
  }

  useEffect(() => {
    setLoading(true);

    client
      .get(`/customer/${id}`)
      .then((response) => setCustomer(response.data))
      .finally(() => setLoading(false));
  }, []);

  const columns: Array<ColumnDefinitionType<CustomerResource>> = [
    { key: 'name', title: 'Naam', type: 'string', sortable: true },
    { key: 'type', title: 'Type', type: 'string', sortable: true },
    { key: `cost.${formatDateToMonthString(new Date(year, month))}`, title: 'Kosten', type: 'custom', sortable: true, content: (row) => {
      const key = formatDateToMonthString(new Date(year, month));

      if (row.cost === null || row.cost[key] === undefined) {
        return '';
      }

      return '€ ' + row.cost[key].toFixed(2).replace('.', ',');
    }},
  ];

  const actions: Array<TableAction<CustomerResource>> = [
    { title: 'Bekijken', icon: <VisibilityOutlined />, variant: 'primary', onClick: (resource) => {
      if (resource.type === 'Kubernetes namespace') {
        navigate(`/kubernetes/namespace/${resource.id}`);
      }
    }, type: 'button' },
  ];

  if (null === customer) {
    return <></>;
  }

  const items = [
    { title: 'Klanten', url: '/customer' },
    { title: customer.name, url: null },
  ]

  const selectComponents: Array<ReactElement> = [(
    <Autocomplete
      disableClearable={true}
      sx={{ml: 2, minWidth: '200px'}}
      options={getRecentYears()}
      getOptionLabel={(option: any) => option.toString()}
      value={year ?? undefined}
      onChange={(event, value) => setYear(value)}
      renderInput={(params) => (
        <TextField{...params} label="Jaar" variant="outlined" margin="none" style={{ backgroundColor: 'white' }} />
      )}
    />
  ), (
    <Autocomplete
      disableClearable={true}
      sx={{ml: 2, minWidth: '200px'}}
      options={getRecentMonths()}
      getOptionLabel={(option: number) => new Date(0, option).toLocaleString('nl-NL', { month: 'long' })}
      value={month ?? undefined}
      onChange={(event, value) => {setMonth(value)}}
      filterSelectedOptions
      renderInput={(params) => (
        <TextField{...params} label="Maand" variant="outlined" margin="none" style={{ backgroundColor: 'white' }} />
      )}
    />
  )];


  // @ts-ignore
  return (
    <>
      <div style={{ display: 'flex', justifyContent: "space-between" }}>
        <Box sx={{mb: 5}}>
          <Typography variant="h5" sx={{fontWeight: 'bold'}}>{customer.name}</Typography>
          <Breadcrumb items={items} />
        </Box>
        <div style={{ display: 'flex' }}>
          {selectComponents}
        </div>
      </div>
      <Grid container spacing={4} sx={{mb: 4}}>
        <Grid item xs={4}>
          <Card>
            <CardContent sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', p: 3}}>
              <Box>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                  Aantal resources
                </Typography>
                <Typography variant="h5" component="div">
                  {customer.resources.length}
                </Typography>
              </Box>
              <Box sx={{visibility: 'hidden'}}>
                <Kubernetes/>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card>
            <CardContent sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', p: 3}}>
              <Box>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                  Kosten
                </Typography>
                <Typography variant="h5" component="div">
                  € {
                  customer.resources
                    .map((c) => {
                      let key = formatDateToMonthString(new Date(year, month));

                      if (c.cost === null) {
                        return 0;
                      }

                      return c.cost[key] ?? 0;
                    })
                    .reduce((a, b) => a + b, 0)
                    .toFixed(2)
                    .replace('.', ',')
                }
                </Typography>
              </Box>
              <Box>
                <Dollar color={red[200]}/>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card>
            <CardContent sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', p: 3}}>
              <Box>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                  Gefactureerd
                </Typography>
                <Typography variant="h5" component="div">
                  € {(() => {
                    const invoiced = customer?.invoicedSums[formatDateToMonthString(new Date(year, month))];

                    return invoiced === undefined ? 0 : invoiced
                      .toFixed(2)
                      .replace('.', ',');
                })()}
                </Typography>
              </Box>
              <Box>
                <Dollar color={green[200]}/>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Paper>
        <DataTable
          columns={columns}
          rows={customer.resources}
          actions={actions}
          loading={loading}
          toolbar={{
            title: 'Resources',
          }}
        />
      </Paper>
    </>
  );
};

export default ResourceOverview;
