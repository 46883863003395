import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import {Customer} from '../../types';
import {getCustomers} from "../../api/customer";
import {linkResourceToCustomer} from "../../api/resource";
import {FormProvider, useForm} from "react-hook-form";
import {joiResolver} from "@hookform/resolvers/joi";
import validationSchema from "../../services/validationSchema";
import Joi from "joi";
import Autocomplete from "../../elements/Form/Autocomplete";
import {LoadingButton} from "@mui/lab";

interface Props {
  open: boolean;
  onClose: () => void;
  resourceId: number;
  resourceType: string;
}

const CustomerLinkForm = ({ open, onClose, resourceId, resourceType}: Props) => {
  const {data: customers} = getCustomers(open);
  const mutation = linkResourceToCustomer();

  const schema = validationSchema.object({
    customer: Joi.object().required(),
  }).unknown();

  const form = useForm({
    defaultValues: {customer: null},
    resolver: joiResolver(schema),
    mode: 'onBlur',
  });

  const onSubmit = (values: {customer: Customer|null}) => {
    return mutation.mutateAsync({
      customer: values.customer?.id || 0,
      resource: resourceId,
      resourceType: resourceType,
    }).then(() => onClose()).catch((error) => {
      Object.keys(error.response.data.errors || {}).forEach(key => {
        form.setError(key as keyof {customer: Customer|null}, {type: 'custom', message: error.response.data.errors[key]})
      })
    });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <DialogTitle>Resource koppelen aan klant</DialogTitle>
          <DialogContent sx={{overflow: 'visible', minWidth: '400px'}}>
            <Autocomplete
              options={customers}
              name="customer"
              label="Klant"
              getOptionLabel={(option: Customer) => option.name}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color="secondary" disabled={form.formState.isSubmitting}>Annuleren</Button>
            <LoadingButton type="submit" color="primary" variant="contained" loading={form.formState.isSubmitting}>
              Koppelen
            </LoadingButton>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};

export default CustomerLinkForm;
