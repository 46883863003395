import {Button, Grid, Paper, Typography} from '@mui/material';
import React, {useEffect} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {joiResolver} from "@hookform/resolvers/joi";
import Joi from "joi";
import {FormProvider, useForm} from "react-hook-form";
import TextField from '../../elements/Form/TextField';
import { LoadingButton } from '@mui/lab';
import validationSchema from "../../services/validationSchema";
import {Incident} from "../../types";
import {createIncident, getIncident, updateIncident} from "../../api/incident";
import Select from "../../elements/Form/Select";
import UserSelect from "../../elements/Form/UserSelect";

const levels = [
  {value: '1', label: 'SEV-1 - Systeemuitval / Volledige uitval van de dienstverlening'},
  {value: '2', label: 'SEV-2 - Grote impact / Ernstige vermindering van de dienstverlening'},
  {value: '3', label: 'SEV-3 - Kleine impact / Het grootste deel van het systeem functioneert'},
  {value: '4', label: 'SEV-4 - Informatief / Weinig tot geen impact op de dienstverlening'},
  {value: '5', label: 'SEV-5 - Cosmetisch / Geen impact op de dienstverlening'},
];

const priorities = [
  {value: '1', label: 'P1 - Hoogste prioriteit. Direct alles laten vallen om het op te lossen'},
  {value: '2', label: 'P2 - Hoge prioriteit. Maak af waar je mee bezig bent en los het dan op'},
  {value: '3', label: 'P3 - Gemiddelde prioriteit. Kan de volgende werkdag opgepakt worden'},
  {value: '4', label: 'P4 - Lage prioriteit. Kan volgende week opgepakt worden'},
];

const statuses = [
  {value: 'open', label: 'Open'},
  {value: 'in_review', label: 'In review'},
  {value: 'closed', label: 'Closed'},
];

const Form = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const {data: incident} = getIncident(+(id || 0));
  const mutation = id === undefined ? createIncident() : updateIncident();

  const schema = validationSchema.object({
    name: Joi.string().required(),
  }).unknown();

  const form = useForm<Incident>({
    defaultValues: incident,
    resolver: joiResolver(schema),
    mode: 'onBlur',
  });

  const onSubmit = (values: Incident) => {
    return mutation.mutateAsync(values).then(() => {
        navigate(-1);
      }).catch((error) => {
      Object.keys(error.response.data.errors || {}).forEach(key => {
        form.setError(key as keyof Incident, {type: 'custom', message: error.response.data.errors[key]})
      })
    });
  }

  useEffect(() => {
    if (id !== undefined) {
      form.reset(incident);
    }
  }, [incident])

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} sm={12} md={12} lg={6}>
        <Paper sx={{p: 6, maxWidth: '600px', margin: '0 auto'}}>
          <Typography variant="h6" fontWeight="bold">Incident {id === undefined ? 'aanmaken' : 'wijzigen'}</Typography>
          <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <TextField name="name" label="Naam" />
              <Select name="level" label="Severity level" options={levels} />
              <Select name="priority" label="Prioriteit" options={priorities} />
              {id !== undefined && (
                <Select name="status" label="Status" options={statuses} />
              )}
              <TextField name="description" label="Beschrijving" multiline rows={3} />
              <UserSelect name="commander" label="Commander" />
              <UserSelect name="engineers" label="Engineers" multiple />
              <LoadingButton sx={{mt: 2}} type="submit" color="primary" variant="contained" loading={form.formState.isSubmitting}>
                {id === undefined ? 'Aanmaken' : 'Wijzigen'}
              </LoadingButton>
              <Button onClick={() => navigate(-1)} sx={{mt: 2, ml: 1}} color="secondary" disabled={form.formState.isSubmitting}>Annuleren</Button>
            </form>
          </FormProvider>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default Form;
