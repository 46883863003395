import {Formik, FormikHelpers} from 'formik';
import React from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import * as Yup from 'yup';
import {DatabaseServer, DatabaseCost} from '../../types';
import client from '../../services/client';
import {toast} from "react-toastify";
import MonthCostRow from "./MonthCostRow";

const schema = Yup.object().shape({
  month: Yup.string().nullable(true).required('Vul een maand in (Y-m)'),
  cost: Yup.string().nullable(true).required('Vul de kosten voor deze maand in'),
});

interface Props {
  databaseServer: DatabaseServer|null;
  open: boolean;
  onClose: () => void;
}

const SetCostForm = ({ databaseServer, open, onClose }: Props) => {

  console.log(databaseServer);
  if (null === databaseServer) {
    return <></>;
  }

  let databaseCost: DatabaseCost = {
    databaseServer: databaseServer,
    month: undefined,
    cost: undefined,
  };

  const onSubmit = (databaseCost: DatabaseCost, actions: FormikHelpers<DatabaseCost>) => {
    console.log({
      serverId: databaseCost.databaseServer.id,
      month: databaseCost.month,
      cost: databaseCost.cost,
    });

    client
      .post('database/server/set-cost', {
        serverId: databaseCost.databaseServer.id,
        month: databaseCost.month,
        cost: databaseCost.cost,
      })
      .then(() => {
        toast.success('Kosten doorgegeven');
        onClose();
      })
      .finally(() => actions.setSubmitting(false));
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <Formik
        initialValues={databaseCost}
        validationSchema={schema}
        onSubmit={onSubmit}
      >
        {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isValid,
            isSubmitting,
          }) => (
          <form onSubmit={handleSubmit}>
            <DialogTitle>Geef kosten door voor server</DialogTitle>
            <DialogContent sx={{minWidth: '400px'}}>
              <MonthCostRow
                handleChange={handleChange}
                handleBlur={handleBlur}
                monthHelperText={errors.month && touched.month}
                costHelperText={errors.cost && touched.cost}
                monthError={Boolean(touched.month && errors.month)}
                costError={Boolean(touched.cost && errors.cost)}
                monthValue={values.month}
                costValue={values.cost}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} color="primary">
                Annuleren
              </Button>
              <Button type="submit" color="primary" disabled={!isValid || isSubmitting}>
                Doorgeven
                {isSubmitting && <CircularProgress size={20} style={{ marginLeft: '8px' }} />}
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};

export default SetCostForm;
