import React from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import {MenuItem, TextField as MuiTextField} from '@mui/material';
import {TextFieldProps} from '@mui/material/TextField/TextField';
import {getIn} from '../../services/form';

type Props = Omit<TextFieldProps, 'name'> & {
  name: string;
  options: Array<{value: string|number, label: string}>;
}

const Select = ({name, options, ...props}: Props) => {
  const {control, formState: {errors, isSubmitting}} = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({field}) => (
        <MuiTextField
          select
          error={Boolean(getIn(errors, name))}
          helperText={getIn(errors, name)?.message}
          disabled={isSubmitting || props.disabled}
          {...field}
          {...props}
        >
          {options.map(option => (
            <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
          ))}
        </MuiTextField>
      )}
    />
  );
};

export default Select;
