import React, {useContext} from 'react';
import {ColumnDefinitionType, Incident, TableAction} from '../../types';
import DataTable from '../../elements/DataTable/DataTable';
import {Chip, Paper} from "@mui/material";
import {AddBox, DeleteOutlined, EditOutlined} from "@mui/icons-material";
import {UserContext} from "../../provider/UserProvider";
import {hasRole} from "../../services/role";
import {useNavigate} from "react-router-dom";
import {deleteIncident, getIncidents} from "../../api/incident";
import {SlackIcon} from "../../elements/Icon/Icons";

const Overview = () => {
  const navigate = useNavigate();
  const {user} = useContext(UserContext);
  const {data: incidents, isLoading, isFetching} = getIncidents();
  const mutation = deleteIncident();

  const columns: Array<ColumnDefinitionType<Incident>> = [
    { key: 'name', title: 'Titel', type: 'string', sortable: true, filter: true },
    { key: 'status', title: 'Status', type: 'custom', sortable: true, content: (row) => {
        switch (row.status) {
          case 'open':
            return <Chip label="Open" className="MuiChip--danger" />;
          case 'in_review':
            return <Chip label="In review" className="MuiChip--warning" />;
          case 'closed':
            return <Chip label="Closed" className="MuiChip--success" />;
          default:
            return 'Onbekend';
        }
    }},
    { key: 'level', title: 'Level', type: 'custom', sortable: true, content: (row) => `SEV-${row.level}`},
    { key: 'priority', title: 'Prioriteit', type: 'integer', sortable: true },
    { key: 'createdAt', title: 'Datum', type: 'date', sortable: true },
  ];

  const toolbarActions: Array<TableAction<undefined>> = [
    {
      title: 'Incident aanmaken',
      icon: <AddBox />,
      variant: 'primary',
      onClick: () => navigate('/incident/new'),
      type: 'button' ,
      condition: () => hasRole(user, ['ROLE_INCIDENT_CREATE']),
    },
  ];

  const actions: Array<TableAction<Incident>> = [
    {
      title: 'Slack kanaal',
      icon: <SlackIcon />,
      variant: 'primary',
      onClick: (incident) => window.open(`https://friday-digitalagency.slack.com/archives/${incident.channel}`, '_blank'),
      type: 'button',
      condition: () => hasRole(user, ['ROLE_INCIDENT_GET'])
    },
    {
      title: 'Bewerken',
      icon: <EditOutlined />,
      variant: 'primary',
      onClick: (incident) => navigate(`/incident/${incident.id}`),
      type: 'button',
      condition: () => hasRole(user, ['ROLE_INCIDENT_UPDATE'])
    },
    {
      title: 'Verwijderen',
      icon: <DeleteOutlined />,
      variant: 'primary',
      onClick: mutation.mutate,
      type: 'button',
      condition: () => hasRole(user, ['ROLE_INCIDENT_DELETE'])
    },
  ];

  return (
    <Paper>
      <DataTable
        columns={columns}
        rows={incidents}
        loading={isLoading || isFetching}
        actions={actions}
        toolbar={{
          title: 'Incidenten',
          actions: toolbarActions,
        }}
        defaultRowsPerPage={10}
        pagination
      />
    </Paper>
  );
};

export default Overview;
