import React, {useEffect} from 'react';
import {Stack, Typography} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {ElasticDeployment} from "../../../types";
import {getDeploymentPassword} from "../../../api/elasticDeployment";

interface Props {
  deployment: ElasticDeployment;
}

const Password = ({deployment}: Props) => {
  const [visible, setVisible] = React.useState(false);
  const [enabled, setEnabled] = React.useState(true);
  const {data: password} = getDeploymentPassword(deployment.id, visible && enabled);

  useEffect(() => {
    if ('' !== password) {
      setEnabled(false);
    }
  }, [password]);

  return (
    <Stack direction="row" justifyContent="start" alignItems="center">
      <Typography>{visible ? password : '●●●●●●●●●●'}</Typography>
      <IconButton onClick={() => setVisible(!visible)} size="small" sx={{ml: 0.5}}>
        {visible ? (<VisibilityOff />) : (<Visibility />)}
      </IconButton>
    </Stack>
  );
}

export default Password;
