import React, {ReactElement, Fragment, Dispatch, SetStateAction} from 'react';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import {ArrowBack} from '@mui/icons-material';
import {useNavigate} from 'react-router-dom';
import {ColumnDefinitionType, TableAction} from '../../types';
import {Box, Button, Hidden, ListItemIcon, ListItemText, Menu, MenuItem, TextField} from '@mui/material';
import ColumnEditor from './ColumnEditor';

interface Props<T> {
  title: any;
  components?: Array<ReactElement>;
  icons?: Array<any>;
  actions?: Array<TableAction<undefined>>;
  backButton?: boolean;
  columns?: Array<ColumnDefinitionType<T>>;
  setColumns?: Dispatch<SetStateAction<ColumnDefinitionType<T>[]>>;
  query?: string;
  setQuery?: Dispatch<SetStateAction<string>>;
  columnEditor?: boolean
}

const DataTableToolbar = <T extends object>({title, components = [], icons = [], actions = [], backButton = false, columns = [], setColumns, columnEditor = false, query = '', setQuery}: Props<T>): JSX.Element => {
  const navigate = useNavigate();
  const [anchorEls, setAnchorEls] = React.useState<any>({});

  const redirectBack = () => {
    navigate(-1);
  };

  const handleChange = (e: any) => {
    if (setQuery) {
      setQuery(e.target.value);
    }
  };

  return (
    <>
      <Toolbar sx={{py: 3}}>
        {backButton && (
          <Tooltip title="Terug">
            <IconButton onClick={redirectBack} sx={{mr: 1}}>
              <ArrowBack />
            </IconButton>
          </Tooltip>
        )}
        <Box sx={{flex: '0 0 auto', mr: 1}}>
          <Typography variant="h6" sx={{fontWeight: 'bold'}}>{title}</Typography>
        </Box>
        {icons.map((icon, i) => {
          if (typeof icon.condition !== 'undefined' && true !== icon.condition()) {
            return <></>;
          }

          return (
            <Tooltip title={icon.title} key={i} sx={{mr: 1}}>
              {icon.element}
            </Tooltip>
          );
        })}
        {columns.some(column => !column.hidden && column.filter) && (
          <Box>
            <TextField
              placeholder="Zoeken . . ."
              variant="outlined"
              size="small"
              margin="none"
              onChange={handleChange}
              sx={{mx: 2, minWidth: '200px'}}
              fullWidth
            />
          </Box>
        )}
        <Hidden xsDown>
          {components.map((component, i) => (
            <Fragment key={i}>{component}</Fragment>
          ))}
        </Hidden>
        <Box sx={{flex: '1 1 100%'}}/>
        <Box sx={{flex: '0 0 auto'}}>
          {(columnEditor && typeof setColumns !== 'undefined') && (
            <ColumnEditor title={title} columns={columns} setColumns={setColumns}/>
          )}
          {actions.map(({condition, icon, onClick = () => void 0, title: actionTitle, variant, type = 'icon', custom}) => {
            // @ts-ignore
            if (typeof condition !== 'undefined' && condition() !== true) {
              return null;
            }

            if (typeof custom !== 'undefined') {
              return custom();
            }

            if (type === 'button') {
              return (
                <Button
                  sx={{ml: 1, py: 1.5}}
                  key={actionTitle}
                  color="primary"
                  variant="contained"
                  startIcon={icon}
                  onClick={(e) => {
                    e.stopPropagation();
                    onClick();
                  }}
                >
                  {actionTitle}
                </Button>
              );
            }

            if (type === 'menu_button') {
              return (
                <Button
                  sx={{ml: 1, py: 1.5}}
                  key={actionTitle}
                  color="primary"
                  variant="contained"
                  startIcon={icon}
                  onClick={(e) => {
                    e.stopPropagation();
                    setAnchorEls({...anchorEls, [actionTitle]: e.currentTarget});
                  }}
                >
                  {actionTitle}
                </Button>
              );
            }

            return (
              <Tooltip title={actionTitle} key={actionTitle}>
                <IconButton
                  color="primary"
                  centerRipple={false}
                  onClick={(e) => {
                    e.stopPropagation();
                    onClick();
                  }}
                >
                  {icon}
                </IconButton>
              </Tooltip>
            );
          })}
        </Box>
      </Toolbar>
      <Hidden smUp>
        {components.map((component, i) => (
          <Fragment key={i}>{component}</Fragment>
        ))}
      </Hidden>
      {actions?.filter(({type}) => type === 'menu_button').map(({title: actionTitle, subActions = []}) => (
        <Menu
          key={actionTitle}
          anchorEl={anchorEls[actionTitle]}
          open={Boolean(anchorEls[actionTitle])}
          onClose={() => setAnchorEls({...anchorEls, [actionTitle]: null})}
        >
          {subActions.map(({title: subActionTitle, icon, onClick, condition}) => {
            if (typeof condition !== 'undefined' && condition() !== true) {
              return null;
            }

            return (
              <MenuItem key={subActionTitle} onClick={() => {
                setAnchorEls({...anchorEls, [actionTitle]: null});
                onClick();
              }}>
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText>{subActionTitle}</ListItemText>
              </MenuItem>
            );
          })}
        </Menu>
      ))}
    </>
  );
};

export default DataTableToolbar;
