import React, {useContext} from 'react';
import {ColumnDefinitionType, Database, TableAction} from '../../types';
import DataTable from '../../elements/DataTable/DataTable';
import {Alert, Box, Paper} from "@mui/material";
import {
  AddBox,
  DeleteOutlined, InfoOutlined,
  UpdateOutlined,
  VpnKeyOffOutlined,
  VpnKeyOutlined
} from "@mui/icons-material";
import Countdown, {zeroPad} from "react-countdown";
import ExtendAccessForm from "./ExtendAccessForm";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import {UserContext} from "../../provider/UserProvider";
import {hasRole} from "../../services/role";
import CustomerLinkButton from "../CustomerResource/CustomerLinkButton";
import ProjectLinkButton from "../ProjectResource/ProjectLinkButton";
import {deleteDatabase, getDatabases, grantDatabaseAccess, revokeDatabaseAccess} from '../../api/database';
import {useNavigate} from "react-router-dom";

const Overview = () => {
  const navigate = useNavigate();
  const {user} = useContext(UserContext);
  const {data: databases, isLoading, isFetching} = getDatabases();
  const [databaseToExtend, setDatabaseToExtend] = React.useState<Database | null>(null);
  const grantMutation = grantDatabaseAccess();
  const revokeMutation = revokeDatabaseAccess();
  const deleteMutation = deleteDatabase();

  const toolbarActions: Array<TableAction<undefined>> = [
    {
      title: 'Database aanmaken',
      icon: <AddBox />,
      variant: 'primary',
      onClick: () => navigate('/database/new'),
      type: 'button' ,
      condition: () => hasRole(user, ['ROLE_DATABASE_CREATE']),
    },
  ];

  const columns: Array<ColumnDefinitionType<Database>> = [
    { key: 'name', title: 'Naam', type: 'string', sortable: true, filter: true },
    { key: 'server.name', title: 'Server', type: 'string', sortable: true, filter: true },
    { key: 'customer.name', title: 'Klant', type: 'custom', sortable: true, filter: true, content: (row) => {
      if (row.customer !== null) {
        return row.customer.name;
      }

      return <CustomerLinkButton resourceId={row.id} resourceType="Database"/>
    }},
    { key: 'project.domain', title: 'Project', type: 'custom', sortable: true, content: (row) => {
      if (row.project !== null) {
        return row.project.domain;
      }

      return <ProjectLinkButton resourceId={row.id} resourceType="Database" customer={row.customer || undefined}/>
    }},
    { key: 'timeRemaining', title: 'Toegang', type: 'custom', sortable: true, content: (database) => {
        if (database.timeRemaining <= 0) {
          return 'Geen toegang';
        }

        return <Countdown date={Date.now() + database.timeRemaining * 1000} renderer={({hours, minutes, seconds, completed}) => {
          if (completed) {
            return 'Geen toegang';
          }

          return (
            <Box sx={{display: 'flex', alignItems: 'center'}}>
              <span>{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</span>
              {(hours < 1 && minutes < 15) && (
                <Tooltip title="Toegang verlengen">
                  <IconButton sx={{ml: 1}} onClick={() => setDatabaseToExtend(database)} color="secondary">
                    <UpdateOutlined/>
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          )
        }} />
      }},
  ];

  const actions: Array<TableAction<Database>> = [
    { title: 'Toegang stoppen', icon: <VpnKeyOffOutlined />, variant: 'primary', onClick: revokeMutation.mutate, type: 'button', condition: (database) => database.timeRemaining > 0 && hasRole(user, ['ROLE_DATABASE_ACCESS_REVOKE'])},
    { title: 'Toegang krijgen', icon: <VpnKeyOutlined />, variant: 'primary', onClick: grantMutation.mutate, type: 'button', condition: (database) => database.timeRemaining <= 0 && hasRole(user, ['ROLE_DATABASE_ACCESS_GRANT']), disabled: () => user.database.expired},
    { title: 'Details', icon: <InfoOutlined />, variant: 'primary', onClick: (database: Database) => navigate(`/database/${database.id}`), type: 'button', condition: () => hasRole(user, ['ROLE_DATABASE_GET']) },
    { title: 'Verwijderen', icon: <DeleteOutlined />, variant: 'danger', onClick: deleteMutation.mutate, type: 'button', condition: () => hasRole(user, ['ROLE_DATABASE_DELETE']) },
  ];

  return (
    <>
      {user.database.expired && (
        <Alert severity="error" sx={{mb: 3}}>
          Database wachtwoord is verlopen.
          Klik <Box onClick={() => navigate('/profile/database')} sx={{display: 'inline', cursor: 'pointer'}}><u>hier</u></Box> om deze bij te werken.
        </Alert>
      )}
      <Paper>
        <DataTable
          columns={columns}
          rows={databases}
          loading={isFetching || isLoading}
          actions={actions}
          pagination
          toolbar={{
            title: 'Databases',
            actions: toolbarActions,
          }}
        />
        <ExtendAccessForm
          database={databaseToExtend}
          open={databaseToExtend !== null}
          onClose={() => setDatabaseToExtend(null)}
        />
      </Paper>
    </>
  );
};

export default Overview;
