import React from 'react';
import {CloudimageToken, ColumnDefinitionType} from '../../types';
import DataTable from '../../elements/DataTable/DataTable';
import {Paper} from "@mui/material";
import CustomerLinkButton from "../CustomerResource/CustomerLinkButton";
import {getCloudimageTokens} from '../../api/cloudimageToken';

const Overview = () => {
  const {data: tokens, isLoading, isFetching} = getCloudimageTokens();

  const columns: Array<ColumnDefinitionType<CloudimageToken>> = [
    { key: 'name', title: 'Naam', type: 'string', sortable: true, filter: true },
    { key: 'token', title: 'Token', type: 'string', sortable: true, filter: true },
    { key: 'customer.name', title: 'Klant', type: 'custom', sortable: true, content: (row) => {
        if (row.customer !== null) {
          return row.customer.name;
        }

        return <CustomerLinkButton resourceId={row.id} resourceType="Cloudimage token"/>
      }},
  ];

  return (
    <Paper>
      <DataTable
        columns={columns}
        rows={tokens}
        loading={isLoading || isFetching}
        actions={[]}
        toolbar={{
          title: 'Cloudimage tokens',
        }}
        defaultRowsPerPage={10}
        pagination
      />
    </Paper>
  );
};

export default Overview;
