import {Box, Grid, Paper, Stack, Table, TableCell, TableRow, Typography} from '@mui/material';
import React from 'react';
import {getDatabase} from "../../api/database";
import {useNavigate, useParams} from "react-router-dom";
import ProjectLinkButton from "../ProjectResource/ProjectLinkButton";
import CustomerLinkButton from "../CustomerResource/CustomerLinkButton";
import Countdown, {zeroPad} from "react-countdown";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import {ArrowBack} from "@mui/icons-material";

const Detail = () => {
  const navigate = useNavigate();
  const {id} = useParams();
  const {data: database} = getDatabase(+(id || 0));

  const redirectBack = () => {
    navigate(-1);
  };

  const renderAccess = () => {
    if (database.timeRemaining <= 0) {
      return 'Geen toegang';
    }

    return <Countdown date={Date.now() + database.timeRemaining * 1000} renderer={({hours, minutes, seconds, completed}) => {
      if (completed) {
        return 'Geen toegang';
      }

      return (
        <Box sx={{display: 'flex', alignItems: 'center'}}>
          <span>{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</span>
        </Box>
      )
    }} />
  }

  const styles = {height: '60px', px: 0, width: '50%'}

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} sm={12} md={12} lg={6}>
        <Paper sx={{p: 6, maxWidth: '600px', margin: '0 auto'}}>
          <Stack direction="row" justifyContent="start" alignItems="center" sx={{mb: 4}}>
            <Tooltip title="Terug">
              <IconButton onClick={redirectBack} sx={{mr: 1}}>
                <ArrowBack />
              </IconButton>
            </Tooltip>
            <Typography variant="h6" fontWeight="bold">Database {database.name}</Typography>
          </Stack>
          <Typography variant="subtitle1" fontWeight="bold">Algemene informatie</Typography>
          <Table>
            <TableRow>
              <TableCell sx={styles}>Naam</TableCell>
              <TableCell sx={styles}>{database.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={styles}>Server</TableCell>
              <TableCell sx={styles}>{database.server.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={styles}>Project</TableCell>
              <TableCell sx={styles}>
                {database.project === null ? (
                  <ProjectLinkButton resourceId={database.id} resourceType="Database"/>
                ) : database.project.domain}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{...styles, borderBottom: 'none'}}>Klant</TableCell>
              <TableCell sx={{...styles, borderBottom: 'none'}}>
                {database.customer === null ? (
                  <CustomerLinkButton resourceId={database.id} resourceType="Database"/>
                ) : database.customer.name}
              </TableCell>
            </TableRow>
          </Table>

          <Typography variant="subtitle1" fontWeight="bold" sx={{mt: 2}}>Verbinden</Typography>
          <Table>
            <TableRow>
              <TableCell sx={styles}>Private IP</TableCell>
              <TableCell sx={styles}>{database.server.privateIp}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={styles}>SSH tunnel username</TableCell>
              <TableCell sx={styles}>{database.server.sshTunnelUsername || 'n.v.t.'}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={styles}>SSH tunnel IP</TableCell>
              <TableCell sx={styles}>{database.server.sshTunnelIp || 'n.v.t.'}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{...styles, borderBottom: 'none'}}>Toegang</TableCell>
              <TableCell sx={{...styles, borderBottom: 'none'}}>{renderAccess()}</TableCell>
            </TableRow>
          </Table>
        </Paper>
      </Grid>
    </Grid>
  )
};

export default Detail;
