import React, { useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import client from '../../services/client';

interface Props {
  label: string;
  endpoint: string;
  minLength: number;
  onChange: any;
  ignore?: Array<string>;
  error?: boolean;
  helperText?: React.ReactNode;
}

const AsynchronousAutocomplete = ({ label, endpoint, minLength, onChange, ignore = [], error, helperText }: Props) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [options, setOptions] = React.useState<Array<any>>([]);
  const [query, setQuery] = React.useState<string>('');

  useEffect(() => {
    if (query.length < minLength) {
      return undefined;
    }

    setLoading(true);
    client
      .get(`${endpoint}?query=${query}`)
      .then((response) => setOptions(response.data.filter((item: any) => !ignore?.includes(item.name))))
      .finally(() => setLoading(false));
  }, [query, endpoint, minLength, ignore]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      getOptionLabel={(option: any) => option.name}
      onChange={(e: any, value: any) => onChange(value)}
      options={options}
      loading={loading}
      disableClearable={true}
      noOptionsText={query.length < minLength ? 'Begin met typen om te zoeken' : 'Geen resultaten gevonden'}
      renderInput={(params: any) => (
        <TextField
          {...params}
          label={label}
          onChange={(e) => setQuery(e.target.value)}
          fullWidth
          margin="normal"
          helperText={helperText}
          error={error}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default AsynchronousAutocomplete;
