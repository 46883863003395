import {useQuery} from "react-query";
import client from "../services/client";

export const getDatabaseServers = () => {
  return useQuery({
    queryKey: ['databaseServers'],
    queryFn: () => client.get('database/server').then(response => response.data),
    initialData: [],
  });
};
