import axios from 'axios';
import {toast} from 'react-toastify';
import history from './history';

const client = axios.create({
  baseURL: `${process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_URL : window.location.origin}/api/`,
});

client.interceptors.request.use((config: any) => {
  if (localStorage.getItem('token') === null) {
    history.push('/login');
  }

  config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;

  return config;
});

client.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status !== 401) {
      if (typeof error.response.data.message === 'object') {
        // toast.error(String(Object.values(error.response.data.message)[0]));
      } else {
        toast.error(error.response.data.message);
      }

      return Promise.reject(error);
    }

    if (localStorage.getItem('token') !== null) {
      toast.error('Sessie verlopen. Log opnieuw in');
    }
    localStorage.removeItem('token');

    history.push('/login');

    return Promise.reject(error);
  }
);

export default client;
