import React, {useEffect} from 'react';
import {ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Navigation from './Navigation';
import Header from './Header';
import 'react-toastify/dist/ReactToastify.css';
import Router from './Router';
import theme from '../../styles/theme';
import {User} from '../../types';
import {UserContext} from '../../provider/UserProvider';
import {Box, Hidden, useMediaQuery} from '@mui/material';
import client from '../../services/client';
import { QueryClientProvider } from 'react-query';
import queryClient from "../../services/queryClient";

const drawerWidth = 280;

const App = () => {
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
  const [mobileOpen, setMobileOpen] = React.useState<boolean>(false);
  const [user, setUser] = React.useState<User>({
    id: 0,
    name: '',
    email: '',
    password: '',
    roles: [],
    internal: false,
    database: {
      age: 0,
      expires: 0,
      expired: false,
    },
    secure: false,
    customer: {
      id: 0,
      name: '',
      architectureUrl: null,
      amountOfUsers: 0,
      amountOfZones: 0,
      amountOfProjects: 0,
      amountOfResources: 0,
      users: [],
      zones: [],
      projects: [],
      services: [],
      resources: [],
      invoicedSums: {},
      subscriptionId: null,
      debtorNumber: null,
    },
  });

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    client.get('user/current').then((response: any) => setUser(response.data));
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <UserContext.Provider value={{user, setUser}}>
          <Box sx={{display: 'flex', minHeight: '100vh'}}>
            <CssBaseline />
            <Box component="nav" sx={{width: {sm: drawerWidth}, flexShrink: {sm: 0}}}>
              {isSmUp
                ? null
                : (
                  <Navigation
                    PaperProps={{style: {width: drawerWidth, backgroundColor: '#000'}}}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                  />
                  )}
              <Hidden smDown>
                <Navigation
                  PaperProps={{style: {width: drawerWidth, backgroundColor: '#000'}}}
                  sx={{display: {sm: 'block', xs: 'none'}}}
                />
              </Hidden>
            </Box>
            <Box sx={{flex: 1, display: 'flex', flexDirection: 'column', width: `calc(100% - ${drawerWidth}px)`}}>
              <Header onDrawerToggle={handleDrawerToggle} />
              <Box component="main" sx={{
                flex: 1,
                py: isSmUp ? 5 : 1.5,
                px: isSmUp ? 5 : 1.5,
                bgcolor: '#f4f4f4',
              }}>
                <Router />
              </Box>
            </Box>
          </Box>
        </UserContext.Provider>
      </ThemeProvider>
    </QueryClientProvider>
  );
};

export default App;
