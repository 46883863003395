import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import Typography from '@mui/material/Typography';

interface props {
  data: any
  type?: any
}

export const DataToolTip = ({data, type}: props) => {

  let info: string;
  info = data.map((value: any) => value[type]).join(', ');

  return (
    <Tooltip
      title={<Typography fontSize={16}>{info}</Typography>}
      placement="right"
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 600}}
    >
      <IconButton>
        <InfoIcon/>
      </IconButton>
    </Tooltip>
  )
}
