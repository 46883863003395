import {useMutation, useQuery} from "react-query";
import client from "../services/client";
import {MessageQueueUser} from "../types";
import queryClient from "../services/queryClient";
import {toast} from "react-toastify";
import confirm from "../elements/Confirmation/Confirmation";

export const getMessageQueueUsers = () => {
  return useQuery({
    queryKey: ['messageQueueUsers', 'resources'],
    queryFn: () => client.get('mq/user').then(response => response.data),
    initialData: [],
  });
};

export const getMessageQueueUser = (id: number) => {
  return useQuery({
    queryKey: ['messageQueueUser', id],
    queryFn: () => client.get(`mq/user/${id}`).then(response => response.data),
    enabled: id !== 0,
    initialData: {
      id: 0,
      name: '',
      password: '',
      server: null,
      customer: null,
      project: null,
    },
  });
};

export const createMessageQueueUser = () => {
  return useMutation({
    mutationFn: async (user: MessageQueueUser) => {
      return client.post('mq/user', {
        ...user,
        server: user.server?.id,
        customer: user.customer?.id,
        project: user.project?.id,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['messageQueueUsers']});
      toast.success('Gebruiker aangemaakt');
    },
  });
};

export const updateMessageQueueUser = () => {
  return useMutation({
    mutationFn: async (user: MessageQueueUser) => {
      return client.put(`mq/user/${user.id}`, {
        ...user,
        server: user.server?.id,
        customer: user.customer?.id,
        project: user.project?.id,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['messageQueueUsers']});
      toast.success('Gebruiker gewijzigd');
    },
  });
};

export const deleteMessageQueueUser = () => {
  return useMutation({
    mutationFn: async (user: MessageQueueUser) => {
      if (await confirm({
        title: 'Gebruiker verwijderen',
        text: 'Deze actie kan niet ongedaan gemaakt worden',
      })) {
        return client.delete(`mq/user/${user.id}`);
      }

      return Promise.reject(new Error('Cancelled'));
    },
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['messageQueueUsers']});
      toast.success('Gebruiker verwijderd');
    },
  });
};
