import React from 'react';
import {Alert, Box, Grid, Paper, TextField, Typography} from "@mui/material";
import {Formik, FormikHelpers} from "formik";
import * as Yup from "yup";
import {LoadingButton} from "@mui/lab";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";
import client from "../../services/client";
import {toast} from "react-toastify";

const ProfileDatabaseForm = () => {
  const navigate = useNavigate();

  const schema = Yup.object().shape({
    password: Yup.string()
      .matches(/^(?=.*[!@#$%^&*()\-_=+{}[\]|:;"'<>,.?/~`])(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[!@#$%^&*()\-_=+{}[\]|:;"'<>,.?/~`A-Za-z\d]{32}$/, 'Wachtwoord voldoet niet aan de eisen')
      .required('Dit veld mag niet leeg zijn'),
    passwordConfirmation: Yup.string().oneOf([Yup.ref('password')], 'Wachtwoord komt niet overeen')
  });


  const onSubmit = (values: {password: string, passwordConfirmation: string}, actions: FormikHelpers<{password: string, passwordConfirmation: string}>) => {
    client.put('user/database/password', {password: values.password})
      .then(() => {
        toast.success('Database wachtwoord gewijzigd');
        navigate('/profile');
      })
      .finally(() => actions.setSubmitting(false));
  }

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <Paper sx={{p: 6}}>
          <Formik initialValues={{password: '', passwordConfirmation: ''}} validationSchema={schema} onSubmit={onSubmit} enableReinitialize={true}>
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isValid,
                isSubmitting,
              }) => (
              <form onSubmit={handleSubmit}>
                <Typography variant="h6" fontWeight="bold">Database wachtwoord wijzigen</Typography>

                <Alert severity="info" sx={{my: 2}}>
                  Het wachtwoord moet aan de volgende eisen voldoen: <br/>
                  &nbsp;- Precies 32 tekens <br/>
                  &nbsp;- Minimaal 1 hoofdletter <br/>
                  &nbsp;- Minimaal 1 kleine letter <br/>
                  &nbsp;- Minimaal 1 cijfer <br/>
                  &nbsp;- Minimaal 1 speciaal teken <br/>
                  <br/>
                  Zorg dat je zelf het wachtwoord ergens opslaat. Het wachtwoord wordt niet opgeslagen binnen My Friday.
                </Alert>

                <TextField
                  type="password"
                  name="password"
                  label="Wachtwoord"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched.password && errors.password)}
                  helperText={errors.password && touched.password && errors.password}
                  fullWidth
                />

                <TextField
                  type="password"
                  name="passwordConfirmation"
                  label="Herhaal wachtwoord"
                  value={values.passwordConfirmation}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched.passwordConfirmation && errors.passwordConfirmation)}
                  helperText={errors.passwordConfirmation && touched.passwordConfirmation && errors.passwordConfirmation}
                  fullWidth
                />

                <Box sx={{pt: 3}}>
                  <LoadingButton type="submit" color="primary" variant="contained" disabled={!isValid} loading={isSubmitting}>
                    Wijzigen
                  </LoadingButton>
                  <Button onClick={() => navigate('/profile')} color="secondary" disabled={isSubmitting} sx={{ml: 2}}>Annuleren</Button>
                  <Box style={{flex: '1 0 0'}} />
                </Box>
              </form>
            )}
          </Formik>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default ProfileDatabaseForm;
