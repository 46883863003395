import {Grid, Paper, Stack, Table, TableCell, TableRow, Typography} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import {ArrowBack, ZoomInOutlined} from '@mui/icons-material';
import React from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import DataTable from '../../elements/DataTable/DataTable';
import {ColumnDefinitionType, TableAction} from '../../types';
import {getUser} from '../../api/user';

const Detail = () => {
  const navigate = useNavigate();
  const {id} = useParams();
  const {data: user, isLoading, isFetching} = getUser(+(id || 0));

  const styles = {height: '60px', px: 0, width: '50%'}

  return (
    <Grid container justifyContent="center" gap={4}>
      <Grid item xs={12} sm={12} md={12} lg={5}>
        <Paper sx={{p: 6}}>
          <Stack direction="row" justifyContent="start" alignItems="center" sx={{mb: 4}}>
            <Tooltip title="Terug">
              <IconButton onClick={() => navigate(-1)} sx={{mr: 1}}>
                <ArrowBack />
              </IconButton>
            </Tooltip>
            <Typography variant="h6" fontWeight="bold">{user.name}</Typography>
          </Stack>
          <Table>
            <TableRow>
              <TableCell sx={styles}>E-mail</TableCell>
              <TableCell sx={styles}>{user.email}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{...styles, borderBottom: 'none'}}>Telefoonnummer</TableCell>
              <TableCell sx={{...styles, borderBottom: 'none'}}>{user.phone}</TableCell>
            </TableRow>
          </Table>
        </Paper>
        <Paper sx={{mt: 4}}>
          <DataTable
            columns={[
              { key: 'customer', title: 'Klant', type: 'string', sortable: true },
              { key: 'name', title: 'Functie', type: 'string', sortable: true },
            ] as Array<ColumnDefinitionType<{id: number, customer: string, name: string}>>}
            rows={user.customerRoles}
            loading={isLoading || isFetching}
            actions={[
              { title: 'Bekijken', icon: <ZoomInOutlined/>, variant: 'primary', onClick: (role) => navigate(`/customer/${role.id}/data`), type: 'button' },
            ] as Array<TableAction<{id: number, customer: string, name: string}>>}
            toolbar={{
              title: 'Gekoppelde klanten',
            }}
          />
        </Paper>
      </Grid>
    </Grid>
  )
};

export default Detail;
