import React from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import {Info} from '@mui/icons-material';

interface Props {
    colSpan: number;
    text?: string;
}

const InfoRow = ({colSpan, text = 'Er is geen data gevonden'}: Props) => {
  return (
        <TableRow>
            <TableCell colSpan={colSpan}>
                <IconButton disableTouchRipple={true}>
                    <Info />
                </IconButton>
                <span>{text}</span>
            </TableCell>
        </TableRow>
  );
};

export default InfoRow;
