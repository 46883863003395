import {Alert, Button, Grid, Paper, Typography} from '@mui/material';
import React, {useContext, useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {joiResolver} from "@hookform/resolvers/joi";
import Joi from "joi";
import {FormProvider, useForm} from "react-hook-form";
import TextField from '../../elements/Form/TextField';
import Autocomplete from '../../elements/Form/Autocomplete';
import { LoadingButton } from '@mui/lab';
import validationSchema from "../../services/validationSchema";
import {Cluster, MessageQueueUser} from "../../types";
import {getMessageQueueServers} from "../../api/messageQueueServer";
import {createMessageQueueUser, getMessageQueueUser, updateMessageQueueUser} from "../../api/messageQueueUser";
import ProjectSelect from "../../elements/Form/ProjectSelect";
import {CopyBlock, dracula} from "react-code-blocks";
import {generatePassword} from "../../services/password";
import CustomerSelect from "../../elements/Form/CustomerSelect";
import {UserContext} from "../../provider/UserProvider";
import {hasRole} from "../../services/role";

const Form = () => {
  const {user: currentUser} = useContext(UserContext);
  const navigate = useNavigate();
  const { id } = useParams();
  const {data: user} = getMessageQueueUser(+(id || 0));
  const {data: servers} = getMessageQueueServers();
  const mutation = id === undefined ? createMessageQueueUser() : updateMessageQueueUser();
  const [password, setPassword] = useState<string>('');

  const schema = validationSchema.object({
    name: Joi.string()
      .max(253)
      .required()
      .when('project', {
        is: null,
        then: Joi.string().regex(/^[a-z0-9_]+$/),
        otherwise: Joi.custom((value, helpers) => {
          const domain = helpers.state.ancestors[0].project.domain.replaceAll('.', '_');
          const dynamicRegexPattern = new RegExp(`^(?!_)(?:[a-z0-9_]*_)?${domain}(?:_[a-z0-9_]*[^_])?$`);

          if (!dynamicRegexPattern.test(value)) {
            return helpers.error('regex.message_queue.project', { domain: domain });
          }

          return value;
        })
      })
      .messages({
        'string.pattern.base': 'De waarde voldoet niet aan het vereiste formaat. Het mag alleen kleine letters, cijfers en underscores bevatten.',
        'regex.message_queue.project': 'De waarde voldoet niet aan het vereiste formaat. Het moet \'{#domain}\' bevatten en kan andere kleine letters, cijfers en underscores bevatten.',
      }),
    server: Joi.object().required(),
    customer: Joi.object().required(),
    project: Joi.object().allow(null),
  }).unknown();

  const form = useForm<MessageQueueUser>({
    defaultValues: user,
    resolver: joiResolver(schema),
    mode: 'onBlur',
  });

  const onSubmit = (values: MessageQueueUser) => {
    return mutation.mutateAsync({...values, password: password}).catch((error) => {
      Object.keys(error.response.data.errors || {}).forEach(key => {
        form.setError(key as keyof MessageQueueUser, {type: 'custom', message: error.response.data.errors[key]})
      })
    });
  }

  useEffect(() => {
    if (form.watch('project') === null) {
      return;
    }

    form.trigger('name');
  }, [form.watch('project')]);

  useEffect(() => {
    setPassword(generatePassword(32, true, true, true, false));
  }, []);

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} sm={12} md={12} lg={6}>
        <Paper sx={{p: 6, maxWidth: '600px', margin: '0 auto'}}>
          {form.formState.isSubmitSuccessful ? (
            <>
              <Typography variant="h6" fontWeight="bold">Gebruiker is aangemaakt</Typography>
              <Alert severity="warning" sx={{my: 2}} elevation={0}>Zorg dat het wachtwoord direct in Vault wordt gezet. Het wachtwoord wordt niet door My Friday bewaard.</Alert>
              <Typography sx={{mb: 1}}>De gebruiker is met het volgende wachtwoord aangemaakt:</Typography>
              <CopyBlock
                text={password}
                language="text"
                showLineNumbers={false}
                theme={dracula}
              />
              <Button onClick={() => navigate(-1)} sx={{mt: 2}} color="primary" variant="contained">Bevestigen</Button>
            </>
          ) : (
            <>
              <Typography variant="h6" fontWeight="bold">Gebruiker {id === undefined ? 'aanmaken' : 'wijzigen'}</Typography>
              <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)}>
                  <TextField name="name" label="Naam" />
                  <Autocomplete
                    options={servers}
                    name="server"
                    label="Message queue server"
                    getOptionLabel={(option: Cluster) => option.name}
                    getOptionDisabled={(option: Cluster) => option.tags.includes('deprecated') && !hasRole(currentUser, ['ROLE_ADMIN'])}
                  />
                  <CustomerSelect name="customer" label="Klant"/>
                  <ProjectSelect name="project" label="Project"/>
                  <LoadingButton sx={{mt: 2}} type="submit" color="primary" variant="contained" loading={form.formState.isSubmitting}>
                    {id === undefined ? 'Aanmaken' : 'Wijzigen'}
                  </LoadingButton>
                  <Button onClick={() => navigate(-1)} sx={{mt: 2, ml: 1}} color="secondary" disabled={form.formState.isSubmitting}>Annuleren</Button>
                </form>
              </FormProvider>
            </>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
}

export default Form;
