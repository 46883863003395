import React, {ReactElement, useEffect, useState} from 'react';
import DataTableToolbar from '../../elements/DataTable/DataTableToolbar';
import {StackedBar} from './StackedBar';
import {DatePicker} from './DatePicker';
import {addDays, endOfDay, startOfDay, subDays} from "date-fns";
import DataTable from '../../elements/DataTable/DataTable';
import client from "../../services/client";
import './kubecost.css';
import {SelectForm} from './SelectForm';
import {Paper} from "@mui/material";
import {ColumnDefinitionType, Kubecost} from "../../types";

const columns: Array<ColumnDefinitionType<Kubecost>> = [
  {key: 'namespace', title: 'Namespace', type: 'string', sortable: true},
  {key: 'cpuCost', title: 'CPU', type: 'price', sortable: true},
  {key: 'ramCost', title: 'RAM', type: 'price', sortable: true},
  {key: 'sharedCost', title: 'Shared', type: 'price', sortable: true},
  {key: 'totalCost', title: 'Totaal', type: 'price', sortable: true}
];

const Overview = () => {
  const [startDate, setStartDate] = useState(startOfDay(subDays(new Date(), 7)));
  const [endDate, setEndDate] = useState(endOfDay(addDays(new Date(), -1)));
  const [kubeChartData, setChartData] = useState([]);
  const [kubeTableData, setTableData] = useState([]);
  const [selectedNamespace, setSelectedNamespace] = useState();
  const [selectedCustomer, setSelectedCustomer] = useState();
  const [selectedCluster, setSelectedCluster] = useState();
  const [clusters, setClusterList] = useState([]);

  useEffect(() => {
    client.get('kubecost', {
      params: {
        startDate: startDate,
        endDate: endDate,
        namespace: selectedNamespace,
        customerName: selectedCustomer,
        clusterName: selectedCluster
      }
    }).then((response: any) => setChartData(response.data));
    client.get('kubecost/usage', {
      params: {
        startDate: startDate,
        endDate: endDate,
        customerName: selectedCustomer,
        clusterName: selectedCluster
    }}).then((response: any) => setTableData(response.data));
    client.get('kubecost/clusters', {
      params: {
        customerName: selectedCustomer
    }}).then((response: any) => setClusterList(response.data));
    },[startDate, endDate, setChartData, setTableData, selectedNamespace, selectedCustomer, selectedCluster]);

  const components: Array<ReactElement> = [
    <DatePicker setStartDate={setStartDate} setEndDate={setEndDate}/>,
    <SelectForm inputData={clusters} setSelected={setSelectedCluster} type="clusterName" placeholder="Filter op Cluster" searchable={false} menuHeight={120}/>,
    <SelectForm inputData={kubeTableData} setSelected={setSelectedCustomer} type="customerName" placeholder="Filter op klant" searchable={true}/>,
    <SelectForm inputData={kubeTableData} setSelected={setSelectedNamespace} type="namespace" placeholder="Filter grafiek op namespace" searchable={true}/>
  ];

  const getTableRows = () => {
    return kubeTableData.map((value: any) => {
      return {
        ...value,
        totalCost: value.cpuCost + value.ramCost + value.sharedCost
      }
    })
  }

  const calculateSummedTotal = (costType: string) => {
    let cost: number = 0;
    getTableRows().forEach((value: any) => {
      cost += value[costType];
    })

    return cost;
  }

  const summedCosts = [
    calculateSummedTotal('cpuCost'),
    calculateSummedTotal('ramCost'),
    calculateSummedTotal('sharedCost'),
    calculateSummedTotal('totalCost'),
  ];

  return(
    <Paper className="kubecost">
      <DataTableToolbar title="Kubecost" components={components} />
      <StackedBar kubeData={kubeChartData}/>
      <DataTable columns={columns} rows={getTableRows()} summedValues={summedCosts}/>
    </Paper>
  )
}

export default Overview;
