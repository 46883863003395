import React from 'react';
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    InputAdornment,
    MenuItem,
    TextField,
    Typography,
    Alert,
} from '@mui/material';
import { FullRecord, Record, Zone } from '../../../types';
import * as Yup from 'yup';
import psl from 'psl';
import { Formik } from 'formik';

const types = ['A', 'AAAA', 'CAA', 'CNAME', 'MX', 'NAPTR', 'SSHFP', 'NS', 'SOA', 'SRV', 'TXT'];
const transports = ['tcp', 'tls', 'sctp', 'udp'];

interface Props {
    zone: Zone;
    record: Record;
    open: boolean;
    onClose: () => void;
    onConfirm: (record: Record) => void;
}

const getFullName = (domain: string, name?: string) => {
    if (typeof name === 'undefined' || name === '') {
        return domain;
    }

    return `${name}.${domain}`;
};

const hasRecord = (zone: Zone, type: string, values: FullRecord) => {
    if (values.type !== type) {
        return false;
    }

    return (
        zone.records.filter(
            (r) =>
                r.name === getFullName(zone.name, values.name) && r.id !== values.id && [type].includes(r.type)
        ).length > 0
    );
};

const Form = ({ zone, record, open, onClose, onConfirm }: Props) => {
    const schema = Yup.object().shape({
        name: Yup.string()
            .test(
                'domain-not-repeated',
                `${zone.name} wordt al automatisch aan het eind van de naam toegevoegd`,
                (value: any) => !value?.includes(zone.name)
            )
            .test(
                'domain-already-has-cname-record',
                (context: any) => `${getFullName(zone.name, context.value)} heeft al een CNAME record, en mag dus geen andere records bevatten`,
                (value: any) => zone.records.filter((r) => r.type === 'CNAME' && r.id !== record.id &&  r.name === getFullName(zone.name, value)).length === 0
            )
            .when('type', {
                is: (type: string) => type === 'CNAME',
                then: Yup.string()
                    .test(
                        'cname-root-domain',
                        'Een CNAME record kan niet op het root domein aangemaakt worden',
                        (value: any) => zone.name !== getFullName(zone.name, value)
                    )
                    .test(
                        'cname-other-records',
                        (context: any) => `${getFullName(zone.name, context.value)} mag geen andere records bevatten`,
                        (value: any) =>
                            zone.records.filter((r) => r.id !== record.id && r.name === getFullName(zone.name, value)).length === 0
                    ),
            }),
        service: Yup.string().when('type', {
            is: (type: string) => type === 'SRV',
            then: Yup.string().required('Service mag niet leeg zijn'),
        }),
        transport: Yup.string().when('type', {
            is: (type: string) => type === 'SRV',
            then: Yup.string().required('Transport mag niet leeg zijn'),
        }),
        type: Yup.string().required('Type mag niet leeg zijn'),
        content: Yup.string()
            .when('type', {
                is: (type: string) => type !== 'SRV',
                then: Yup.string().required('Content mag niet leeg zijn'),
            })
            .when('type', {
                is: (type: string) => type === 'A',
                then: Yup.string().matches(
                    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
                    'Het A record moet een geldig IP adres zijn'
                ),
            })
            .when('type', {
                is: (type: string) => type === 'AAAA',
                then: Yup.string().matches(
                    /^((?=.*::)(?!.*::.+::)(::)?([\dA-F]{1,4}:(:|\b)|){5}|([\dA-F]{1,4}:){6})((([\dA-F]{1,4}((?!\3)::|:\b|$))|(?!\2\3)){2}|(((2[0-4]|1\d|[1-9])?\d|25[0-5])\.?\b){4})$/i,
                    'Het AAAA record moet een geldig IPv6 adres zijn'
                ),
            })
            .when('type', {
                is: (type: string) => type === 'CNAME',
                then: Yup.string().test(
                    'dot-check',
                    'Het CNAME record mag geen punt aan het eind bevatten',
                    (value) => value?.charAt(value?.length - 1) !== '.'
                ),
            }),
        weight: Yup.number().when('type', {
            is: (type: string) => type === 'SRV',
            then: Yup.number().required('Gewicht mag niet leeg zijn'),
        }),
        port: Yup.number().when('type', {
            is: (type: string) => type === 'SRV',
            then: Yup.number().required('Poort mag niet leeg zijn'),
        }),
        target: Yup.string().when('type', {
            is: (type: string) => type === 'SRV',
            then: Yup.string()
                .required('Bestemming mag niet leeg zijn')
                .test('domain', 'Bestemming moet een geldig domein zijn', (value) => psl.get(String(value)) !== null),
        }),
        ttl: Yup.number().required('TTL mag niet leeg zijn'),
    });

    const initialValues = () => {
        const fullRecord = {
            ...record,
            content: record.content.replaceAll('"', ''),
            name: record.name.replace(`.${zone.name}`, '').replace(zone.name, ''),
            service: '',
            transport: '',
            weight: 0,
            port: 0,
            target: '',
        } as FullRecord;

        if (fullRecord.type === 'SRV') {
            const [weight, port, target] = fullRecord.content.split(' ');
            [fullRecord.service, fullRecord.transport] = fullRecord.name.split('.');

            fullRecord.weight = +weight;
            fullRecord.port = +port;
            fullRecord.target = target;
        }

        return fullRecord;
    };

    const onSubmit = (fullRecord: FullRecord) => {
        const updatedRecord = {
            id: fullRecord.id,
            name: fullRecord.name === '' ? zone.name : `${fullRecord.name.trim()}.${zone.name}`,
            type: fullRecord.type,
            content: fullRecord.content,
            priority: fullRecord.priority,
            ttl: fullRecord.ttl,
        } as Record;

        if (updatedRecord.type === 'TXT') {
            updatedRecord.content = `"${fullRecord.content.replace('"', '')}"`;
        }

        if (updatedRecord.type === 'SRV') {
            updatedRecord.name = `_${fullRecord.service.replace('_', '')}._${fullRecord.transport.replace('_', '')}.${zone.name}`;
            updatedRecord.content = `${fullRecord.weight} ${fullRecord.port} ${fullRecord.target}`;
        }

        onConfirm(updatedRecord);
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <Formik initialValues={initialValues()} validationSchema={schema} onSubmit={onSubmit}>
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isValid,
                    isSubmitting,
                    setFieldTouched,
                }) => (
                    <form onSubmit={handleSubmit}>
                        <DialogTitle>{values.id === 0 ? 'Record aanmaken' : 'Record wijzigen'}</DialogTitle>
                        <DialogContent>
                            <TextField
                                name="type"
                                select
                                label="Type"
                                value={values.type}
                                onChange={(e) => {
                                    handleChange(e);
                                    setFieldTouched('name');
                                }}
                                onBlur={handleBlur}
                                helperText={errors.type && touched.type && errors.type}
                                margin="normal"
                                error={Boolean(errors.type && touched.type)}
                                fullWidth
                                disabled={values.id !== 0 || isSubmitting}
                            >
                                {types.map((option) => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </TextField>
                            {(values.type !== 'SRV' || values.id !== 0) && (
                                <TextField
                                    name="name"
                                    label="Naam"
                                    value={values.name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={Boolean(errors.name && touched.name)}
                                    helperText={errors.name && touched.name && errors.name}
                                    margin="normal"
                                    disabled={isSubmitting || 0 !== values.id}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Typography>.{zone.name}</Typography>
                                            </InputAdornment>
                                        ),
                                    }}
                                    fullWidth
                                />
                            )}
                            {values.type === 'SRV' && values.id === 0 && (
                                <>
                                    <TextField
                                        name="transport"
                                        select
                                        label="Transport"
                                        value={values.transport}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={Boolean(errors.transport && touched.transport)}
                                        helperText={errors.transport && touched.transport && errors.transport}
                                        margin="normal"
                                        disabled={isSubmitting}
                                        fullWidth
                                    >
                                        {transports.map((option) => (
                                            <MenuItem key={option} value={option}>
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <TextField
                                        name="service"
                                        label="Service"
                                        value={values.service}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={Boolean(errors.service && touched.service)}
                                        helperText={errors.service && touched.service && errors.service}
                                        margin="normal"
                                        disabled={isSubmitting}
                                        fullWidth
                                    />
                                </>
                            )}
                            {values.type !== 'SRV' && (
                                <TextField
                                    name="content"
                                    label="Content"
                                    value={values.content}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={Boolean(errors.content && touched.content)}
                                    helperText={errors.content && touched.content && errors.content}
                                    margin="normal"
                                    disabled={isSubmitting}
                                    fullWidth
                                    InputProps={{
                                        startAdornment: values.type === 'TXT' ? '"' : '',
                                        endAdornment: values.type === 'TXT' ? '"' : '',
                                    }}
                                />
                            )}
                            {values.type === 'SRV' && (
                                <>
                                    <TextField
                                        name="weight"
                                        label="Gewicht"
                                        type="number"
                                        value={values.weight}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={Boolean(errors.weight && touched.weight)}
                                        helperText={errors.weight && touched.weight && errors.weight}
                                        margin="normal"
                                        disabled={isSubmitting}
                                        fullWidth
                                    />
                                    <TextField
                                        name="port"
                                        label="Poort"
                                        type="number"
                                        value={values.port}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={Boolean(errors.port && touched.port)}
                                        helperText={errors.port && touched.port && errors.port}
                                        margin="normal"
                                        disabled={isSubmitting}
                                        fullWidth
                                    />
                                    <TextField
                                        name="target"
                                        label="Bestemming"
                                        value={values.target}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={Boolean(errors.target && touched.target)}
                                        helperText={errors.target && touched.target && errors.target}
                                        margin="normal"
                                        disabled={isSubmitting}
                                        fullWidth
                                    />
                                </>
                            )}
                            {(values.type === 'SRV' || values.type === 'MX') && (
                                <TextField
                                    name="priority"
                                    label="Prioriteit"
                                    type="number"
                                    value={values.priority}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={Boolean(errors.priority && touched.priority)}
                                    helperText={errors.priority && touched.priority && errors.priority}
                                    margin="normal"
                                    disabled={isSubmitting}
                                    fullWidth
                                />
                            )}
                            <TextField
                                name="ttl"
                                label="TTL"
                                type="number"
                                value={values.ttl}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={Boolean(errors.ttl && touched.ttl)}
                                helperText={errors.ttl && touched.ttl && errors.ttl}
                                margin="normal"
                                disabled={isSubmitting}
                                fullWidth
                            />
                            {['AAAA', 'A'].map((type) => {
                                if (!hasRecord(zone, type, values)) {
                                    return <></>;
                                }

                                return (
                                    <Alert severity="warning" key={type}>
                                        {getFullName(zone.name, values.name)} heeft al een {type} record. Het is niet
                                        gebruikelijk om meerdere {type} records met dezelfde naam te hebben.
                                    </Alert>
                                );
                            })}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={onClose} color="primary" disabled={isSubmitting}>
                                Annuleren
                            </Button>
                            <Button type="submit" color="primary" disabled={!isValid || isSubmitting}>
                                {values.id === 0 ? 'Aanmaken' : 'Wijzigen'}
                                {isSubmitting && <CircularProgress size={20} style={{ marginLeft: '8px' }} />}
                            </Button>
                        </DialogActions>
                    </form>
                )}
            </Formik>
        </Dialog>
    );
};

export default Form;
