import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import {Box} from '@mui/material';

type Props = {
  title: string;
  text: string;
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

const DeleteConfirmation: React.FC<Props> = ({title, text, open, onClose, onConfirm}: Props) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
      <DialogActions sx={{px: 3, pb: 2}}>
        <Button onClick={onConfirm} color="primary">Bevestigen</Button>
        <Button onClick={onClose} color="primary">Annuleren</Button>
        <Box style={{flex: '1 0 0'}} />
      </DialogActions>
    </Dialog>
  );
};

export default DeleteConfirmation;
