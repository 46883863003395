import {useMutation, useQuery} from "react-query";
import client from "../services/client";
import {Project} from "../types";
import queryClient from "../services/queryClient";
import {toast} from "react-toastify";
import confirm from "../elements/Confirmation/Confirmation";

export const getProjects = (enabled: boolean = true) => {
  return useQuery({
    queryKey: ['projects'],
    queryFn: () => client.get('project').then(response => response.data),
    initialData: [],
    enabled: enabled,
  });
};

export const getProject = (id: number) => {
  return useQuery({
    queryKey: ['project', id],
    queryFn: () => client.get(`project/${id}`).then(response => response.data),
  });
};

export const getProjectResources = (id: number) => {
  return useQuery({
    queryKey: ['projectResources', id],
    queryFn: () => client.get(`project/${id}/resource`).then(response => response.data),
    initialData: [],
  });
};

export const getProjectLinks = (id?: number) => {
  return useQuery({
    queryKey: ['projectLinks', id],
    queryFn: () => client.get('link', {
      params: {
        project: id || null,
      },
    }).then(response => response.data),
    initialData: [],
  });
};

export const createProject = () => {
  return useMutation({
    mutationFn: async (project: Project) => {
      return client.post('project',{
        ...project,
        customer: project.customer?.id,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['projects']});
      toast.success('Project aangemaakt');
    },
  });
};

export const deleteProject = () => {
  return useMutation({
    mutationFn: async (project: Project) => {
      if (await confirm({
        title: 'Project verwijderen',
        text: 'Deze actie kan niet ongedaan gemaakt worden',
      })) {
        return client.delete(`project/${project.id}`);
      }

      return Promise.reject(new Error('Cancelled'));
    },
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['projects']});
      toast.success('Project verwijderd');
    },
  });
};
