import React from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import {TextField as MuiTextField} from '@mui/material';
import {TextFieldProps} from '@mui/material/TextField/TextField';
import {getIn} from '../../services/form';

type Props = Omit<TextFieldProps, 'name'> & {
  name: string;
}

const TextField = ({name, ...props}: Props) => {
  const {control, formState: {errors, isSubmitting}} = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({field}) => (
        <MuiTextField
          error={Boolean(getIn(errors, name))}
          helperText={getIn(errors, name)?.message}
          {...field}
          {...props}
          disabled={isSubmitting || props.disabled}
        />
      )}
    />
  );
};

export default TextField;
