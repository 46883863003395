import {Button, Grid, Paper, Typography} from '@mui/material';
import React, {useEffect} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {joiResolver} from "@hookform/resolvers/joi";
import Joi from "joi";
import {FormProvider, useForm} from "react-hook-form";
import TextField from '../../../elements/Form/TextField';
import Autocomplete from '../../../elements/Form/Autocomplete';
import { LoadingButton } from '@mui/lab';
import validationSchema from "../../../services/validationSchema";
import {Cluster, Namespace} from "../../../types";
import {createNamespace, getNamespace, updateNamespace} from "../../../api/namespace";
import {getClusters} from "../../../api/kubernetesCluster";
import ProjectSelect from '../../../elements/Form/ProjectSelect';
import CustomerSelect from "../../../elements/Form/CustomerSelect";
import BranchSelect from "../../../elements/Form/BranchSelect";

const tags: {[key: string]: string} = {
  'acceptance': 'Acceptance',
  'production': 'Production',
  'deprecated': 'Deprecated',
  'system': 'System',
};

const Form = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const {data: namespace} = getNamespace(+(id || 0));
  const {data: clusters} = getClusters();
  const mutation = id === undefined ? createNamespace() : updateNamespace();

  const schema = validationSchema.object({
    name: Joi.string()
      .max(253)
      .required()
      .when('project', {
        is: null,
        then: Joi.string().regex(/^[a-z0-9-]+$/),
        otherwise: Joi.custom((value, helpers) => {
          const domain = helpers.state.ancestors[0].project.domain.replaceAll('.', '-');
          const dynamicRegexPattern = new RegExp(`^(?!-)(?:[a-z0-9-]*-)?${domain}(?:-[a-z0-9-]*[^-])?$`);

          if (!dynamicRegexPattern.test(value)) {
            return helpers.error('regex.namespace.project', { domain: domain });
          }

          return value;
        })
      })
      .messages({
        'string.pattern.base': 'De waarde voldoet niet aan het vereiste formaat. Het mag alleen kleine letters, cijfers en streepjes bevatten.',
        'regex.namespace.project': 'De waarde voldoet niet aan het vereiste formaat. Het moet \'{#domain}\' bevatten en kan andere kleine letters, cijfers en streepjes bevatten.',
      })
    ,
    cluster: Joi.object().required(),
    customer: Joi.object(),
    project: Joi.object().allow(null),
    branch: Joi.string().allow(null).allow(''),
  }).unknown();

  const form = useForm<Namespace>({
    defaultValues: namespace,
    resolver: joiResolver(schema),
    mode: 'onChange',
  });

  const onSubmit = (values: Namespace) => {
    return mutation.mutateAsync(values).then(() => {
      navigate(-1);
    }).catch((error) => {
      Object.keys(error.response.data.errors || {}).forEach(key => {
        form.setError(key as keyof Namespace, {type: 'custom', message: error.response.data.errors[key]})
      })
    });
  }

  useEffect(() => {
    if (id !== undefined) {
      form.reset(namespace);
    }
  }, [namespace])

  useEffect(() => {
    if (form.watch('project') === null) {
      return;
    }

    form.trigger('name');
  }, [form.watch('project')]);

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} sm={12} md={12} lg={6}>
        <Paper sx={{p: 6, maxWidth: '600px', margin: '0 auto'}}>
          <Typography variant="h6" fontWeight="bold">Namespace {id === undefined ? 'aanmaken' : 'wijzigen'}</Typography>
          <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <TextField name="name" label="Naam" disabled={id !== undefined} />
              <Autocomplete
                options={clusters}
                name="cluster"
                label="Kubernetes cluster"
                getOptionLabel={(option: Cluster) => option.name}
                disabled={id !== undefined}
              />
              <CustomerSelect name="customer" label="Klant"/>
              <ProjectSelect name="project" label="Project" />
              <BranchSelect name="branch" label="Git branch" />
              <Autocomplete
                multiple
                name="tags"
                label="Tags"
                options={Object.keys(tags)}
                getOptionLabel={(tag) => tags[tag]}
              />
              <LoadingButton sx={{mt: 2}} type="submit" color="primary" variant="contained" loading={form.formState.isSubmitting}>
                {id === undefined ? 'Aanmaken' : 'Wijzigen'}
              </LoadingButton>
              <Button onClick={() => navigate(-1)} sx={{mt: 2, ml: 1}} color="secondary" disabled={form.formState.isSubmitting}>Annuleren</Button>
            </form>
          </FormProvider>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default Form;
