import {useMutation, useQuery} from 'react-query';
import client from '../services/client';
import {WebservicesBilling} from '../types';
import {toast} from 'react-toastify';

export const getWebservicesUsers = () => {
  return useQuery({
    queryKey: ['webservicesUsers', 'resources'],
    queryFn: () => client.get('webservices/user').then(response => response.data),
    initialData: [],
  });
};

export const getWebservicesUser = (id: number, period: 'monthly'|'quarterly') => {
  return useQuery({
    queryKey: ['webservicesUser', id, period],
    queryFn: () => client.get(`webservices/user/${id}`, {
      params: {
        period: period,
      },
    }).then(response => response.data),
    initialData: {
      id: 0,
      name: '',
      customer: null,
      data: {},
    },
    enabled: id !== 0,
  });
};

export const billWebservicesUser = () => {
  return useMutation({
    mutationFn: async (values: WebservicesBilling) => {
      return client.post(`webservices/user/${values.user.id}/bill`, {
        ...values,
        subscription: values.subscription?.id,
      });
    },
    onSuccess: () => {
      toast.success('Gebruiker gefactureerd');
    },
  });
};
