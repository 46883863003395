import {Button, Grid, Paper, Stack, Table, TableCell, TableRow, Typography} from '@mui/material';
import React from 'react';
import {useNavigate, useParams} from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import {ArrowBack, OpenInNewOutlined} from "@mui/icons-material";
import {getUser} from "../../../api/elasticUser";
import ProjectLinkButton from "../../ProjectResource/ProjectLinkButton";

const Detail = () => {
  const navigate = useNavigate();
  const {id} = useParams();
  const {data: user} = getUser(+(id || 0));

  const redirectBack = () => {
    navigate(-1);
  };

  const styles = {height: '60px', px: 0, width: '50%'}

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} sm={12} md={12} lg={6}>
        <Paper sx={{p: 6, maxWidth: '600px', margin: '0 auto'}}>
          <Stack direction="row" justifyContent="start" alignItems="center" sx={{mb: 4}}>
            <Tooltip title="Terug">
              <IconButton onClick={redirectBack} sx={{mr: 1}}>
                <ArrowBack />
              </IconButton>
            </Tooltip>
            <Typography variant="h6" fontWeight="bold">Elastic user {user.name}</Typography>
          </Stack>
          <Typography variant="subtitle1" fontWeight="bold">Algemene informatie</Typography>
          <Table>
            <TableRow>
              <TableCell sx={styles}>Naam</TableCell>
              <TableCell sx={styles}>{user.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={styles}>Deployment</TableCell>
              <TableCell sx={styles}>{user.deployment?.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={styles}>Kibana</TableCell>
              <TableCell sx={styles}>
                {user.deployment?.kibanaUrl === null ? (
                  'N.v.t.'
                ) : (
                  <Button
                    sx={{p: 1}}
                    startIcon={<OpenInNewOutlined />}
                    color="info"
                    onClick={() => window.open(user.deployment.kibanaUrl, '_blank')}
                  >
                    Openen
                  </Button>
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={styles}>Enterprise search</TableCell>
              <TableCell sx={styles}>
                {user.deployment?.appSearchUrl === null ? (
                  'N.v.t.'
                ) : (
                  <Button
                    sx={{p: 1}}
                    startIcon={<OpenInNewOutlined />}
                    color="info"
                    onClick={() => window.open(user.deployment.appSearchUrl, '_blank')}
                  >
                    Openen
                  </Button>
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{...styles, borderBottom: 'none'}}>Project</TableCell>
              <TableCell sx={{...styles, borderBottom: 'none'}}>
                {user.project === null ? (
                  <ProjectLinkButton resourceId={user.id} resourceType="Elastic user"/>
                ) : user.project.domain}
              </TableCell>
            </TableRow>
          </Table>

          <Typography variant="subtitle1" fontWeight="bold" sx={{mt: 2}}>Verbinden</Typography>
          <Table>
            <TableRow>
              <TableCell sx={styles}>Host</TableCell>
              <TableCell sx={styles}>{user.deployment?.elasticsearchUrl}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={styles}>Poort</TableCell>
              <TableCell sx={styles}>9200</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{...styles, borderBottom: 'none'}}>Gebruikersnaam</TableCell>
              <TableCell sx={{...styles, borderBottom: 'none'}}>{user.name}</TableCell>
            </TableRow>
          </Table>
        </Paper>
      </Grid>
    </Grid>
  )
};

export default Detail;
