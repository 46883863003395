import React, {ReactElement} from 'react';
import {ColumnDefinitionType, Customer, Invoice, TableAction} from '../../types';
import DataTable from '../../elements/DataTable/DataTable';
import {Alert, Autocomplete, Chip, Paper, TextField} from "@mui/material";
import {ZoomInOutlined} from "@mui/icons-material";
import {getInvoices} from '../../api/invoice';
import {getCustomers} from '../../api/customer';

const AdminOverview = () => {
  const [customer, setCustomer] = React.useState<Customer|null>(null);
  const {data: invoices, isLoading, isFetching} = getInvoices(customer?.id || null, customer !== null);
  const {data: customers} = getCustomers();

  const columns: Array<ColumnDefinitionType<Invoice>> = [
    { key: 'description', title: 'Beschrijving', type: 'string', sortable: true, filter: true },
    { key: 'totalAmount', title: 'Bedrag', type: 'price', sortable: true },
    { key: 'invoiceDate', title: 'Datum', type: 'date', sortable: true },
    { key: 'openAmount', title: 'Status', type: 'custom', sortable: true, content: (row) => {
      if (row.openAmount === '0.00') {
        return <Chip label="Betaald" className="MuiChip--success" />;
      }

      if (new Date(row.dueDate) < new Date()) {
        return <Chip label="Verlopen" className="MuiChip--danger" />;
      }

      return <Chip label="Open" className="MuiChip--warning" />;
    }},
  ];

  const actions: Array<TableAction<Invoice>> = [
    { title: 'Bekijken', icon: <ZoomInOutlined />, variant: 'primary', onClick: (invoice) => window.open(invoice.publicUrl, '_blank'), type: 'button' },
  ];

  const components: Array<ReactElement> = [(
    <Autocomplete
      disableClearable={true}
      sx={{minWidth: '200px', ml: 4}}
      options={customers.filter((c: Customer) => c.debtorNumber !== null)}
      getOptionLabel={(option: Customer) => option.name}
      value={customer ?? undefined}
      onChange={(event, value) => setCustomer(value)}
      renderInput={(params) => (
        <TextField {...params} label="Klant" variant="outlined" margin="none" size="small" />
      )}
    />
  )];

  return (
    <>
      {(invoices.filter((i: Invoice) => i.openAmount !== '0.00').length === 0 && invoices.length > 0) && (
        <Alert severity="success" sx={{mb: 3}}>Alle facturen zijn betaald.</Alert>
      )}
      {customer === null && (
        <Alert severity="info" sx={{mb: 3}}>Selecteer een klant om de facturen te tonen.</Alert>
      )}
      <Paper>
        <DataTable
          columns={columns}
          rows={invoices}
          loading={isLoading || isFetching}
          actions={actions}
          toolbar={{
            title: 'Facturen',
            components: components,
          }}
          defaultRowsPerPage={10}
          pagination
          defaultOrderColumn="invoiceNumber"
          defaultOrder="desc"
        />
      </Paper>
    </>
  );
};

export default AdminOverview;
