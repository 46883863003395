import React from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import {Autocomplete as MuiAutocomplete, AutocompleteProps, TextField} from '@mui/material';
import {getIn} from '../../services/form';
import {Customer} from "../../types";
import {getBranches} from "../../api/branch";

type Props = Omit<AutocompleteProps<any, any, any, any>, 'name'|'renderInput'|'options'> & {
  name: string;
  label: string;
  customer?: Customer;
}

const BranchSelect = ({name, label, customer, ...props}: Props) => {
  const {control, watch, formState: {errors, isSubmitting}} = useFormContext();
  const {data: branches, isFetching, isLoading} = getBranches(watch('project')?.id || 0);

  return (
    <Controller
      name={name}
      control={control}
      render={({field: {onChange, ...field}}) => (
        <MuiAutocomplete
          {...field}
          {...props}
          value={watch(name)}
          options={branches}
          loading={isFetching || isLoading}
          disabled={branches?.length === 0 || isSubmitting || props.disabled}
          onChange={(event, value) => onChange(value)}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              error={Boolean(getIn(errors, name))}
              helperText={getIn(errors, name)?.message}
            />
          )}
        />
      )}
    />
  );
};

export default BranchSelect;
