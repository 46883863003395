import React from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import {Search} from '@mui/icons-material';
import { isMobile } from 'react-device-detect';

interface Props {
  handleSearch: (query: string) => void;
}

export default function SearchBar({ handleSearch }: Props) {
  const [query, setQuery] = React.useState('');

  const handleKeyUp = () => {
    handleSearch(query);
  };

  return (
    <Paper sx={{
      display: 'flex',
      alignItems: 'center',
      flex: '0 0 auto',
      ml: 2,
      mr: 2,
      mb: isMobile ? 2 : 0,
      border: `1px solid rgba(0, 0, 0, 0.12)`
    }} elevation={0}>
      <InputBase
        sx={{flex: 1, ml: 1}}
        placeholder="Zoeken . . ."
        onChange={(e) => setQuery(e.target.value)}
        onKeyUp={handleKeyUp}
      />
      <IconButton
        sx={{p: 1}}
        aria-label="search"
        onClick={() => handleSearch(query)}
        disabled={query.length < 3}
      >
        <Search />
      </IconButton>
    </Paper>
  );
}
