import {SelectPicker} from 'rsuite';
import React from "react";

interface props {
  inputData: any,
  type: string
  placeholder: string
  searchable: any
  menuHeight?: any
  setSelected: any
}

export function SelectForm({inputData, setSelected, type, placeholder, searchable, menuHeight}: props) {

  if (menuHeight === null){
    menuHeight = 320;
  }

  let uniqueLabels: any[] = [];

  inputData.forEach((item:any) => {
    if (item[type] && !uniqueLabels.includes(item[type])) {
        uniqueLabels.push(item[type]);
      }
    });

  uniqueLabels.sort();

  const data = uniqueLabels.map((item:any) => ({
    label: item,
    value: item
  }));

  const handleChange = (value: any | null) => {
    setSelected(value);
  }

  return(
    <SelectPicker
      className="kube-select"
      size="lg"
      data={data}
      searchable={searchable}
      virtualized
      placeholder={placeholder}
      onChange={ (e) => {handleChange(e);}}
      menuMaxHeight={menuHeight}
    />
  )
}
