import React from 'react';
import {ColumnDefinitionType, CustomerResource, TableAction} from '../../types';
import {Paper} from '@mui/material';
import DataTable from '../../elements/DataTable/DataTable';
import {VisibilityOutlined} from '@mui/icons-material';
import {useNavigate} from "react-router-dom";
import CustomerLinkButton from "./CustomerLinkButton";
import {getCustomerResources} from "../../api/resource";

const Overview = () => {
  const navigate = useNavigate();
  const {data: resources, isLoading, isFetching} = getCustomerResources();

  const columns: Array<ColumnDefinitionType<CustomerResource>> = [
    { key: 'name', title: 'Naam', type: 'string', sortable: true, filter: true },
    { key: 'type', title: 'Type', type: 'string', sortable: true, filter: true },
    { key: 'customer.name', title: 'Klant', type: 'custom', sortable: true, filter: true, content: (row) => {
      if (row.customer !== null) {
        return row.customer.name;
      }

      return <CustomerLinkButton resourceId={row.id} resourceType={row.type}/>
    }},
  ];

  const actions: Array<TableAction<CustomerResource>> = [
    { title: 'Bekijken', icon: <VisibilityOutlined />, variant: 'primary', onClick: (resource) => {
      if (resource.type === 'Kubernetes namespace') {
        navigate(`/kubernetes/namespace/${resource.id}/statistics`);
      }
    }, type: 'button' },
  ];

  return (
    <Paper>
      <DataTable
        columns={columns}
        rows={resources}
        actions={actions}
        loading={isLoading || isFetching}
        defaultRowsPerPage={10}
        pagination
        toolbar={{
          title: 'Klant resources',
        }}
      />
    </Paper>
  );
};

export default Overview;
