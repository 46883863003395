import React from 'react';
import {Box, Grid, Typography} from '@mui/material';

interface Props {
  cryptokey: any;
}

const Detail = ({ cryptokey }: Props) => {
  return (
    <Box sx={{mt: 3, p: 2}}>
      <Typography variant="h5" sx={{mb: 1}}>
        Key
      </Typography>
      <Grid container spacing={3} sx={{mb: 1}}>
        <Grid item xs={2}>
          <Typography sx={{fontWeight: 'bold'}}>Algorithm</Typography>
          <Typography sx={{fontWeight: 'bold'}}>Bits</Typography>
          <Typography sx={{fontWeight: 'bold'}}>Flags</Typography>
          <Typography sx={{fontWeight: 'bold'}}>DNS key</Typography>
          <Typography sx={{fontWeight: 'bold'}}>Key type</Typography>
        </Grid>
        <Grid item xs={10}>
          <Typography>{cryptokey.algorithm}</Typography>
          <Typography>{cryptokey.bits}</Typography>
          <Typography>{cryptokey.flags}</Typography>
          <Typography>{cryptokey.dnskey}</Typography>
          <Typography>{cryptokey.keytype}</Typography>
        </Grid>
      </Grid>
      <Typography variant="h5" sx={{mb: 1}}>
        DS
      </Typography>
      {cryptokey.ds.map((record: any, i: number) => (
        <Typography key={i}>{record}</Typography>
      ))}
    </Box>
  );
};

export default Detail;
