import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import {ThemeProvider} from '@mui/material/styles';
import theme from '../../styles/theme';
import {Formik} from 'formik';
import * as Yup from 'yup';
import {Alert, Box, Button, Container, Divider, Paper} from '@mui/material';
import {LoginData} from '../../types';
import axios from 'axios';
import {LoadingButton} from '@mui/lab';
import {useNavigate} from 'react-router-dom';
// @ts-ignore
import logo from '../../images/logo-white.svg';
import background from '../../images/background.png';

const Login = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');

  const schema = Yup.object().shape({
    username: Yup.string().required('Dit veld is verplicht'),
    password: Yup.string().required('Dit veld is verplicht'),
  });

  const onConfirm = (values: LoginData) => {
    const url = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_URL : window.location.origin;

    setLoading(true);
    setErrorMessage('');
    axios
      .post(`${url}/api/token`, values)
      .then((response: any) => {
        localStorage.setItem('token', response.data.token);
        navigate('/');
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setErrorMessage('Onjuiste gebruikersnaam of wachtwoord');
        } else {
          setErrorMessage('Er is iets fout gegaan');
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{
        display: 'flex',
        minHeight: '100vh',
        backgroundColor: 'transparent',
        backgroundImage: 'linear-gradient(180deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.84) 30%, #000 100%, #021226)'
      }}>
        <img src={background} alt="Background" style={{position: 'fixed', right: 0, bottom: 0, minWidth: '100%', minHeight: '100%', zIndex: -100}}/>
        <Container component="main" maxWidth="xs">
          <CssBaseline/>
          <Box sx={{mt: 8, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <img src={logo} alt="Webstores logo" height={40}/>
            <Paper sx={{p: 6, mt: 5}}>
              <Typography component="h1" variant="h6" sx={{mb: 1, fontWeight: 'bold'}}>Inloggen</Typography>
              <Formik
                initialValues={{username: '', password: ''} as LoginData}
                validationSchema={schema}
                onSubmit={onConfirm}
              >
                {({values, errors, touched, handleChange, handleBlur, handleSubmit}) => (
                  <form onSubmit={handleSubmit}>
                    <TextField
                      variant="standard"
                      name="username"
                      label="Emailadres"
                      value={values.username}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(errors.username && touched.username)}
                      helperText={errors.username && touched.username && errors.username}
                      margin="dense"
                      fullWidth
                    />
                    <TextField
                      variant="standard"
                      name="password"
                      type="password"
                      label="Wachtwoord"
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(errors.password && touched.password)}
                      helperText={errors.password && touched.password && errors.password}
                      margin="dense"
                      fullWidth
                    />
                    <LoadingButton
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      sx={{mt: 3, py: 1}}
                      loading={loading}
                    >
                      Inloggen
                    </LoadingButton>
                    {errorMessage !== '' && (
                      <Alert severity="error" sx={{mt: 2}}>{errorMessage}</Alert>
                    )}
                    <Divider sx={{mt: 3}}/>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      sx={{mt: 3, mb: 2, py: 1}}
                      href={`${process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_URL : window.location.origin}/connect/google`}
                    >
                      Friday login
                    </Button>
                  </form>
                )}
              </Formik>
            </Paper>
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default Login;
