import React from 'react';
import { ColumnDefinitionType, Redirect, TableAction, Zone } from '../../../types';
import DataTable from '../../../elements/DataTable/DataTable';
import DeleteConfirmation from '../../../elements/DataTable/DeleteConfirmation';
import client from '../../../services/client';
import { toast } from 'react-toastify';
import { AddBox, Delete, Edit } from '@mui/icons-material';
import Form from './Form';

const columns: Array<ColumnDefinitionType<Redirect>> = [
    { key: 'name', title: 'Oorsprong', type: 'string', sortable: true },
    { key: 'destination', title: 'Bestemming', type: 'string', sortable: true },
];

interface Props {
    zone: Zone;
    loading: boolean;
    refresh: () => void;
}

const Overview = ({ zone, refresh, loading }: Props) => {
    const [redirectToEdit, setRedirectToEdit] = React.useState<null | Redirect>(null);
    const [redirectToDelete, setRedirectToDelete] = React.useState<null | Redirect>(null);

    const handleAdd = () => {
        setRedirectToEdit({ id: 0, name: '', destination: '' });
    };

    const handleSubmit = (redirect: Redirect) => {
        if (redirect.id === 0) {
            client
                .post(`dns/zone/${zone.id}/redirect`, redirect)
                .then(() => {
                    toast.success('Redirect aangemaakt');
                    refresh();
                })
                .finally(() => setRedirectToEdit(null));
        } else {
            client
                .put(`dns/zone/${zone.id}/redirect/${redirect.id}`, redirect)
                .then(() => {
                    toast.success('Redirect gewijzigd');
                    refresh();
                })
                .finally(() => setRedirectToEdit(null));
        }
    };

    const handleDelete = () => {
        if (null === redirectToDelete) {
            return;
        }

        client
            .delete(`dns/zone/${zone.id}/redirect/${redirectToDelete.id}`)
            .then(() => {
                toast.success('Redirect verwijderd');
                refresh();
            })
            .finally(() => setRedirectToDelete(null));
    };

    const toolbarActions: Array<TableAction<undefined>> = [
        { title: 'Redirect aanmaken', icon: <AddBox />, variant: 'primary', type: 'button', onClick: handleAdd },
    ];

    const actions: Array<TableAction<Redirect>> = [
        { title: 'Bewerken', icon: <Edit />, variant: 'primary', type: 'button', onClick: setRedirectToEdit },
        { title: 'Verwijderen', icon: <Delete />, variant: 'danger', type: 'button', onClick: setRedirectToDelete },
    ];

    return (
        <>
            <DataTable
              columns={columns}
              rows={zone.redirects}
              actions={actions}
              loading={loading}
              toolbar={{
                title: 'Redirects',
                actions: toolbarActions,
              }}
            />
            {null !== redirectToEdit && (
                <Form
                    zone={zone}
                    redirect={redirectToEdit}
                    open={null !== redirectToEdit}
                    onClose={() => setRedirectToEdit(null)}
                    onConfirm={handleSubmit}
                />
            )}
            <DeleteConfirmation
                title="Redirect verwijderen?"
                text="Deze actie kan niet ongedaan gemaakt worden."
                open={null !== redirectToDelete}
                onConfirm={handleDelete}
                onClose={() => setRedirectToDelete(null)}
            />
        </>
    );
};

export default Overview;
