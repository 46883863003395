import {useMutation, useQuery} from "react-query";
import client from "../services/client";
import {Incident} from "../types";
import queryClient from "../services/queryClient";
import {toast} from "react-toastify";
import confirm from "../elements/Confirmation/Confirmation";

export const getIncidents = () => {
  return useQuery({
    queryKey: ['incidents'],
    queryFn: () => client.get('incident').then(response => response.data),
    initialData: [],
  });
};

export const getIncident = (id: number) => {
  return useQuery({
    queryKey: ['incident', id],
    queryFn: () => client.get(`incident/${id}`).then(response => response.data),
    enabled: id !== 0,
    initialData: {
      id: 0,
      name: '',
      level: 1,
      priority: 1,
      status: 'open',
      description: '',
      commander: null,
      engineers: [],
    },
  });
};

export const createIncident = () => {
  return useMutation({
    mutationFn: async (incident: Incident) => {
      return client.post('incident', {
        ...incident,
        commander: incident.commander?.id,
        engineers: incident.engineers.map(engineer => engineer.id),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['incidents']});
      toast.success('Incident aangemaakt');
    },
  });
};

export const updateIncident = () => {
  return useMutation({
    mutationFn: async (incident: Incident) => {
      return client.put(`incident/${incident.id}`, {
        ...incident,
        commander: incident.commander?.id,
        engineers: incident.engineers.map(engineer => engineer.id),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['incidents']});
      toast.success('Incident gewijzigd');
    },
  });
};

export const deleteIncident = () => {
  return useMutation({
    mutationFn: async (incident: Incident) => {
      if (await confirm({
        title: 'Incident verwijderen',
        text: 'Deze actie kan niet ongedaan gemaakt worden',
      })) {
        return client.delete(`incident/${incident.id}`);
      }

      return Promise.reject(new Error('Cancelled'));
    },
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['incidents']});
      toast.success('Incident verwijderd');
    },
  });
};
