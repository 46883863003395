import React, {useContext} from 'react';
import {ColumnDefinitionType, Namespace, ProjectResource, TableAction} from '../../types';
import {Paper, Box, Typography, Button, Stack} from '@mui/material';
import DataTable from '../../elements/DataTable/DataTable';
import {useNavigate, useParams} from "react-router-dom";
import Breadcrumb from "../App/Breadcrumb";
import {deleteProject, getProject, getProjectResources} from "../../api/project";
import {deleteNamespace, getNamespaces, synchronizeNamespace} from "../../api/namespace";
import {
  Add,
  AddBox,
  DeleteOutlined,
  InfoOutlined,
  LinkOffOutlined,
  OpenInNewOutlined,
  SyncOutlined
} from "@mui/icons-material";
import { UserContext } from '../../provider/UserProvider';
import {hasRole} from "../../services/role";
import {unlinkResourceFromProject} from "../../api/resource";
import Overview from './Link/Overview';

const typeMap = {
  sulu: 'Sulu',
  sylius: 'Sylius',
  akeneo: 'Akeneo',
  symfony: 'Symfony',
  shopware: 'Shopware',
  shopware_frontend: 'Shopware frontend',
  frontend: 'Frontend',
  monorepo: 'Monorepo',
} as any;

const Detail = () => {
  const {user} = useContext(UserContext);
  const {id} = useParams();
  const navigate = useNavigate();
  const {data: project} = id === undefined ? {data: undefined} : getProject(+(id));
  const {data: namespaces, isLoading: isLoadingNamespaces, isFetching: isFetchingNamespaces} = getNamespaces(id === undefined ? null : +id);
  const {data: resources, isLoading: isLoadingResources, isFetching: isFetchingResources} = id === undefined ? {data: [], isLoading: false, isFetching: false} : getProjectResources(+(id));
  const namespaceMutation = deleteNamespace();
  const projectMutation = deleteProject();
  const synchronizeMutation = synchronizeNamespace();
  const unlinkMutation = unlinkResourceFromProject();

  const namespaceColumns: Array<ColumnDefinitionType<Namespace>> = [
    { key: 'name', title: 'Naam', type: 'string', sortable: true },
    { key: 'branch', title: 'Git branch', type: 'string', sortable: true },
    { key: 'cluster.name', title: 'Cluster', type: 'string', sortable: true },
  ];

  const resourceColumns: Array<ColumnDefinitionType<ProjectResource>> = [
    { key: 'name', title: 'Naam', type: 'string', sortable: true },
    { key: 'type', title: 'Type', type: 'string', sortable: true },
  ];

  if (undefined === project) {
    return <></>;
  }

  const items = [
    { title: 'Projecten', url: '/project' },
    { title: project.domain, url: null },
  ];

  const namespaceToolbarActions: Array<TableAction<undefined>> = [
    {
      title: 'Namespace aanmaken',
      icon: <AddBox />,
      variant: 'primary',
      onClick: () => navigate(`/kubernetes/namespace/new?project=${project.id}&customer=${project.customer.id}`),
      type: 'button',
      condition: () => hasRole(user, ['ROLE_KUBERNETES_NAMESPACE_CREATE'])
    },
  ];

  const resourceToolbarActions: Array<TableAction<undefined>> = [
    { title: 'Resource aanmaken', icon: <AddBox />, variant: 'primary', onClick: () => void 0, type: 'menu_button', subActions: [
      {
        title: 'Database',
        icon: <Add />,
        onClick: () => navigate(`/database/new?project=${project.id}&customer=${project.customer.id}`),
        condition: () => hasRole(user, ['ROLE_DATABASE_CREATE']),
      },
      {
        title: 'Message queue user',
        icon: <Add />,
        onClick: () => navigate(`/mq/user/new?project=${project.id}&customer=${project.customer.id}`),
        condition: () => hasRole(user, ['ROLE_MESSAGE_QUEUE_USER_CREATE'])
      },
      {
        title: 'Elastic user',
        icon: <Add />,
        onClick: () => navigate(`/elastic/user/new?project=${project.id}`),
        condition: () => hasRole(user, ['ROLE_ELASTIC_USER_CREATE'])
      },
    ]},
  ];

  const actions: Array<TableAction<Namespace>> = [
    { title: 'Synchroniseren', icon: <SyncOutlined />, variant: 'primary', onClick: synchronizeMutation.mutateAsync, type: 'loading_button' },
    { title: 'Vault', icon: <OpenInNewOutlined />, variant: 'primary', onClick: (namespace) => window.open(`https://vault-03683.saas.true.nl/ui/vault/secrets/${namespace.cluster?.externalId}/show/${namespace.name}`, '_blank'), type: 'button' },
    { title: 'ArgoCD', icon: <OpenInNewOutlined />, variant: 'primary', onClick: (namespace) => window.open(`https://argocd-03683.saas.true.nl/applications/argocd-03683/${namespace.name}`, '_blank'), type: 'button' },
    {
      title: 'Verwijderen',
      icon: <DeleteOutlined />,
      variant: 'danger',
      onClick: namespaceMutation.mutate,
      type: 'button',
      condition: () => hasRole(user, ['ROLE_KUBERNETES_NAMESPACE_DELETE'])
    },
  ];

  const resourceActions: Array<TableAction<ProjectResource>> = [
    { title: 'Details', icon: <InfoOutlined />, variant: 'primary', onClick: (resource) => navigate(`/database/${resource.id}`), type: 'button', condition: (resource) => hasRole(user, ['ROLE_DATABASE_GET']) && resource.type === 'Database' },
    { title: 'Details', icon: <InfoOutlined />, variant: 'primary', onClick: (resource) => navigate(`/mq/user/${resource.id}/detail`), type: 'button', condition: (resource) => hasRole(user, ['ROLE_MESSAGE_QUEUE_USER_GET']) && resource.type === 'Message queue user' },
    { title: 'Details', icon: <InfoOutlined />, variant: 'primary', onClick: (resource) => navigate(`/elastic/user/${resource.id}/detail`), type: 'button', condition: (resource) => hasRole(user, ['ROLE_ELASTIC_USER_GET']) && resource.type === 'Elastic user' },
    { title: 'Ontkoppelen', icon: <LinkOffOutlined />, variant: 'danger', onClick: (resource) => unlinkMutation.mutate({project: null, resource: resource.id, resourceType: resource.type}), type: 'button', condition: (resource) => hasRole(user, ['ROLE_PROJECT_UPDATE']) },
  ];

  return (
    <>
      <Stack
        direction={{xs: 'column', sm: 'row'}}
        alignItems={{xs: 'left', sm: 'center'}}
        sx={{mb: 4, whiteSpace: 'nowrap'}}
        gap={2}
        flexWrap="wrap"
      >
        <Stack direction="column">
          <Typography variant="h5" sx={{fontWeight: 'bold'}}>{project.domain} - {typeMap[project.type]}</Typography>
          <Breadcrumb items={items} />
        </Stack>
        <Box sx={{flex: '1 0 auto', display: 'flex'}}/>
        <Button startIcon={<OpenInNewOutlined/>} variant="outlined" onClick={() => window.open(`https://gitlab.com/projects/${project.repository}`, '_blank')}>
          Repository
        </Button>
        {(namespaces.filter((namespace: Namespace) => namespace.project?.id === project.id).length === 0 && hasRole(user, ['ROLE_PROJECT_DELETE'])) && (
          <Button
            startIcon={<DeleteOutlined/>}
            variant="outlined"
            color="error"
            onClick={() => {
              projectMutation.mutateAsync(project).then(() => navigate('/project'));
            }}
          >
            Verwijderen
          </Button>
        )}
      </Stack>
      <Paper>
        <DataTable
          columns={namespaceColumns}
          rows={namespaces.filter((namespace: Namespace) => namespace.project?.id === project.id)}
          actions={actions}
          loading={isLoadingNamespaces || isFetchingNamespaces}
          toolbar={{
            title: 'Namespaces',
            actions: namespaceToolbarActions,
          }}
        />
      </Paper>
      <Paper sx={{mt: 4}}>
        <DataTable
          columns={resourceColumns}
          rows={resources}
          actions={resourceActions}
          loading={isLoadingResources || isFetchingResources}
          toolbar={{
            title: 'Resources',
            actions: resourceToolbarActions,
          }}
        />
      </Paper>
      <Paper sx={{mt: 4}}>
        <Overview customer={project.customer.id} project={project.id} />
      </Paper>
    </>
  );
};

export default Detail;
