export const getDescription = (log: any, t: any) => {
  if (log.scope === 'database_access') {
    if (log.email === (log.oldValues['user'] || '') || log.email === (log.newValues['user'] || '')) {
      if (log.action === 'create') {
        return t('audit:database_access_granted_text', {
          user: log.newValues['user'],
          database: log.newValues['database'],
        });
      } else if (log.action === 'delete') {
        return t('audit:database_access_revoked_text', {
          user: log.oldValues['user'],
          database: log.oldValues['database'],
        });
      } else if (log.action === 'update') {
        return t('audit:database_access_updated_text', {
          user: log.newValues['user'],
          database: log.newValues['database'],
        });
      }
    } else {
      if (log.action === 'create') {
        return t('audit:database_access_granted_text_different_user', {
          user: log.newValues['user'],
          database: log.newValues['database'],
          adminUser: log.email,
        });
      } else if (log.action === 'delete') {
        return t('audit:database_access_revoked_text_different_user', {
          user: log.oldValues['user'],
          database: log.oldValues['database'],
          adminUser: log.email,
        });
      } else if (log.action === 'update') {
        return t('audit:database_access_updated_text_different_user', {
          user: log.newValues['user'],
          database: log.newValues['database'],
          adminUser: log.email,
        });
      }
    }
  }

  return t('audit:text', {
    user: log.email,
    scope: t(`audit:${log.scope}`),
    identifier: log.identifier,
    action: t(`audit:${log.action}`),
  });
}
