import React from 'react';
import { ColumnDefinitionType, TableAction, User } from '../../types';
import { Paper } from '@mui/material';
import DataTable from '../../elements/DataTable/DataTable';
import {AddBox, Delete, Edit, ZoomInOutlined} from '@mui/icons-material';
import {deleteUser, getUsers} from "../../api/user";
import {useNavigate} from "react-router-dom";

const columns: Array<ColumnDefinitionType<User>> = [
  { key: 'name', title: 'Naam', type: 'string', sortable: true, filter: true },
  { key: 'email', title: 'Email', type: 'string', sortable: true, filter: true },
  { key: 'customer.name', title: 'Klant', type: 'string', sortable: true, filter: true },
];

const UserOverview = () => {
  const {isLoading, data, isFetching} = getUsers();
  const navigate = useNavigate();
  const mutation = deleteUser();

  const actions: Array<TableAction<User>> = [
    { title: 'Bekijken', icon: <ZoomInOutlined/>, variant: 'primary', onClick: (user) => navigate(`/user/${user.id}/detail`), type: 'button' },
    { title: 'Wijzigen', icon: <Edit />, type: 'button', variant: 'primary', onClick: (user) => navigate(`/user/${user.id}`) },
    { title: 'Verwijderen', icon: <Delete />, type: 'button', variant: 'primary', onClick: mutation.mutate },
  ];

  const toolbarActions: Array<TableAction<undefined>> = [
    { title: 'Gebruiker aanmaken', icon: <AddBox />, type: 'button', variant: 'primary', onClick: () => navigate('/user/new') },
  ];

  return (
    <Paper>
      <DataTable
        columns={columns}
        rows={data}
        actions={actions}
        loading={isLoading || isFetching}
        toolbar={{
          title: 'Gebruikers',
          actions: toolbarActions,
          backButton: true,
        }}
      />
    </Paper>
  );
};

export default UserOverview;
