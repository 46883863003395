import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import {Customer, Project} from '../../types';
import {linkResourceToProject} from "../../api/resource";
import {FormProvider, useForm} from "react-hook-form";
import {joiResolver} from "@hookform/resolvers/joi";
import validationSchema from "../../services/validationSchema";
import Joi from "joi";
import {LoadingButton} from "@mui/lab";
import ProjectSelect from "../../elements/Form/ProjectSelect";

interface Props {
  open: boolean;
  onClose: () => void;
  resourceId: number;
  resourceType: string;
  customer?: Customer;
}

const ProjectLinkForm = ({ open, onClose, resourceId, resourceType, customer}: Props) => {
  const mutation = linkResourceToProject();

  const schema = validationSchema.object({
    project: Joi.object().required(),
  }).unknown();

  const form = useForm({
    defaultValues: {project: null},
    resolver: joiResolver(schema),
    mode: 'onBlur',
  });

  const onSubmit = (values: {project: Project|null}) => {
    return mutation.mutateAsync({
      project: values.project?.id || 0,
      resource: resourceId,
      resourceType: resourceType,
    }).then(() => onClose()).catch((error) => {
      Object.keys(error.response.data.errors || {}).forEach(key => {
        form.setError(key as keyof {project: Project|null}, {type: 'custom', message: error.response.data.errors[key]})
      })
    });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <DialogTitle>Resource koppelen aan project</DialogTitle>
          <DialogContent sx={{overflow: 'visible', minWidth: '400px'}}>
            <ProjectSelect name="project" label="Project" customer={customer}/>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color="secondary" disabled={form.formState.isSubmitting}>Annuleren</Button>
            <LoadingButton type="submit" color="primary" variant="contained" loading={form.formState.isSubmitting}>
              Koppelen
            </LoadingButton>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};

export default ProjectLinkForm;
