import {Formik, FormikHelpers} from 'formik';
import React from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from '@mui/material';
import * as Yup from 'yup';
import {DatabaseServer} from '../../types';
import client from '../../services/client';
import {toast} from "react-toastify";

const schema = Yup.object().shape({
  name: Yup.string().required('Naam mag niet leeg zijn'),
  server: Yup.object().typeError('Er moet een database server gekozen worden'),
  customer: Yup.object().typeError('Er moet een klant gekozen worden'),
});

interface Props {
  databaseServer: DatabaseServer|null;
  open: boolean;
  onClose: () => void;
}

const Form = ({ databaseServer, open, onClose }: Props) => {
  if (null === databaseServer) {
    return <></>;
  }

  const onSubmit = (databaseServer: DatabaseServer, actions: FormikHelpers<DatabaseServer>) => {
    if (databaseServer.id === 0) {
      client
        .post('database/server', databaseServer)
        .then(() => {
          toast.success('Database server aangemaakt');
          onClose();
        })
        .finally(() => actions.setSubmitting(false));
    } else {
      client
        .put(`database/server/${databaseServer.id}`, databaseServer)
        .then(() => {
          toast.success('Database server gewijzigd');
          onClose();
        })
        .finally(() => actions.setSubmitting(false));
    }
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <Formik
        initialValues={databaseServer}
        validationSchema={schema}
        onSubmit={onSubmit}
      >
        {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isValid,
            isSubmitting,
          }) => (
          <form onSubmit={handleSubmit}>
            <DialogTitle>{values.id === 0 ? 'Nieuwe database server aanmaken' : 'Database server wijzigen'}</DialogTitle>
            <DialogContent sx={{overflow: 'visible', minWidth: '400px'}}>
              <TextField
                name="name"
                label="Naam"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.name && errors.name)}
                helperText={errors.name && touched.name && errors.name}
                margin="normal"
                fullWidth
              />
              <TextField
                name="host"
                label="Host"
                value={values.host}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.host && errors.host)}
                helperText={errors.host && touched.host && errors.host}
                margin="normal"
                fullWidth
              />
              <TextField
                name="type"
                label="Type"
                value={values.type}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.type && errors.type)}
                helperText={errors.type && touched.type && errors.type}
                margin="normal"
                fullWidth
              />
              <TextField
                name="username"
                label="Gebruikersnaam"
                value={values.username}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.username && errors.username)}
                helperText={errors.username && touched.username && errors.username}
                margin="normal"
                fullWidth
              />
              <TextField
                name="password"
                label="Wachtwoord"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.password && errors.password)}
                helperText={errors.password && touched.password && errors.password}
                margin="normal"
                type="password"
                fullWidth
              />
              <TextField
                name="privateIp"
                label="Private IP"
                value={values.privateIp}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.privateIp && errors.privateIp)}
                helperText={errors.privateIp && touched.privateIp && errors.privateIp}
                margin="normal"
                fullWidth
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} color="primary">
                Annuleren
              </Button>
              <Button type="submit" color="primary" disabled={!isValid || isSubmitting}>
                {values.id === 0 ? 'Aanmaken' : 'Wijzigen'}
                {isSubmitting && <CircularProgress size={20} style={{ marginLeft: '8px' }} />}
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};

export default Form;
