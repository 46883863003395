import {useMutation, useQuery} from "react-query";
import client from "../services/client";
import confirm from "../elements/Confirmation/Confirmation";
import queryClient from "../services/queryClient";
import {toast} from "react-toastify";
import {ProjectLink} from '../types';

export const getLink = (id: number) => {
  return useQuery({
    queryKey: ['projectLink', id],
    queryFn: () => client.get(`link/${id}`).then(response => response.data),
    enabled: id !== 0,
    initialData: {
      id: 0,
      url: '',
      description: '',
      project: null,
      customer: null,
      public: false,
    },
  });
};

export const createLink = () => {
  return useMutation({
    mutationFn: async (link: ProjectLink) => {
      return client.post('link', {
        ...link,
        customer: link.customer?.id,
        project: link.project?.id,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['projectLinks']});
      toast.success('Link aangemaakt');
    },
  });
};

export const updateLink = () => {
  return useMutation({
    mutationFn: async (link: ProjectLink) => {
      return client.put(`link/${link.id}`, link);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['projectLinks']});
      toast.success('Link gewijzigd');
    },
  });
};

export const deleteLink = () => {
  return useMutation({
    mutationFn: async (link: ProjectLink) => {
      if (await confirm({
        title: 'Link verwijderen',
        text: 'Deze actie kan niet ongedaan gemaakt worden',
      })) {
        return client.delete(`link/${link.id}`);
      }

      return Promise.reject(new Error('Cancelled'));
    },
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['projectLinks']});
      toast.success('Link verwijderd');
    },
  });
};
