import React from 'react';
import AppBar from '@mui/material/AppBar';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import UserAvatar from '../../elements/Icon/UserAvatar';
import {Box, Stack} from '@mui/material';

interface Props {
  onDrawerToggle: () => void;
}

const Header = ({onDrawerToggle} : Props) => {
  return (
    <>
      <Hidden smUp>
        <AppBar position="sticky" elevation={0} sx={{backgroundColor: '#fff'}}>
          <Toolbar>
            <Grid container spacing={1} alignItems="center">
              <Grid item>
                <IconButton color="primary" onClick={onDrawerToggle} sx={{ml: -1}}>
                  <MenuIcon />
                </IconButton>
              </Grid>
              <Grid item xs />
              <Grid item xs>
                <Box sx={{pt: 1}}>
                  {/* <img src={logo} alt="Webstores logo" height={30}/> */}
                </Box>
              </Grid>
              <Grid>
                <UserAvatar/>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </Hidden>
      <Hidden smDown>
        <AppBar position="sticky" sx={{backgroundColor: '#fff', height: 80, display: 'flex', justifyContent: 'center'}}>
          <Toolbar>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs>
                <Box sx={{pt: 1, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                  {/*<img src={logo} alt="Webstores logo" height={30}/>*/}
                </Box>
              </Grid>
              <Grid item>
                <Stack direction="row" justifyContent="center">
                  <UserAvatar/>
                </Stack>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </Hidden>
    </>
  );
};

export default Header;
