import React, {useEffect} from 'react';
import {Namespace, ColumnDefinitionType, Cluster, TableAction} from "../../../types";
import {Box, Card, CardContent, Grid, Paper, Typography} from "@mui/material";
import DataTable from "../../../elements/DataTable/DataTable";
import client from "../../../services/client";
import {useNavigate, useParams} from "react-router-dom";
import Kubernetes from "../../../elements/Icon/Kubernetes";
import Dollar from "../../../elements/Icon/Dollar";
import {red} from "@mui/material/colors";
import Breadcrumb from "../../App/Breadcrumb";
import {AddBox, VisibilityOutlined} from "@mui/icons-material";
import CustomerLinkButton from "../../CustomerResource/CustomerLinkButton";
import ProjectLinkButton from "../../ProjectResource/ProjectLinkButton";

const Detail = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [cluster, setCluster] = React.useState<Cluster|null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);

  const loadCluster = () => {
    setLoading(true);
    client
      .get(`kubernetes/cluster/${params.id}`)
      .then((response: any) => setCluster(response.data))
      .finally(() => setLoading(false));
  }

  useEffect(loadCluster, []);

  if (cluster === null) {
    return <></>;
  }

  const columns: Array<ColumnDefinitionType<Namespace>> = [
    { key: 'name', title: 'Naam', type: 'string', sortable: true },
    { key: 'cost', title: 'Kosten', type: 'price', sortable: true },
    { key: 'customer.name', title: 'Klant', type: 'custom', sortable: true, content: (row) => {
      if (row.customer !== null) {
        return row.customer.name;
      }

      return <CustomerLinkButton resourceId={row.id} resourceType="Kubernetes namespace"/>
    }},
    { key: 'project.domain', title: 'Project', type: 'custom', sortable: true, content: (row) => {
      if (row.project !== null) {
        return row.project.domain;
      }

      return <ProjectLinkButton resourceId={row.id} resourceType="Kubernetes namespace"/>
    }},
  ];

  const items = [
    { title: 'Kubernetes', url: null },
    { title: 'Clusters', url: '/kubernetes/cluster' },
    { title: cluster.name, url: null },
  ]

  const actions: Array<TableAction<Namespace>> = [
    { title: 'Bekijken', icon: <VisibilityOutlined />, variant: 'primary', onClick: (namespace) => navigate(`/kubernetes/namespace/${namespace.id}`), type: 'button' },
  ];

  const toolbarActions: Array<TableAction<undefined>> = [
    { title: 'Namespace aanmaken', icon: <AddBox />, variant: 'primary', onClick: () => navigate(`/kubernetes/namespace/new?cluster=${cluster.id}`), type: 'button' },
  ];

  return (
    <>
      <Box sx={{mb: 5}}>
        <Typography variant="h5" sx={{fontWeight: 'bold'}}>{cluster.name} cluster</Typography>
        <Breadcrumb items={items} />
      </Box>
      <Grid container spacing={4} sx={{mb: 4}}>
        <Grid item xs={4}>
          <Card>
            <CardContent sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', p: 3}}>
              <Box>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                  Aantal namespaces
                </Typography>
                <Typography variant="h5" component="div">
                  {cluster.namespaces.length}
                </Typography>
              </Box>
              <Box>
                <Kubernetes/>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card>
            <CardContent sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', p: 3}}>
              <Box>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                  Namespace kosten (Kubecost)
                </Typography>
                <Typography variant="h5" component="div">
                  € 0
                </Typography>
              </Box>
              <Box>
                <Dollar color={red[200]}/>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card>
            <CardContent sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', p: 3}}>
              <Box>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                  Kosten op factuur
                </Typography>
                <Typography variant="h5" component="div">
                  € {cluster.cost.toFixed(2).replace('.', ',')}
                </Typography>
              </Box>
              <Box>
                <Dollar color={red[200]}/>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Paper>
        <DataTable
          columns={columns}
          rows={cluster.namespaces.filter(n => n.name !== '__idle__')}
          actions={actions}
          loading={loading}
          toolbar={{
            title: 'Namespaces',
            actions: toolbarActions,
          }}
        />
      </Paper>
    </>
  );
}

export default Detail;
