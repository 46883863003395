import React from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import {Autocomplete as MuiAutocomplete, AutocompleteProps, TextField} from '@mui/material';
import {getIn} from '../../services/form';

type Props = Omit<AutocompleteProps<any, any, any, any>, 'name'|'renderInput'> & {
  name: string;
  label: string;
}

const Autocomplete = ({name, label, ...props}: Props) => {
  const {control, formState: {errors, isSubmitting}} = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({field: {onChange, ...field}}) => (
        <MuiAutocomplete
          {...field}
          {...props}
          disabled={isSubmitting || props.disabled}
          onChange={(event, value) => onChange(value)}
          isOptionEqualToValue={(option, value) => {
            if (typeof option === 'object') {
              return option.id === value.id
            }

            return option === value;
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              error={Boolean(getIn(errors, name))}
              helperText={getIn(errors, name)?.message}
            />
          )}
        />
      )}
    />
  );
};

export default Autocomplete;
