import {Button, Grid, InputAdornment, Paper, Typography} from '@mui/material';
import React, {useEffect} from 'react';
import {useNavigate, useSearchParams} from "react-router-dom";
import {joiResolver} from "@hookform/resolvers/joi";
import Joi from "joi";
import {FormProvider, useForm} from "react-hook-form";
import TextField from '../../elements/Form/TextField';
import { LoadingButton } from '@mui/lab';
import validationSchema from "../../services/validationSchema";
import {SslCertificate} from "../../types";
import CustomerSelect from "../../elements/Form/CustomerSelect";
import {createCertificate, getCertificate} from '../../api/sslCertificate';
import Select from '../../elements/Form/Select';
import SubscriptionSelect from '../../elements/Form/SubscriptionSelect';
import DomainField from '../../elements/Form/DomainField';

const products = [
  {value: 41, label: 'Enkel domein'},
  {value: 40, label: 'Multi domein'},
  {value: 42, label: 'Wildcard'},
];

const Form = () => {
  const navigate = useNavigate();
  const mutation = createCertificate();
  const [searchParams] = useSearchParams();
  const {data: certificate} = getCertificate(+(searchParams.get('from') || 0), false);

  const schema = validationSchema.object({
    commonName: Joi.string()
      .required()
      .invalid(Joi.in('domains'))
      .when('product', {
        is: 41,
        then: Joi.string().regex(/^(?!www\.).*/).message('De common name mag niet met `www.` beginnen'),
        otherwise: Joi.string()
      })
      .when('product', {
        is: 42,
        then: Joi.string().regex(/^\*.*/).message('De common name moet met `*.` beginnen'),
        otherwise: Joi.string()
      })
      .messages({'any.invalid': 'De common name mag niet gelijk zijn aan een van de domeinen'}),
    domains: Joi.array().required().items(
      Joi.string().domain().custom((value, helpers) => {
        if (value === helpers.state.ancestors[1].commonName) {
          return helpers.error('any.invalid');
        }

        return value;
      })
    ).messages({
      'string.domain': 'Een of meerdere domeinen zijn ongeldig',
      'any.invalid': 'Domeinen mogen niet gelijk zijn aan de common name'
    }),
    customer: Joi.object().required(),
    subscription: Joi.object().required(),
    product: Joi.number().required(),
    setupCosts: Joi.number().required(),
    subscriptionCosts: Joi.number().required(),
  }).unknown();

  const form = useForm<SslCertificate>({
    defaultValues: {
      commonName: '',
      domains: [],
      customer: null,
      subscription: null,
      product: 41,
      setupCosts: 0,
      subscriptionCosts: 0,
    },
    resolver: joiResolver(schema),
    mode: 'onBlur',
  });

  const onSubmit = (values: SslCertificate) => {
    return mutation.mutateAsync(values).then(() => {
      navigate(-1);
    }).catch((error) => {
      Object.keys(error.response.data.errors || {}).forEach(key => {
        form.setError(key as keyof SslCertificate, {type: 'custom', message: error.response.data.errors[key]})
      })
    });
  }

  useEffect(() => {
    if (certificate.id === 0) {
      return;
    }

    let product = 41;
    if (certificate.commonName.startsWith('*.')) {
      product = 42;
    }
    if (certificate.domains.length > 2) {
      product = 40;
    }

    form.reset({
      commonName: certificate.commonName,
      domains: certificate.domains,
      customer: certificate.customer,
      subscription: null,
      product: product,
      setupCosts: 0,
      subscriptionCosts: 0,
    });
  }, [certificate]);

  useEffect(() => {
    if (form.watch('commonName') !== '') {
      form.trigger('commonName');
    }

    if (form.watch('product') === 41 && form.watch('commonName') !== '') {
      form.setValue('domains', [`www.${form.watch('commonName')}`]);
    }
  }, [form.watch('product')]);

  useEffect(() => {
    if (form.watch('product') === 41) {
      form.setValue('setupCosts', 150);
      form.setValue('subscriptionCosts', 40);
    } else if (form.watch('product') === 40) {
      form.setValue('setupCosts', 180);
      form.setValue('subscriptionCosts', form.watch('domains').length > 3 ? 150 + 75 * (form.watch('domains').length - 3) : 150);
    } else {
      form.setValue('setupCosts', 180);
      form.setValue('subscriptionCosts', 200);
    }
  }, [form.watch('product'), form.watch('domains')]);

  useEffect(() => {
    if (form.watch('product') === 41 && form.watch('commonName') !== '') {
      form.setValue('domains', [`www.${form.watch('commonName')}`]);
    }
  }, [form.watch('commonName')]);

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} sm={12} md={12} lg={6}>
        <Paper sx={{p: 6, maxWidth: '600px', margin: '0 auto'}}>
          <Typography variant="h6" fontWeight="bold">SSL certificaat aanmaken</Typography>
          <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <Typography variant="subtitle1" sx={{mt: 4}}>Certificaat details</Typography>
              <TextField name="commonName" label="Common name" />
              <Select name="product" options={products} label="Type certificaat" />
              <DomainField name="domains" label="Domeinen" disabled={form.watch('product') === 41} />
              <Typography variant="subtitle1" sx={{mt: 4}}>Facturatie</Typography>
              <CustomerSelect name="customer" label="Klant"/>
              <SubscriptionSelect name="subscription" label="Simplicate project"/>
              <TextField
                name="setupCosts"
                label="Installatie kosten"
                InputProps={{
                  startAdornment: <InputAdornment position="start">€</InputAdornment>,
                }}
              />
              <TextField
                name="subscriptionCosts"
                label="Abonnement kosten"
                InputProps={{
                  startAdornment: <InputAdornment position="start">€</InputAdornment>,
                }}
              />
              <LoadingButton sx={{mt: 2}} type="submit" color="primary" variant="contained" loading={form.formState.isSubmitting}>
                Aanmaken
              </LoadingButton>
              <Button onClick={() => navigate(-1)} sx={{mt: 2, ml: 1}} color="secondary" disabled={form.formState.isSubmitting}>Annuleren</Button>
            </form>
          </FormProvider>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default Form;
