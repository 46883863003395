import {Button, Grid, Paper, Typography} from '@mui/material';
import {FormProvider, useForm} from 'react-hook-form';
import {WebservicesBilling} from '../../types';
import CustomerSelect from '../../elements/Form/CustomerSelect';
import {LoadingButton} from '@mui/lab';
import React, {useEffect} from 'react';
import {joiResolver} from '@hookform/resolvers/joi';
import {useNavigate, useParams} from 'react-router-dom';
import Select from '../../elements/Form/Select';
import validationSchema from '../../services/validationSchema';
import Joi from 'joi';
import {billWebservicesUser, getWebservicesUser} from '../../api/webservicesUser';
import SubscriptionSelect from '../../elements/Form/SubscriptionSelect';
import moment from 'moment';

const years = [
  {value: 2024, label: '2024'},
  {value: 2023, label: '2023'},
];

const quarters = [
  {value: 1, label: 'Q1'},
  {value: 2, label: 'Q2'},
  {value: 3, label: 'Q3'},
  {value: 4, label: 'Q4'},
];

const Form = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const mutation = billWebservicesUser();
  const {data: user} = getWebservicesUser(+(id || 0), 'quarterly');

  const schema = validationSchema.object({
    user: Joi.object().required(),
    year: Joi.number().required(),
    quarter: Joi.number().required(),
    customer: Joi.object().required(),
    subscription: Joi.object().required(),
  }).unknown();

  const form = useForm<WebservicesBilling>({
    defaultValues: {
      user: user,
      customer: user.customer,
      subscription: null,
      quarter: moment().subtract(1, 'quarter').quarter(),
      year: moment().subtract(1, 'quarter').year(),
    },
    resolver: joiResolver(schema),
    mode: 'onBlur',
  });

  const onSubmit = (values: WebservicesBilling) => {
    return mutation.mutateAsync(values).then(() => {
      navigate(-1);
    }).catch((error) => {
      Object.keys(error.response.data.errors || {}).forEach(key => {
        form.setError(key as keyof WebservicesBilling, {type: 'custom', message: error.response.data.errors[key]})
      })
    });
  }

  useEffect(() => {
    if (id !== undefined) {
      form.reset({
        user: user,
        customer: user.customer,
        subscription: null,
        quarter: moment().subtract(1, 'quarter').quarter(),
        year: moment().subtract(1, 'quarter').year(),
      });
    }
  }, [user])

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} sm={12} md={12} lg={6}>
        <Paper sx={{p: 6, maxWidth: '600px', margin: '0 auto'}}>
          <Typography variant="h6" fontWeight="bold">Gebruiker {user.name} factureren</Typography>
          <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <CustomerSelect name="customer" label="Klant"/>
              <SubscriptionSelect name="subscription" label="Simplicate project" />
              <Select name="year" label="Jaar" options={years} />
              <Select name="quarter" label="Kwartaal" options={quarters} />
              <LoadingButton sx={{mt: 2}} type="submit" color="primary" variant="contained" loading={form.formState.isSubmitting}>
                Factureren
              </LoadingButton>
              <Button onClick={() => navigate(-1)} sx={{mt: 2, ml: 1}} color="secondary" disabled={form.formState.isSubmitting}>Annuleren</Button>
            </form>
          </FormProvider>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default Form;
