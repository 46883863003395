// @ts-nocheck
import React, {useEffect} from 'react';
import Breadcrumb from '../App/Breadcrumb';
import {
  Alert,
  AlertTitle,
  Avatar,
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import {AuditLog, BreadcrumbItem} from '../../types';
import {useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import client from '../../services/client';
import {AxiosResponse} from 'axios';
import Typography from '@mui/material/Typography';
import moment from 'moment/moment';
import {orange} from '@mui/material/colors';
import {FiberManualRecord} from '@mui/icons-material';
import {getDescription} from "../../services/audit";

const Detail = () => {
  const params = useParams();
  const {t} = useTranslation();
  const [log, setLog] = React.useState<AuditLog>({
    action: '',
    datetime: '',
    email: '',
    id: '',
    identifier: '',
    scope: '',
    oldValues: [],
    newValues: [],
  });

  const toUpperCase = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const camelCaseToSnakeCase = (string: string) => {
    return string.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase();
  };

  const values = () => {
    if (Object.keys(log.oldValues).length > 0) {
      return log.oldValues;
    }

    if (Object.keys(log.newValues).length > 0) {
      return log.newValues;
    }

    return [];
  }

  const icon = (log: AuditLog, key: string) => {
    if (log.oldValues.length === 0) {
      // return <FiberManualRecord fontSize="small" sx={{color: green[500]}} />;
      return <></>;
    }

    if (log.newValues.length === 0) {
      // return <FiberManualRecord fontSize="small" sx={{color: red[500]}} />;
      return <></>;
    }

    if ((log.oldValues[key] || '') !== (log.newValues[key] || '')) {
      return <FiberManualRecord fontSize="small" sx={{color: orange[500]}} />;
    }

    return <></>;
  };

  const breadcrumbs: Array<BreadcrumbItem> = [
    {title: t('audit:activities'), url: '/audit'},
    {title: t('audit:activity'), url: null},
  ];

  useEffect(() => {
    client
      .get(`audit/${params.id}`)
      .then((response: AxiosResponse) => setLog(response.data));
  }, []);

  if (log.id === '') {
    return <></>;
  }

  return (
    <>
      <Box sx={{mb: 5}}>
        <Typography variant="h5" sx={{fontWeight: 'bold'}}>{t('audit:activity')}</Typography>
        <Breadcrumb items={breadcrumbs} />
      </Box>
      <Paper sx={{p: 6}}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Box sx={{display: 'flex', alignItems: 'center'}}>
              <Avatar sx={{width: 48, height: 48}}/>
              <Typography sx={{pl: 2, lineHeight: 1.7}}>
                <strong>Wie</strong><br />
                {log.email}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Typography sx={{lineHeight: 1.7}}>
              <strong>Tijd</strong><br />
              {moment(log.datetime).format('DD-MM-YYYY HH:mm:ss')}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography sx={{lineHeight: 1.7}}>
              <strong>Wat</strong><br />
              {toUpperCase(t(`audit:${log.scope}`) || '')}
            </Typography>
          </Grid>
        </Grid>

        <Alert icon={false} severity="info" sx={{my: 5, p: 3, backgroundColor: 'rgba(39, 110, 241, 0.2)', color: 'black'}}>
          <AlertTitle sx={{fontSize: '19px', mb: 2}}>{t('audit:description')}</AlertTitle>
          <span dangerouslySetInnerHTML={{__html: getDescription(log, t)}}/>
        </Alert>

        <Table>
          <TableHead>
            <TableCell sx={{fontWeight: 'bold'}}>Veld</TableCell>
            {log.oldValues.length !== 0 && (
              <TableCell sx={{fontWeight: 'bold'}}>{log.newValues.length !== 0 ? 'Oude situatie' : 'Waardes'}</TableCell>
            )}
            {log.newValues.length !== 0 && (
              <TableCell sx={{fontWeight: 'bold'}}>{log.oldValues.length !== 0 ? 'Gewijzigd naar' : 'Waardes'}</TableCell>
            )}
          </TableHead>
          <TableBody>
            {Object.keys(values()).map((key: string) => (
              <TableRow key={key}>
                <TableCell sx={{fontWeight: 'bold', py: 2}}>
                  <Box sx={{display: 'flex', alignItems: 'center'}}>
                    {icon(log, key)}
                    <Typography sx={{pl: 0.5}}>
                      {t(`${log.scope}:${camelCaseToSnakeCase(key)}`)}
                    </Typography>
                  </Box>
                </TableCell>
                {log.oldValues.length !== 0 && (
                  <TableCell>
                    {log.oldValues[key] || ''}
                  </TableCell>
                )}
                {log.newValues.length !== 0 && (
                  <TableCell>
                    {log.newValues[key] || ''}
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </>
  );
};

export default Detail;
