import Button from "@mui/material/Button";
import {LinkOutlined} from "@mui/icons-material";
import React, {useState} from 'react';
import ProjectLinkForm from "./ProjectLinkForm";
import { Customer } from "../../types";

interface Props {
  resourceId: number;
  resourceType: string;
  customer?: Customer;
}

const ProjectLinkButton = (props: Props) => {
  const [showForm, setShowForm] = useState<boolean>(false);

  return (
    <>
      <Button sx={{p: 1}} startIcon={<LinkOutlined/>} color="primary" onClick={() => setShowForm(true)}>Koppelen</Button>
      <ProjectLinkForm open={showForm} onClose={() => setShowForm(false)} {...props} />
    </>
  )
}

export default ProjectLinkButton;
