import {Box, Button, Checkbox, FormControlLabel, Grid, Paper, Typography} from '@mui/material';
import React, {useEffect} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {joiResolver} from "@hookform/resolvers/joi";
import Joi from "joi";
import {Controller, FormProvider, useForm} from "react-hook-form";
import TextField from '../../elements/Form/TextField';
import { LoadingButton } from '@mui/lab';
import validationSchema from "../../services/validationSchema";
import {createLink, getLink, updateLink} from '../../api/link';
import {ProjectLink} from '../../types';
import CustomerSelect from '../../elements/Form/CustomerSelect';
import ProjectSelect from '../../elements/Form/ProjectSelect';

const Form = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const {data: link} = getLink(+(id || 0));
  const mutation = id === undefined ? createLink() : updateLink();

  const schema = validationSchema.object({
    url: Joi.string().required(),
    description: Joi.string().required(),
    customer: Joi.object().required(),
    project: Joi.object().allow(null),
    public: Joi.boolean().required()
  }).unknown();

  const form = useForm<ProjectLink>({
    defaultValues: link,
    resolver: joiResolver(schema),
    mode: 'onBlur',
  });

  const onSubmit = (values: ProjectLink) => {
    return mutation.mutateAsync(values).then(() => {
      navigate(-1);
    }).catch((error) => {
      Object.keys(error.response.data.errors || {}).forEach(key => {
        form.setError(key as keyof ProjectLink, {type: 'custom', message: error.response.data.errors[key]})
      })
    });
  }

  useEffect(() => {
    if (id !== undefined) {
      form.reset(link);
    }
  }, [link])

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} sm={12} md={12} lg={6}>
        <Paper sx={{p: 6, maxWidth: '600px', margin: '0 auto'}}>
          <Typography variant="h6" fontWeight="bold">Link {id ? 'wijzigen' : 'aanmaken'}</Typography>
          <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <TextField name="url" label="URL" />
              <TextField name="description" label="Beschrijving" />
              {id === undefined && (
                <>
                  <CustomerSelect name="customer" label="Klant"/>
                  <ProjectSelect name="project" label="Project" />
                </>
              )}
              <Box>
                <Controller
                  name="public"
                  control={form.control}
                  render={({field}) => (
                    <FormControlLabel
                      control={<Checkbox {...field} name="public" checked={field.value} />}
                      label="Link is voor klanten zichtbaar"
                    />
                  )}
                />
              </Box>
              <LoadingButton sx={{mt: 2}} type="submit" color="primary" variant="contained" loading={form.formState.isSubmitting}>
                {id ? 'Wijzigen' : 'Aanmaken'}
              </LoadingButton>
              <Button onClick={() => navigate(-1)} sx={{mt: 2, ml: 1}} color="secondary" disabled={form.formState.isSubmitting}>Annuleren</Button>
            </form>
          </FormProvider>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default Form;
