import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './components/App/App';
import reportWebVitals from './reportWebVitals';
import {Route, Routes, unstable_HistoryRouter as Router} from 'react-router-dom';
import Login from './components/Login/Login';
import {ToastContainer} from 'react-toastify';
import history from './services/history';
import './services/i18n';
import './styles/index.css';

const params = new URLSearchParams(window.location.search);

if (params.get('token') !== null) {
  localStorage.setItem('token', String(params.get('token')));

  window.history.pushState('', '', '/');
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <Router history={history}>
      <Routes>
        <Route path="/login" element={<Login/>}/>
        <Route path="/*" element={<App/>}/>
      </Routes>
    </Router>
    <ToastContainer position="bottom-center" autoClose={3000} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
