import React, {Dispatch, SetStateAction, useState} from 'react';
import {Box, Button, FormControlLabel, FormGroup, Popover, Switch, Typography} from '@mui/material';
import {Visibility} from '@mui/icons-material';
import {ColumnDefinitionType} from '../../types';

interface Props<T> {
  title: string;
  columns: Array<ColumnDefinitionType<T>>;
  setColumns: Dispatch<SetStateAction<ColumnDefinitionType<T>[]>>;
}

const ColumnEditor = <T extends object>({title, columns, setColumns}: Props<T>): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleToggle = (e: any, key: any) => {
    const status: any = {};
    setColumns(columns.map(column => {
      if (column.key === key) {
        column.hidden = !e.target.checked;
      }

      status[column.key] = column.hidden;

      return column;
    }));

    localStorage.setItem(title, JSON.stringify(status));
  };

  return (
    <>
      <Button variant="contained" onClick={handleClick} startIcon={<Visibility/>} color="primary">
        Weergave aanpassen
      </Button>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
      >
        <Box sx={{p: 2}}>
          <Typography sx={{pb: 2}}>Kolommen tonen/verbergen</Typography>
          <FormGroup>
            {columns
              .filter(column => typeof column.condition === 'undefined' || column.condition())
              .map((column, i) => (
                <FormControlLabel control={<Switch checked={!column.hidden} onClick={(e) => handleToggle(e, column.key)} />} key={i} label={column.title} />
              ))}
          </FormGroup>
        </Box>
      </Popover>
    </>
  );
};

export default ColumnEditor;
