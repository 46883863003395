import React, {useContext, useEffect} from 'react';
import {Box, Divider, Grid, Paper, TextField, Typography} from "@mui/material";
import {User} from "../../types";
import {Formik} from "formik";
import {UserContext} from "../../provider/UserProvider";
import * as Yup from "yup";
import {LoadingButton} from "@mui/lab";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";
import {CheckCircle, Error, Warning} from "@mui/icons-material";
import client from "../../services/client";
import {hasRole} from "../../services/role";

const Profile = () => {
  const {user, setUser} = useContext(UserContext);
  const navigate = useNavigate();

  const schema = Yup.object().shape({
    name: Yup.string().required('Dit veld mag niet leeg zijn'),
    email: Yup.string().required('Dit veld mag niet leeg zijn'),
  });

  useEffect(() => {
    client.get('user/current').then((response: any) => setUser(response.data));
  }, []);

  const onSubmit = (values: User) => {

  }

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <Paper sx={{p: 6}}>
          <Formik initialValues={user} validationSchema={schema} onSubmit={onSubmit} enableReinitialize={true}>
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isValid,
                isSubmitting,
              }) => (
              <form onSubmit={handleSubmit}>
                <Typography variant="h6" fontWeight="bold">Profiel</Typography>
                <TextField
                  name="name"
                  label="Naam"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched.name && errors.name)}
                  helperText={errors.name && touched.name && errors.name}
                  disabled={values.internal}
                  fullWidth
                />
                <TextField
                  name="email"
                  label="E-mailadres"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched.email && errors.email)}
                  helperText={errors.email && touched.email && errors.email}
                  disabled={values.internal}
                  fullWidth
                />

                {hasRole(user, ['ROLE_DATABASE_ACCESS_GRANT', 'ROLE_DATABASE_ACCESS_GRANT_REVOKE']) && (
                  <>
                    <Divider sx={{mt: 3}} />
                    <TextField label="Database gebruikersnaam" disabled fullWidth value={values.email.replace('@friday.nl', '').replace('.', '')} />
                    <Box sx={{display: 'flex', alignItems: 'center', my: 3}}>
                      <Button
                        variant="contained"
                        onClick={() => navigate('/profile/database')}
                      >
                        Database wachtwoord wijzigen
                      </Button>
                      {values.database.expires > 30 && (
                        <>
                          <CheckCircle sx={{ml: 2, mr: 0.5}} color="success"/>
                          <Typography>Huidig wachtwoord verloopt over {values.database.expires} dagen</Typography>
                        </>
                      )}
                      {(values.database.expires <= 30 && values.database.expires > 7) && (
                        <>
                          <Warning sx={{ml: 2, mr: 0.5}} color="warning"/>
                          <Typography>Huidig wachtwoord verloopt over {values.database.expires} dagen</Typography>
                        </>
                      )}
                      {(values.database.expires <= 7 && values.database.expires >= 0 && !values.database.expired) && (
                        <>
                          <Error sx={{ml: 2, mr: 0.5}} color="error"/>
                          <Typography>Huidig wachtwoord verloopt over {values.database.expires} dagen</Typography>
                        </>
                      )}
                      {(values.database.expired) && (
                        <>
                          <Error sx={{ml: 2, mr: 0.5}} color="error"/>
                          <Typography>Huidig wachtwoord is verlopen</Typography>
                        </>
                      )}
                    </Box>
                  </>
                )}

                <Divider />

                <Box sx={{mt: 3}}>
                  <LoadingButton type="submit" color="primary" variant="contained" disabled={!isValid || values.internal} loading={isSubmitting}>
                    Opslaan
                  </LoadingButton>
                </Box>
              </form>
            )}
          </Formik>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Profile;
