import {useMutation, useQuery} from 'react-query';
import client from '../services/client';
import confirm from '../elements/Confirmation/Confirmation';
import {Database} from "../types";
import queryClient from '../services/queryClient';
import { toast } from 'react-toastify';

export const getDatabases = () => {
  return useQuery({
    queryKey: ['databases'],
    queryFn: () => client.get('database').then(response => response.data),
    initialData: [],
  });
};

export const getDatabase = (id: number) => {
  return useQuery({
    queryKey: ['database', id],
    queryFn: () => client.get(`database/${id}`).then(response => response.data),
    enabled: id !== 0,
    initialData: {
      id: 0,
      name: '',
      password: '',
      server: {
        id: 0,
        name: '',
        privateIp: '',
        sshTunnelUsername: null,
        sshTunnelIp: null,
      },
      customer: null,
      project: null
    },
  });
};

export const createDatabase = () => {
  return useMutation({
    mutationFn: async (database: Database) => {
      return client.post('database',{
        ...database,
        server: database.server?.id,
        customer: database.customer?.id,
        project: database.project?.id,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['database']});
      toast.success('Database aangemaakt');
    },
  });
};

export const updateDatabase = () => {
  return useMutation({
    mutationFn: async (database: Database) => {
      return client.put(`database/${database.id}`,{
        ...database,
        server: database.server?.id,
        customer: database.customer?.id,
        project: database.project?.id,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['databases']});
      toast.success('Database gewijzigd');
    },
  });
};

export const deleteDatabase = () => {
  return useMutation({
    mutationFn: async (database: Database) => {
      if (await confirm({
        title: 'Database verwijderen',
        text: 'Deze actie kan niet ongedaan gemaakt worden',
      })) {
        return client.delete(`database/${database.id}`);
      }

      return Promise.reject(new Error('Cancelled'));
    },
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['databases']});
      toast.success('Database verwijderd');
    },
  });
};

export const grantDatabaseAccess = () => {
  return useMutation({
    mutationFn: async (database: Database) => {
      return client.post(`database/${database.id}/access`, {data: {}});
    },
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['databases']});
    },
  });
};

export const revokeDatabaseAccess = () => {
  return useMutation({
    mutationFn: async (database: Database) => {
      return client.delete(`database/${database.id}/access`, {data: {}});
    },
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['databases']});
    },
  });
};
