import { Alert } from '@mui/material';
import React from 'react';

const Secure = () => {
  return (
    <Alert severity="warning">
      Om deze pagina te kunnen bekijken heb je een beveiligde verbinding nodig. Zorg dat je op kantoor met de juiste Wi-fi verbonden bent, of van VPN gebruik maakt en ververs daarna de pagina.
    </Alert>
  )
};

export default Secure;
