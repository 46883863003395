import React, {useContext} from 'react';
import {
  ColumnDefinitionType,
  ObjectStoreBucket,
  TableAction
} from '../../../types';
import DataTable from '../../../elements/DataTable/DataTable';
import {Paper} from "@mui/material";
import {AddBox, DeleteOutlined} from "@mui/icons-material";
import {UserContext} from "../../../provider/UserProvider";
import {hasRole} from "../../../services/role";
import {useNavigate} from "react-router-dom";
import ProjectLinkButton from '../../ProjectResource/ProjectLinkButton';
import {deleteBucket, getBuckets} from '../../../api/objectStoreBucket';

const Overview = () => {
  const navigate = useNavigate();
  const {user} = useContext(UserContext);
  const {data: users, isLoading, isFetching} = getBuckets();
  const mutation = deleteBucket();

  const columns: Array<ColumnDefinitionType<ObjectStoreBucket>> = [
    { key: 'name', title: 'Naam', type: 'string', sortable: true, filter: true },
    { key: 'account.customer.name', title: 'Account', type: 'string', sortable: true, filter: true },
    { key: 'project.domain', title: 'Project', type: 'custom', sortable: true, content: (row) => {
        if (row.project !== null) {
          return row.project.domain;
        }

        return <ProjectLinkButton resourceId={row.id} resourceType="Object store bucket" customer={row.account?.customer || undefined}/>
      }},
  ];

  const toolbarActions: Array<TableAction<undefined>> = [
    {
      title: 'Bucket aanmaken',
      icon: <AddBox />,
      variant: 'primary',
      onClick: () => navigate('/os/bucket/new'),
      type: 'button' ,
      condition: () => hasRole(user, ['ROLE_OBJECT_STORAGE_BUCKET_CREATE']),
    },
  ];

  const actions: Array<TableAction<ObjectStoreBucket>> = [
    { title: 'Verwijderen', icon: <DeleteOutlined />, variant: 'danger', onClick: mutation.mutate, type: 'button', condition: () => hasRole(user, ['ROLE_OBJECT_STORAGE_BUCKET_DELETE']) },
  ];

  return (
    <Paper>
      <DataTable
        columns={columns}
        rows={users}
        loading={isLoading || isFetching}
        actions={actions}
        toolbar={{
          title: 'Objectstore buckets',
          actions: toolbarActions,
        }}
        defaultRowsPerPage={10}
        pagination
      />
    </Paper>
  );
};

export default Overview;
