import Joi from "joi";

const validationSchema = Joi.defaults((schema) => schema.preferences({
  messages: {
    'string.empty': 'Dit veld mag niet leeg zijn',
    'string.max': 'Dit veld mag maximaal {#limit} karakters lang zijn',
    'any.only': 'Wachtwoord komt niet overeen',
    'object.base': 'Dit veld mag niet leeg zijn',
  }
}));

export default validationSchema;
