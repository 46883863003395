import React, {ReactElement, useEffect} from 'react';
import {ColumnDefinitionType, TableAction} from '../../types';
import client from '../../services/client';
import {Paper, TextField, Autocomplete, Typography, Box} from '@mui/material';
import DataTable from '../../elements/DataTable/DataTable';
import {useNavigate} from "react-router-dom";
import {VisibilityOutlined} from "@mui/icons-material";
import {getRecentMonths, getRecentYears} from "../../services/date";
import {green, orange} from '@mui/material/colors';

interface Type {
  id: number;
  name: string;
  cost: number;
  costChangePercentage: number;
  billed: number;
  billedChangePercentage: number;
  difference: number;
}

const Overview = () => {
  const navigate = useNavigate();
  const [customers, setCustomers] = React.useState<Array<Type>>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [year, setYear] = React.useState<number>(new Date().getFullYear());
  const [month, setMonth] = React.useState<number>(getRecentMonths(1, false)[0]);

  useEffect(() => {
    setLoading(true);
    client
      .get('resource/customer/statistics', {
        params: {
          month: month,
          year: year,
        },
      })
      .then((response) => setCustomers(response.data))
      .finally(() => setLoading(false));
  }, [month, year]);

  const formatPercentage = (percentage: number) => {
    if (percentage > 0) {
      return <Typography sx={{ml: 0.5, color: green[500]}} variant="caption">+{percentage.toFixed(2)}%</Typography>;
    }

    if (percentage === 0) {
      return <Typography sx={{ml: 0.5, color: orange[500]}} variant="caption">{percentage.toFixed(2)}%</Typography>;
    }

    return <Typography sx={{ml: 0.5}} variant="caption" color="error">{percentage.toFixed(2)}%</Typography>;
  }

  const columns: Array<ColumnDefinitionType<Type>> = [
    { key: 'name', title: 'Naam', type: 'string', sortable: true, filter: true },
    { key: 'cost', title: 'Kosten', type: 'custom', sortable: true, content: (row) => {
      return <Box>€ {row.cost.toFixed(2)} {formatPercentage(row.costChangePercentage || 0)}</Box>;
    }},
    { key: 'billed', title: 'Gefactureerd', type: 'custom', sortable: true, content: (row) => {
        return <Box>€ {row.billed.toFixed(2)} {formatPercentage(row.billedChangePercentage || 0)}</Box>;
      }},
    { key: 'difference', title: 'Verschil', type: 'price', sortable: true },
  ];

  const actions: Array<TableAction<Type>> = [
    { title: 'Bekijken', icon: <VisibilityOutlined />, variant: 'primary', onClick: (customer) => navigate(`/customer/${customer.id}`), type: 'button' },
  ];

  const components: Array<ReactElement> = [(
    <Autocomplete
      disableClearable={true}
      sx={{ml: 2, minWidth: '200px'}}
      options={getRecentYears()}
      getOptionLabel={(option: any) => option.toString()}
      value={year ?? undefined}
      onChange={(event, value) => setYear(value)}
      renderInput={(params) => (
        <TextField{...params} label="Jaar" variant="outlined" margin="none" style={{ backgroundColor: 'white' }} />
      )}
    />
  ), (
    <Autocomplete
      disableClearable={true}
      sx={{ml: 2, minWidth: '200px'}}
      options={[1, 2, 3, 4, 5, 7, 8, 9, 10, 11, 12]}
      getOptionLabel={(option: number) => new Date(0, option).toLocaleString('nl-NL', { month: 'long' })}
      value={month ?? undefined}
      onChange={(event, value) => {setMonth(value)}}
      filterSelectedOptions
      renderInput={(params) => (
        <TextField {...params} label="Maand" variant="outlined" margin="none" style={{ backgroundColor: 'white' }} />
      )}
    />
  )];

  return (
    <Paper>
      <DataTable
        columns={columns}
        rows={customers}
        actions={actions}
        loading={loading}
        defaultRowsPerPage={20}
        pagination
        toolbar={{
          title: 'Klanten',
          components: components,
        }}
      />
    </Paper>
  );
};

export default Overview;
