import React, {useContext} from 'react';
import {ColumnDefinitionType, MessageQueueUser, TableAction} from '../../types';
import DataTable from '../../elements/DataTable/DataTable';
import {Paper} from "@mui/material";
import {AddBox, DeleteOutlined, InfoOutlined} from "@mui/icons-material";
import {UserContext} from "../../provider/UserProvider";
import {hasRole} from "../../services/role";
import {deleteMessageQueueUser, getMessageQueueUsers} from "../../api/messageQueueUser";
import {useNavigate} from "react-router-dom";
import CustomerLinkButton from "../CustomerResource/CustomerLinkButton";
import ProjectLinkButton from '../ProjectResource/ProjectLinkButton';

const Overview = () => {
  const navigate = useNavigate();
  const {user} = useContext(UserContext);
  const {data: users, isLoading, isFetching} = getMessageQueueUsers();
  const mutation = deleteMessageQueueUser();

  const columns: Array<ColumnDefinitionType<MessageQueueUser>> = [
    { key: 'name', title: 'Naam', type: 'string', sortable: true, filter: true },
    { key: 'server.name', title: 'Server', type: 'string', sortable: true, filter: true },
    { key: 'customer.name', title: 'Klant', type: 'custom', sortable: true, content: (row) => {
      if (row.customer !== null) {
        return row.customer.name;
      }

      return <CustomerLinkButton resourceId={row.id} resourceType="Message queue user"/>
    }},
    { key: 'project.domain', title: 'Project', type: 'custom', sortable: true, content: (row) => {
      if (row.project !== null) {
        return row.project.domain;
      }

      return <ProjectLinkButton resourceId={row.id} resourceType="Message queue user" customer={row.customer || undefined}/>
    }},
  ];

  const toolbarActions: Array<TableAction<undefined>> = [
    {
      title: 'Gebruiker aanmaken',
      icon: <AddBox />,
      variant: 'primary',
      onClick: () => navigate('/mq/user/new'),
      type: 'button' ,
      condition: () => hasRole(user, ['ROLE_MESSAGE_QUEUE_USER_CREATE']),
    },
  ];

  const actions: Array<TableAction<MessageQueueUser>> = [
    { title: 'Details', icon: <InfoOutlined />, variant: 'primary', onClick: (resource) => navigate(`/mq/user/${resource.id}/detail`), type: 'button' },
    { title: 'Verwijderen', icon: <DeleteOutlined />, variant: 'danger', onClick: mutation.mutate, type: 'button', condition: () => hasRole(user, ['ROLE_MESSAGE_QUEUE_USER_DELETE']) },
  ];

  return (
    <Paper>
      <DataTable
        columns={columns}
        rows={users}
        loading={isLoading || isFetching}
        actions={actions}
        toolbar={{
          title: 'Message queue gebruikers',
          actions: toolbarActions,
        }}
        defaultRowsPerPage={10}
        pagination
      />
    </Paper>
  );
};

export default Overview;
