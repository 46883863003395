import {Alert, Box, Button, Grid, Paper, Typography} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {joiResolver} from "@hookform/resolvers/joi";
import Joi from "joi";
import {FormProvider, useForm} from "react-hook-form";
import TextField from '../../../elements/Form/TextField';
import Autocomplete from '../../../elements/Form/Autocomplete';
import { LoadingButton } from '@mui/lab';
import validationSchema from "../../../services/validationSchema";
import {ObjectStoreAccount, ObjectStoreBucket} from "../../../types";
import ProjectSelect from "../../../elements/Form/ProjectSelect";
import {CopyBlock, dracula} from "react-code-blocks";
import {createBucket, getBucket, updateBucket} from '../../../api/objectStoreBucket';
import {getAccounts} from '../../../api/objectStoreAccount';

const Form = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const {data: bucket} = getBucket(+(id || 0));
  const {data: accounts} = getAccounts();
  const mutation = id === undefined ? createBucket() : updateBucket();
  const [key, setKey] = useState<string>();
  const [secret, setSecret] = useState<string>();

  const schema = validationSchema.object({
    name: Joi.string()
      .max(253)
      .required()
      .when('project', {
        is: null,
        then: Joi.string().regex(/^[a-z0-9-]+$/),
        otherwise: Joi.custom((value, helpers) => {
          const domain = helpers.state.ancestors[0].project.domain.replaceAll('.', '-');
          const dynamicRegexPattern = new RegExp(`^(?!-)(?:[a-z0-9-]*-)?${domain}(?:-[a-z0-9-]*[^-])?$`);

          if (!dynamicRegexPattern.test(value)) {
            return helpers.error('regex.object_store_bucket.project', { domain: domain });
          }

          return value;
        })
      })
      .messages({
        'string.pattern.base': 'De waarde voldoet niet aan het vereiste formaat. Het mag alleen kleine letters, cijfers en underscores bevatten.',
        'regex.object_store_bucket.project': 'De waarde voldoet niet aan het vereiste formaat. Het moet \'{#domain}\' bevatten en kan andere kleine letters, cijfers en underscores bevatten.',
      }),
    account: Joi.object().required(),
    project: Joi.object().allow(null),
  }).unknown();

  const form = useForm<ObjectStoreBucket>({
    defaultValues: bucket,
    resolver: joiResolver(schema),
    mode: 'onBlur',
  });

  const onSubmit = (values: ObjectStoreBucket) => {
    return mutation.mutateAsync(values).then((response) => {
      setKey(response.data.key);
      setSecret(response.data.secret);
    }).catch((error) => {
      Object.keys(error.response.data.errors || {}).forEach(key => {
        form.setError(key as keyof ObjectStoreBucket, {type: 'custom', message: error.response.data.errors[key]})
      })
    });
  }

  useEffect(() => {
    if (form.watch('project') === null) {
      return;
    }

    form.trigger('name');
  }, [form.watch('project')]);

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} sm={12} md={12} lg={6}>
        <Paper sx={{p: 6, maxWidth: '600px', margin: '0 auto'}}>
          {form.formState.isSubmitSuccessful ? (
            <>
              <Typography variant="h6" fontWeight="bold">Bucket is aangemaakt</Typography>
              <Alert severity="warning" sx={{my: 2}} elevation={0}>Zorg dat de credentials direct in Vault worden gezet. De credentials worden niet door My Friday bewaard.</Alert>
              <Typography sx={{mb: 1}}>De bucket is met de volgende credentials aangemaakt:</Typography>
              <Box>
                <Typography variant="caption">Key</Typography>
                <CopyBlock
                  text={key}
                  language="text"
                  showLineNumbers={false}
                  theme={dracula}
                />
              </Box>
              <Box sx={{mt: 1}}>
                <Typography variant="caption">Secret</Typography>
                <CopyBlock
                  text={secret}
                  language="text"
                  showLineNumbers={false}
                  theme={dracula}
                />
              </Box>
              <Button onClick={() => navigate(-1)} sx={{mt: 4}} color="primary" variant="contained">Bevestigen</Button>
            </>
          ) : (
            <>
              <Typography variant="h6" fontWeight="bold">Bucket {id === undefined ? 'aanmaken' : 'wijzigen'}</Typography>
              <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)}>
                  <TextField name="name" label="Naam" />
                  <Autocomplete
                    options={accounts}
                    name="account"
                    label="Klant"
                    getOptionLabel={(option: ObjectStoreAccount) => option.customer?.name || 'Onbekend'}
                  />
                  <ProjectSelect name="project" label="Project"/>
                  <LoadingButton sx={{mt: 2}} type="submit" color="primary" variant="contained" loading={form.formState.isSubmitting}>
                    {id === undefined ? 'Aanmaken' : 'Wijzigen'}
                  </LoadingButton>
                  <Button onClick={() => navigate(-1)} sx={{mt: 2, ml: 1}} color="secondary" disabled={form.formState.isSubmitting}>Annuleren</Button>
                </form>
              </FormProvider>
            </>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
}

export default Form;
