import { Formik } from 'formik';
import React, { useEffect } from 'react';
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from '@mui/material';
import * as Yup from 'yup';
import { Customer } from '../../types';
import { Autocomplete } from '@mui/lab';
import client from '../../services/client';
import AsynchronousAutocomplete from '../../elements/autocomplete/AsynchronousAutocomplete';

const schema = Yup.object().shape({
    customer: Yup.object().typeError('Er moet een klant gekozen worden'),
    zone: Yup.number().min(1, 'Er moet een zone gekozen worden').required('Er moet een zone gekozen worden'),
});

interface Props {
    customer?: Customer | null;
    open: boolean;
    onClose: () => void;
    onConfirm: (values: any) => void;
    ignore?: Array<string>;
}

const ZoneForm = ({ customer = null, open, onClose, onConfirm, ignore = [] }: Props) => {
    const [customers, setCustomers] = React.useState<Array<Customer>>([]);

    useEffect(() => {
        client.get('customer').then((response) => setCustomers(response.data));
    }, []);

    const handleSubmit = (values: any) => {
        onConfirm({ id: values.customer.id, zoneId: values.zone });
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <Formik
                initialValues={{
                    customer: customer === null ? null : customers.find((c) => c.id === customer.id) || null,
                    zone: 0,
                }}
                validationSchema={schema}
                onSubmit={handleSubmit}
            >
                {({ values, errors, touched, handleSubmit, isValid, isSubmitting, setFieldValue }) => (
                    <form onSubmit={handleSubmit}>
                        <DialogTitle>Zone koppelen aan klant</DialogTitle>
                        <DialogContent sx={{overflow: 'visible', minWidth: '400px'}}>
                            <Autocomplete
                                options={customers}
                                value={values.customer}
                                getOptionLabel={(customer) => customer.name}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Klant"
                                        margin="normal"
                                        helperText={errors.customer && touched.customer && errors.customer}
                                        error={Boolean(errors.customer && touched.customer)}
                                    />
                                )}
                                onChange={(e, value) => {
                                    setFieldValue('customer', value);
                                }}
                            />
                            <AsynchronousAutocomplete
                                minLength={3}
                                endpoint="dns/zone"
                                onChange={(value: any) => setFieldValue('zone', value.id)}
                                helperText={errors.zone && touched.zone && errors.zone}
                                error={Boolean(errors.zone && touched.zone)}
                                label="Zone"
                                ignore={ignore}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={onClose} color="primary">
                                Annuleren
                            </Button>
                            <Button type="submit" color="primary" disabled={!isValid || isSubmitting}>
                                Koppelen
                                {isSubmitting && <CircularProgress size={20} style={{ marginLeft: '8px' }} />}
                            </Button>
                        </DialogActions>
                    </form>
                )}
            </Formik>
        </Dialog>
    );
};

export default ZoneForm;
