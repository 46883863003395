import React, {useContext} from 'react';
import {ColumnDefinitionType, SslCertificate, TableAction} from '../../types';
import DataTable from '../../elements/DataTable/DataTable';
import {Paper} from "@mui/material";
import {ZoomInOutlined} from "@mui/icons-material";
import {UserContext} from "../../provider/UserProvider";
import {hasRole} from "../../services/role";
import {useNavigate} from "react-router-dom";
import {getCertificates} from '../../api/sslCertificate';

const Overview = () => {
  const navigate = useNavigate();
  const {user} = useContext(UserContext);
  const {data: certificates, isLoading, isFetching} = getCertificates();

  const columns: Array<ColumnDefinitionType<SslCertificate>> = [
    { key: 'commonName', title: 'Common name', type: 'string', sortable: true, filter: true },
    { key: 'expirationDate', title: 'Verloopdatum', type: 'date', sortable: true, filter: true },
    { key: 'orderDate', title: 'Aanschafdatum', type: 'date', sortable: true, filter: true },
    { key: 'domains', title: 'Aantal domeinen', type: 'count', sortable: true, filter: true },
    { key: 'requestedBy.name', title: 'Aangevraagd door', type: 'custom', sortable: true, content: (certificate) => {
      if (certificate.requestedBy !== null) {
        return certificate.requestedBy.name;
      }

      return '';
    }, condition: () => user.internal },
  ];

  const actions: Array<TableAction<SslCertificate>> = [
    { title: 'Bekijken', icon: <ZoomInOutlined />, variant: 'primary', onClick: (certificate) => navigate(`/ssl/certificate/${certificate.id}`), type: 'button', condition: () => hasRole(user, ['ROLE_SSL_CERTIFICATE_GET']) },
  ];

  return (
    <Paper>
      <DataTable
        columns={columns}
        rows={certificates}
        loading={isLoading || isFetching}
        actions={actions}
        toolbar={{
          title: 'SSL certificaten',
        }}
        defaultRowsPerPage={10}
        pagination
        defaultOrder="desc"
        defaultOrderColumn="orderDate"
      />
    </Paper>
  );
};

export default Overview;
