import React, {ChangeEvent} from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import {TextField as MuiTextField} from '@mui/material';
import {TextFieldProps} from '@mui/material/TextField/TextField';
import {getIn} from '../../services/form';

type Props = Omit<TextFieldProps, 'name' | 'onChange' | 'multiline' | 'minRows'> & {
  name: string;
}

const DomainField = ({name, ...props}: Props) => {
  const {control, setValue, formState: {errors, isSubmitting}} = useFormContext();

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const lines = event.target.value.split('\n');

    setValue(name, lines, { shouldValidate: true });
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({field}) => (
        <MuiTextField
          error={Boolean(getIn(errors, name))}
          helperText={Boolean(getIn(errors, name)) ? getIn(errors, name).filter((i: any) => null !== i)[0].message : 'De common name wordt automatisch toegevoegd'}
          multiline
          minRows={3}
          {...field}
          {...props}
          disabled={isSubmitting || props.disabled}
          value={field.value.join('\n')}
          onChange={handleOnChange}
        />
      )}
    />
  );
};

export default DomainField;
