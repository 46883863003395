import {useMutation, useQuery} from 'react-query';
import client from '../services/client';
import {ObjectStoreAccount} from '../types';
import queryClient from '../services/queryClient';
import {toast} from 'react-toastify';
import confirm from '../elements/Confirmation/Confirmation';

export const getAccounts = () => {
  return useQuery({
    queryKey: ['objectStoreAccounts', 'resources'],
    queryFn: () => client.get('os/account').then(response => response.data),
    initialData: [],
  });
};

export const createAccount = () => {
  return useMutation({
    mutationFn: async (account: ObjectStoreAccount) => {
      return client.post('os/account', {
        ...account,
        customer: account.customer?.id,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['objectStoreAccounts']});
      toast.success('Account aangemaakt');
    },
  });
};

export const requestAccount = () => {
  return useMutation({
    mutationFn: async (values: any) => {
      return client.post('os/account/request', {
        customer: values.customer?.id,
      });
    },
    onSuccess: () => {
      toast.success('Account aangevraagd');
    },
  });
};

export const deleteAccount = () => {
  return useMutation({
    mutationFn: async (account: ObjectStoreAccount) => {
      if (await confirm({
        title: 'Account verwijderen',
        text: 'Deze actie kan niet ongedaan gemaakt worden',
      })) {
        return client.delete(`os/account/${account.id}`);
      }

      return Promise.reject(new Error('Cancelled'));
    },
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['objectStoreAccounts']});
      toast.success('Account verwijderd');
    },
  });
};

export const synchronizeAccount = () => {
  return useMutation({
    mutationFn: async (account: ObjectStoreAccount) => {
      return client.post(`os/account/${account.id}/synchronize`);
    },
    onSuccess: () => {
      toast.success('Account gesynchroniseerd');
    },
  });
};
