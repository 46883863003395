import React, {useContext} from 'react';
import {ColumnDefinitionType, Project, TableAction} from '../../types';
import { Paper } from '@mui/material';
import DataTable from '../../elements/DataTable/DataTable';
import {AddBox, SearchOutlined} from '@mui/icons-material';
import {useNavigate} from "react-router-dom";
import {getProjects} from "../../api/project";
import {hasRole} from "../../services/role";
import {UserContext} from "../../provider/UserProvider";

const typeMap = {
  sulu: 'Sulu',
  sylius: 'Sylius',
  akeneo: 'Akeneo',
  symfony: 'Symfony',
  shopware: 'Shopware',
  shopware_frontend: 'Shopware frontend',
  frontend: 'Frontend',
  monorepo: 'Monorepo',
} as any;

const Overview = () => {
  const {user} = useContext(UserContext);
  const {isLoading, data, isFetching} = getProjects();
  const navigate = useNavigate();

  const columns: Array<ColumnDefinitionType<Project>> = [
    { key: 'domain', title: 'Domein', type: 'string', sortable: true, filter: user.internal },
    { key: 'type', title: 'Type', type: 'custom', sortable: true, filter: user.internal, content: (row) => typeMap[row.type]},
    { key: 'customer.name', title: 'Klant', type: 'string', sortable: true, filter: user.internal, condition: () => user.internal },
  ];

  const toolbarActions: Array<TableAction<undefined>> = [
    {
      title: 'Project aanmaken',
      icon: <AddBox />,
      type: 'button',
      variant: 'primary',
      onClick: () => navigate('/project/new'),
      condition: () => hasRole(user, ['ROLE_PROJECT_CREATE']) && user.internal,
    },
  ];

  const actions: Array<TableAction<Project>> = [
    {
      title: 'Bekijken',
      icon: <SearchOutlined />,
      type: 'button',
      variant: 'primary',
      onClick: (project) => navigate(`/project/${project.id}`),
      condition: () => user.internal,
    },
  ];

  return (
    <Paper>
      <DataTable
        columns={columns}
        rows={data}
        actions={actions}
        loading={isLoading || isFetching}
        toolbar={{
          title: 'Projecten',
          actions: toolbarActions,
        }}
        pagination={user.internal}
      />
    </Paper>
  );
};

export default Overview;
