import {useQuery} from 'react-query';
import client from '../services/client';

export const getInvoices = (customer: number|null, enabled: boolean) => {
  return useQuery({
    queryKey: ['invoices', customer],
    queryFn: () => client.get('invoice', {
      params: {
        customer: customer,
      },
    }).then(response => response.data),
    initialData: [],
    enabled: enabled,
  });
};
