import React, { useEffect } from 'react';
import { ColumnDefinitionType, Customer, CustomerZoneLink, TableAction, Zone } from '../../types';
import client from '../../services/client';
import { Paper } from '@mui/material';
import DataTable from '../../elements/DataTable/DataTable';
import { Link, LinkOff, Search } from '@mui/icons-material';
import ZoneForm from './ZoneForm';
import { toast } from 'react-toastify';
import DeleteConfirmation from '../../elements/DataTable/DeleteConfirmation';
import {useNavigate, useParams} from "react-router-dom";

const columns: Array<ColumnDefinitionType<Zone>> = [{ key: 'name', title: 'Naam', type: 'string', sortable: true }];

const initialUser = { id: 0, name: '', architectureUrl: null, users: [], zones: [], projects: [], services: [], resources: [], amountOfZones: 0, amountOfUsers: 0, amountOfProjects: 0, amountOfResources: 0, invoicedSums: {}, subscriptionId: null, debtorNumber: null };

const UserOverview = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [customer, setCustomer] = React.useState<Customer>(initialUser);
  const [zoneToUnlink, setZoneToUnlink] = React.useState<Zone | null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [showForm, setShowForm] = React.useState<boolean>(false);

  useEffect(() => {
    if (zoneToUnlink !== null || showForm) {
      return;
    }

    setLoading(true);
    client
      .get(`customer/${id}/zone`)
      .then((response) => setCustomer(response.data))
      .finally(() => setLoading(false));
  }, [showForm, zoneToUnlink]);

  const redirectToZone = (zone: Zone) => {
    navigate(`/dns/zone/${zone.externalId}`);
  };

  const linkZone = ({ id, zoneId }: CustomerZoneLink) => {
    client
      .post(`customer/${id}/zone/${zoneId}`)
      .then(() => toast.success('Zone gekoppeld'))
      .finally(() => setShowForm(false));
  };

  const unlinkZone = () => {
    if (null === zoneToUnlink) {
      return;
    }

    client
      .delete(`customer/${customer.id}/zone/${zoneToUnlink.externalId}`)
      .then(() => toast.success('Zone ontkoppeld'))
      .finally(() => setZoneToUnlink(null));
  };

  const actions: Array<TableAction<Zone>> = [
    { title: 'Zone ontkoppelen', icon: <LinkOff />, variant: 'danger', onClick: setZoneToUnlink },
    { title: 'Bekijken', icon: <Search />, variant: 'primary', onClick: redirectToZone },
  ];

  const toolbarActions: Array<TableAction<undefined>> = [
    { title: 'Zones koppelen', icon: <Link />, variant: 'primary', onClick: () => setShowForm(true) },
  ];

  return (
    <Paper>
      <DataTable
        columns={columns}
        rows={customer.zones}
        actions={actions}
        loading={loading}
        onRowClick={redirectToZone}
        toolbar={{
          title: `Zones voor ${customer.name}`,
          actions: toolbarActions,
          backButton: true,
        }}
      />
      <ZoneForm
        open={showForm}
        onClose={() => setShowForm(false)}
        customer={customer}
        onConfirm={linkZone}
        ignore={customer.zones.map((zone) => zone.name)}
      />
      <DeleteConfirmation
        title="Zone ontkoppelen?"
        text="Wanneer de zone wordt ontkoppeld zal de klant hier geen toegang meer tot hebben."
        open={null !== zoneToUnlink}
        onConfirm={unlinkZone}
        onClose={() => setZoneToUnlink(null)}
      />
    </Paper>
  );
};

export default UserOverview;
