import React, {useEffect} from 'react';
import {ColumnDefinitionType, TableAction, Role} from '../../types';
import client from '../../services/client';
import {Paper} from '@mui/material';
import DataTable from '../../elements/DataTable/DataTable';
import {AxiosResponse} from 'axios';
import {AddBox, DeleteOutline, EditOutlined} from '@mui/icons-material';
import {toast} from 'react-toastify';
import DeleteConfirmation from '../../elements/DataTable/DeleteConfirmation';
import {useNavigate} from "react-router-dom";

const Overview = () => {
  const navigate = useNavigate();
  const [roles, setRoles] = React.useState<Array<Role>>([]);
  const [roleToDelete, setRoleToDelete] = React.useState<Role|null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);

  useEffect(() => {
    setLoading(true);

    client
      .get('role')
      .then((response: AxiosResponse) => setRoles(response.data))
      .finally(() => setLoading(false));
  }, [roleToDelete]);

  const handleDelete = () => {
    if (roleToDelete === null) {
      return;
    }

    client
      .delete(`role/${roleToDelete.id}`)
      .then(() => toast.success('Rol verwijderd'))
      .finally(() => setRoleToDelete(null));
  };

  const columns: Array<ColumnDefinitionType<Role>> = [
    {key: 'name', title: 'Naam', type: 'string', sortable: true},
  ];

  const actions: Array<TableAction<Role>> = [
    {
      title: 'Wijzigen',
      icon: <EditOutlined />,
      variant: 'warning',
      onClick: (role) => navigate(`/role/${role.id}`),
      type: 'button',
    },
    {
      title: 'Verwijderen',
      icon: <DeleteOutline />,
      variant: 'warning',
      onClick: setRoleToDelete,
      type: 'button',
    },
  ];

  const toolbarActions: Array<TableAction<undefined>> = [
    {title: 'Rol toevoegen', icon: <AddBox />, variant: 'primary', onClick: () => navigate('/role/new'), type: 'button'},
  ];

  return (
    <Paper>
      <DataTable
        columns={columns}
        rows={roles.filter((role) => role.name !== 'Admin')}
        actions={actions}
        loading={loading}
        toolbar={{
          title: 'Rollen',
          actions: toolbarActions,
        }}
      />
      <DeleteConfirmation
        title="Rol verwijderen?"
        text="Deze actie kan niet ongedaan gemaakt worden."
        open={roleToDelete !== null}
        onClose={() => setRoleToDelete(null)}
        onConfirm={handleDelete}
      />
    </Paper>
  );
};

export default Overview;
