import React from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import {Autocomplete as MuiAutocomplete, AutocompleteProps, TextField} from '@mui/material';
import {getIn} from '../../services/form';
import {getUsers} from "../../api/user";
import {User} from "../../types";

type Props = Omit<AutocompleteProps<any, any, any, any>, 'name'|'renderInput'|'options'> & {
  name: string;
  label: string;
}

const UserSelect = ({name, label, ...props}: Props) => {
  const {control, watch, formState: {errors, isSubmitting}} = useFormContext();
  const {data: users, isFetching, isLoading} = getUsers();

  return (
    <Controller
      name={name}
      control={control}
      render={({field: {onChange, ...field}}) => (
        <MuiAutocomplete
          {...field}
          {...props}
          value={watch(name)}
          options={users.filter((user: User) => user.email.includes('@friday.nl')).sort((a: User, b: User) => a.name.localeCompare(b.name))}
          loading={isFetching || isLoading}
          disabled={users?.length === 0 || isSubmitting || props.disabled}
          isOptionEqualToValue={(option, value) => {
            if (typeof option === 'object') {
              return option.id === value.id
            }

            return option === value;
          }}
          onChange={(event, value) => onChange(value)}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              error={Boolean(getIn(errors, name))}
              helperText={getIn(errors, name)?.message}
            />
          )}
          getOptionLabel={(option: User) => option.name}
        />
      )}
    />
  );
};

export default UserSelect;
