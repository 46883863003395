import {Button, Grid, Paper, Typography} from '@mui/material';
import React from 'react';
import {useNavigate} from "react-router-dom";
import {joiResolver} from "@hookform/resolvers/joi";
import Joi from "joi";
import {FormProvider, useForm} from "react-hook-form";
import TextField from '../../../elements/Form/TextField';
import { LoadingButton } from '@mui/lab';
import validationSchema from "../../../services/validationSchema";
import {ObjectStoreAccount} from "../../../types";
import CustomerSelect from "../../../elements/Form/CustomerSelect";
import {createAccount} from '../../../api/objectStoreAccount';
import Select from '../../../elements/Form/Select';

const Form = () => {
  const navigate = useNavigate();
  const mutation = createAccount();

  const types = [
    {label: 'AWS', value: 'aws'},
    {label: 'DigitalOcean', value: 'do'},
  ];

  const schema = validationSchema.object({
    name: Joi.string().required(),
    type: Joi.string().required(),
    key: Joi.string().required(),
    secret: Joi.string().required(),
    customer: Joi.object().required(),
  }).unknown();

  const form = useForm<ObjectStoreAccount>({
    defaultValues: {name: '', type: 'aws', key: '', secret: '', customer: null},
    resolver: joiResolver(schema),
    mode: 'onBlur',
  });

  const onSubmit = (values: ObjectStoreAccount) => {
    return mutation.mutateAsync(values).then(() => {
      navigate(-1);
    }).catch((error) => {
      Object.keys(error.response.data.errors || {}).forEach(key => {
        form.setError(key as keyof ObjectStoreAccount, {type: 'custom', message: error.response.data.errors[key]})
      })
    });
  }

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} sm={12} md={12} lg={6}>
        <Paper sx={{p: 6, maxWidth: '600px', margin: '0 auto'}}>
          <Typography variant="h6" fontWeight="bold">Account aanmaken</Typography>
          <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <TextField name="name" label="Naam" />
              <Select name="type" label="Type" options={types} />
              <CustomerSelect name="customer" label="Klant"/>
              <TextField name="key" label="Key" type="password" />
              <TextField name="secret" label="Secret" type="password" />
              <LoadingButton sx={{mt: 2}} type="submit" color="primary" variant="contained" loading={form.formState.isSubmitting}>
                Aanmaken
              </LoadingButton>
              <Button onClick={() => navigate(-1)} sx={{mt: 2, ml: 1}} color="secondary" disabled={form.formState.isSubmitting}>Annuleren</Button>
            </form>
          </FormProvider>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default Form;
