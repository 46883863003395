import {useMutation, useQuery} from "react-query";
import client from "../services/client";
import {ElasticUser} from "../types";
import confirm from "../elements/Confirmation/Confirmation";
import queryClient from "../services/queryClient";
import {toast} from "react-toastify";

export const getUsers = () => {
  return useQuery({
    queryKey: ['elasticUsers'],
    queryFn: () => client.get('elastic/user').then(response => response.data),
    initialData: [],
  });
};

export const getUser = (id: number) => {
  return useQuery({
    queryKey: ['elasticUser', id],
    queryFn: () => client.get(`elastic/user/${id}`).then(response => response.data),
    enabled: id !== 0,
    initialData: {
      id: 0,
      name: '',
      password: '',
      deployment: null,
      project: null,
    },
  });
};

export const createUser = () => {
  return useMutation({
    mutationFn: async (user: ElasticUser) => {
      return client.post('elastic/user', {
        ...user,
        deployment: user.deployment?.id,
        project: user.project?.id,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['elasticUsers']});
      toast.success('Gebruiker aangemaakt');
    },
  });
};

export const updateUser = () => {
  return useMutation({
    mutationFn: async (user: ElasticUser) => {
      return client.put(`elastic/user/${user.id}`, {
        ...user,
        server: user.deployment?.id,
        project: user.project?.id,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['elasticUsers']});
      toast.success('Gebruiker gewijzigd');
    },
  });
};

export const deleteUser = () => {
  return useMutation({
    mutationFn: async (user: ElasticUser) => {
      if (await confirm({
        title: 'Gebruiker verwijderen',
        text: 'Deze actie kan niet ongedaan gemaakt worden',
      })) {
        return client.delete(`elastic/user/${user.id}`);
      }

      return Promise.reject(new Error('Cancelled'));
    },
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['elasticUsers']});
      toast.success('Gebruiker verwijderd');
    },
  });
};
