import {ColumnDefinitionType, DottedPath} from '../../types';
import {Checkbox, TableCell, TableHead, TableRow, TableSortLabel} from '@mui/material';
import React from 'react';

type Props<T> = {
  columns: Array<ColumnDefinitionType<T>>;
  rows: Array<T>;
  order: 'asc' | 'desc';
  orderBy: DottedPath<T>;
  actions: boolean;
  onSort: (key: DottedPath<T>) => void;
  checkboxSelection?: {
    identifier: keyof T;
    selected: Array<any>;
    setSelected: any;
  };
};

const DataTableHead = <T extends object>({columns, order, orderBy, actions, onSort, rows, checkboxSelection}: Props<T>): JSX.Element => {
  const handleSelectAllClick = (event: any) => {
    if (event.target.checked) {
      checkboxSelection?.setSelected(rows.map((n) => n[checkboxSelection?.identifier]));

      return;
    }

    checkboxSelection?.setSelected([]);
  };

  return (
    <TableHead>
      <TableRow>
        {typeof checkboxSelection !== 'undefined' && (
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={checkboxSelection.selected.length > 0 && checkboxSelection.selected.length < rows.length}
              checked={rows.length > 0 && checkboxSelection.selected.length === rows.length}
              onChange={handleSelectAllClick}
            />
          </TableCell>
        )}
        {columns
          .filter(column => (typeof column.condition === 'undefined' || column.condition()) && (!column.hidden))
          .map((column, i) => (
            <TableCell key={i} sortDirection={orderBy === column.key ? order : false} sx={{fontWeight: 'bold'}}>
              {column.sortable
                ? (
                  <TableSortLabel
                    active={orderBy === column.key}
                    direction={order}
                    onClick={() => onSort(column.key)}
                  >
                    {column.title}
                  </TableSortLabel>
                  )
                : (
                    column.title
                  )}
            </TableCell>
          ))}
        {actions && <TableCell align="left" width="5%" sx={{fontWeight: 'bold'}}>Acties</TableCell>}
      </TableRow>
    </TableHead>
  );
};

export default DataTableHead;
