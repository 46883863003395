import React from "react";
import {CheckTreePicker} from "rsuite";

interface props {
  inputData: any
  placeholder: any
  setSelectedCustomers?: any
  menuHeight?: any
  manipulateData?: any
}

export const CheckPickerTree = ({inputData, setSelectedCustomers, menuHeight, placeholder, manipulateData}: props) => {

  if (menuHeight === null){
    menuHeight = 320;
  }

  const manipulatedData = manipulateData(inputData);

  manipulatedData.sort((a:any, b:any) => {
    if (a.value === "Other") {
      return 1;
    } else if (b.value === "Other") {
      return -1;
    } else {
      return a.value.localeCompare(b.value);
    }
  });

  const handleChange = (value: any | null) => {
    if (value !== null){
      setSelectedCustomers(value.join(','));
    } else {
      setSelectedCustomers();
    }
  }

  return (
    <CheckTreePicker
      data={manipulatedData}
      height={menuHeight}
      searchable={true}
      placeholder={placeholder}
      size="lg"
      style={{ width: 300 }}
      onChange={(e) => {handleChange(e);}}
    />
  )
}
