import { Formik } from 'formik';
import React from 'react';
import { Zone } from '../../../types';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import * as Yup from 'yup';

const schema = Yup.object().shape({
  name: Yup.string().required('Naam mag niet leeg zijn'),
});

interface Props {
  zone: Zone;
  open: boolean;
  onClose: () => void;
  onConfirm: (zone: Zone) => void;
}

const Form = ({ zone, open, onClose, onConfirm }: Props) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <Formik initialValues={zone} validationSchema={schema} onSubmit={onConfirm}>
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isValid, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <DialogTitle>Nieuwe zone aanmaken</DialogTitle>
            <DialogContent sx={{overflow: 'visible', minWidth: '400px'}}>
              <TextField
                name="name"
                label="Naam"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.name && errors.name)}
                helperText={errors.name && touched.name && errors.name}
                margin="normal"
                fullWidth
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} color="primary">
                Annuleren
              </Button>
              <Button type="submit" color="primary" disabled={!isValid || isSubmitting}>
                Aanmaken
                {isSubmitting && <CircularProgress size={20} style={{ marginLeft: '8px' }} />}
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};

export default Form;
