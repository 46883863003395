import React from 'react';
import {UserContext} from '../../provider/UserProvider';
import {Avatar, Box, Hidden, Menu, MenuItem, Typography} from '@mui/material';
import {ExpandMore} from '@mui/icons-material';
import {useNavigate} from 'react-router-dom';

const UserAvatar = () => {
  const navigate = useNavigate();
  const {user} = React.useContext(UserContext);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');

    handleClose();

    navigate('/login');
  };

  const redirectToProfile = () => {
    handleClose();

    navigate('/profile');
  }

  return (
    <>
      <Hidden smDown>
        <Box sx={{ml: 2, display: 'flex', alignItems: 'center', cursor: 'pointer'}} onClick={handleClick}>
          <Avatar alt={user.name} />
          <Typography sx={{color: 'black', pl: 1, pr: 0.5, fontWeight: 'bold', fontSize: 14}}>{user.name}</Typography>
          <ExpandMore sx={{color: 'black'}} fontSize="small"/>
        </Box>
      </Hidden>
      <Hidden mdUp>
        <Box sx={{pt: 1, ml: 1}}>
          <Avatar alt={user.name} onClick={handleClick} />
        </Box>
      </Hidden>
      <Menu id="user-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {user.internal && (
          <MenuItem onClick={redirectToProfile}>Profiel</MenuItem>
        )}
        <MenuItem onClick={handleLogout}>Uitloggen</MenuItem>
      </Menu>
    </>
  );
};

export default UserAvatar;
